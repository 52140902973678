import { useState } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { ReceiptIdProps } from '@2ndmarket/types';
import { FiatOperation } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import StepTypeableLine from './StepTypeableLine';
import StepReview from './StepReview';
import StepPin from './StepPin';

const TicketWallet: React.FC<ReceiptIdProps> = ({ user_id }) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);

  const [stepMenuTicket, setStepMenuTicket] = useState(true);
  const [stepLineTicket, setStepLineTicket] = useState(false);
  const [stepRevieTicket, setStepRevieTicket] = useState(false);
  const [stepPinTicket, setStepPinTicket] = useState(false);

  const [resultTicket, setResultTicket] = useState({});

  const [transferPromise, setTransferPromise] = useState({
    id: 0,
    type: '',
    register: false,
  });

  const handleLinkStepMenu = () => {
    setStepMenuTicket(false);
    setStepLineTicket(true);
  };

  const onResponsePin = (result: any) => {
    setStepPinTicket(true);
    setStepRevieTicket(false);
    setResultTicket(result);
  };

  const Breadcrumb = styled(Button)(() => ({
    height: '28px',
    boxShadow: 'none',
    padding: '5px 10px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor:
          themes.authentication.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: themes.authentication.palette.grey[200],
      '&:hover': {
        backgroundColor: themes.authentication.palette.grey[200],
      },
    },
  }));

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '68px',
    border: 'none',
    borderRadius: '3px',
    padding: '15px 20px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  }));

  return (
    <>
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Breadcrumb
          disabled
          size="small"
          color="secondary"
          variant="contained"
        >
          <Typography
            variant="body2"
            color={themes.authentication.palette.text.secondary}
          >
            Início
          </Typography>
        </Breadcrumb>
        <Breadcrumb
          size="small"
          variant="contained"
          color={
            stepMenuTicket == true ? 'primary' : 'secondary'
          }
        >
          <Typography
            variant="body2"
            color={
              stepMenuTicket == true
                ? themes.authentication.palette.common.white
                : themes.authentication.palette.text.secondary
            }
          >
            Boleto
          </Typography>
        </Breadcrumb>
        {!stepMenuTicket && (
          <Breadcrumb
            size="small"
            variant="contained"
            color={
              stepLineTicket == true ? 'primary' : 'secondary'
            }
          >
            <Typography
              variant="body2"
              color={
                stepLineTicket == true
                  ? themes.authentication.palette.common.white
                  : themes.authentication.palette.text.secondary
              }
            >
              Linha digitável
            </Typography>
          </Breadcrumb>
        )}

        {!stepMenuTicket && !stepLineTicket && (
          <Breadcrumb
            size="small"
            variant="contained"
            color={
              stepRevieTicket == true ? 'primary' : 'secondary'
            }
          >
            <Typography
              variant="body2"
              color={
                stepRevieTicket == true
                  ? themes.authentication.palette.common.white
                  : themes.authentication.palette.text.secondary
              }
            >
              Revisão
            </Typography>
          </Breadcrumb>
        )}

        {!stepMenuTicket && !stepLineTicket && !stepRevieTicket && (
          <Breadcrumb
            size="small"
            variant="contained"
            color={
              stepPinTicket == true ? 'primary' : 'secondary'
            }
          >
            <Typography
              variant="body2"
              color={
                stepPinTicket == true
                  ? themes.authentication.palette.common.white
                  : themes.authentication.palette.text.secondary
              }
            >
              Senha
            </Typography>
          </Breadcrumb>
        )}
      </Box>
      <Box marginTop={1}>
        {stepMenuTicket && (
          <>
            <List
              sx={{
                gap: '10px',
                paddingY: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListItem component="li" sx={{ padding: 0 }}>
                <Buttons
                  variant="outlined"
                  onClick={handleLinkStepMenu}
                  endIcon={
                    <Icon
                      size={12}
                      name="arrow"
                      rotate="-90deg"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    />
                  }
                >
                  <Box
                    gap={2}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon name="boleto" size={20} />
                    <Box textAlign="left">
                      <Typography
                        variant="body1"
                        component="span"
                        color={
                          themes.authentication.palette.text
                            .primary
                        }
                      >
                        Pagar boleto
                      </Typography>
                      <Typography
                        component="p"
                        variant="body2"
                        color={
                          themes.authentication.palette.text
                            .secondary
                        }
                      >
                        Escaneie ou digite o código.
                      </Typography>
                    </Box>
                  </Box>
                </Buttons>
              </ListItem>
            </List>
            <Box display="flex" justifyContent="space-between">
              <ButtonReturn
                component="a"
                onClick={() => router.back()}
              >
                Voltar
              </ButtonReturn>
            </Box>
          </>
        )}

        {stepLineTicket && (
          <StepTypeableLine
            user_id={user.id}
            stepLineTicket={stepLineTicket}
            stepRevieTicket={stepRevieTicket}
            setStepLineTicket={setStepLineTicket}
            setStepRevieTicket={setStepRevieTicket}
            setTransferPromise={setTransferPromise}
            setStepMenuTicket={setStepMenuTicket}
          />
        )}

        {stepRevieTicket &&
          transferPromise.type == FiatOperation.BILL && (
            <StepReview
              user_id={user.id}
              data={transferPromise}
              onResponsePin={onResponsePin}
              setStepLineTicket={setStepLineTicket}
              setStepRevieTicket={setStepRevieTicket}
            />
          )}

        {stepPinTicket && (
          <StepPin
            user_id={user_id}
            data={resultTicket}
            setStepPinTicket={setStepPinTicket}
            setStepRevieTicket={setStepRevieTicket}
          />
        )}
      </Box>
    </>
  );
};

export default TicketWallet;
