import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Icon, themes } from '@2ndmarket/components';
import { useMe, useWallet } from '@2ndmarket/services';

import {
  FiatOperation,
  formatCpfCnpj,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

interface DataProps {
  user_id: number;
  operation: string;
}

const Ted: React.FC<DataProps> = ({ user_id, operation }) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);
  const { data: wallet } = useWallet(user.id);

  const [open, setOpen] = useState(false);
  const [copiedData, setCopiedData] = useState<string>('');

  useEffect(() => {
    if (!wallet) return;
  }, [wallet]);

  const tedBalance = [
    {
      title: 'CPF',
      value: formatCpfCnpj(user.cpf_cnpj),
    },
    {
      title: wallet.bank_name,
      value: wallet.bank_code,
    },
    {
      title: 'Agência',
      value: wallet.bank_branch,
    },
    {
      title: 'Conta',
      value: wallet.bank_account_number,
    },
  ];

  const toogleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = (title: string, value: string) => {
    const copiedText = `${title}: ${value}`;
    navigator.clipboard.writeText(copiedText);
    setCopiedData(copiedText);
  };

  const handleCopyData = (id: string, data: any) => {
    const copiedText = data.map((item: any) => {
      return `${item.title}: ${item.value}`;
    });

    navigator.clipboard.writeText(copiedText.join('\n'));
    setCopiedData(copiedText.join('\n'));
  };

  const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '68px',
    border: 'none',
    borderRadius: '3px',
    padding: '15px 20px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  }));

  const DrawerHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 4, 2, 4),
  }));

  const DrawerBody = styled(Box)(({ theme }) => ({
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
  }));

  const Line = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '13px 0',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  }));

  const DrawerFooter = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 4),
  }));

  return (
    <Box>
      <List
        sx={{
          gap: '10px',
          paddingY: 0,
          display: 'flex',
          marginBottom: '16px',
          flexDirection: 'column',
        }}
      >
        <ListItem component="li" sx={{ padding: 0 }}>
          <Buttons
            variant="outlined"
            onClick={toogleDrawer(true)}
            endIcon={
              <Icon
                size={12}
                name="arrow"
                rotate="-90deg"
                color={themes.authentication.palette.grey[300]}
              />
            }
          >
            <Box gap={2} display="flex" alignItems="center">
              <Icon name="bank" size={20} />
              <Box textAlign="left">
                <Typography
                  variant="body1"
                  component="span"
                  color={
                    themes.authentication.palette.text.primary
                  }
                >
                  TED
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  color={
                    themes.authentication.palette.text.secondary
                  }
                >
                  Informe agência e conta
                </Typography>
              </Box>
            </Box>
          </Buttons>
        </ListItem>
      </List>

      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={handleClose}
      >
        <DrawerHeader width={395}>
          <Stack
            gap={6}
            width="80%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              {FiatOperation.TED}
            </Typography>
          </Stack>
        </DrawerHeader>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <DrawerBody width={395}>
            <Typography variant="body1" component="p">
              Copie os dados da conta do cliente abaixo e cole
              nos respectivos campos de transfêrencia da outra
              instituição:
            </Typography>
            <Alert
              severity="warning"
              variant="outlined"
              icon={<Icon name="info" size={18} />}
              sx={{
                border: 1,
                borderRadius: '3px',
                marginBottom: '8px',
                alignItems: 'center',
                borderColor: 'warning.main',
                backgroundColor: 'warning.contrastText',
              }}
            >
              <Typography variant="body2">
                No momento, o cliente só pode receber depósitos
                de mesma titularidade.
              </Typography>
            </Alert>
            <Box>
              {tedBalance.map((item, index) => (
                <Line
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box>
                    <Typography
                      component="p"
                      variant="body1"
                      color={
                        themes.authentication.palette.text
                          .disabled
                      }
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Box
                    gap={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="p">
                      {item.value}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleCopy(item.title, item.value)
                      }
                    >
                      <Icon
                        name="copy"
                        size={20}
                        color={
                          themes.authentication.palette.grey[300]
                        }
                      />
                    </IconButton>
                  </Box>
                </Line>
              ))}
            </Box>
          </DrawerBody>
          <DrawerFooter
            bottom={0}
            width={395}
            position="fixed"
            sx={{
              backgroundColor: 'white',
              padding: '20px 24px',
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() =>
                handleCopyData('box-ted', tedBalance)
              }
              sx={{ width: '100%', boxShadow: 'none' }}
            >
              Compartilhar
            </Button>
          </DrawerFooter>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Ted;
