import NextLink from 'next/link';
import { themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Header, Navigation } from '../../components/';
import { Targets } from '@2ndmarket/helpers';

const Panel: React.FC = () => {
  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-panel"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            marginBottom={3}
          >
            Dashboard
          </Typography>
          {/*
          <Stack
            width="100%"
            spacing={2}
            direction={{
              lg: 'row',
              md: 'column',
              sm: 'column',
              xs: 'column',
            }}
          >
            <Box
              gap={2}
              width={{
                lg: '50%',
                md: '100%',
                sm: '100%',
                xs: '100%',
              }}
              display="flex"
              flexDirection={{
                lg: 'row',
                md: 'row',
                sm: 'row',
                xs: 'column',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  padding: 3,
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  borderRadius: '6px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Chip
                  size="small"
                  color="primary"
                  sx={{ height: 8, width: 40, marginBottom: 3 }}
                />
                <Box>
                  <Typography variant="h2" component="h2">
                    130
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ marginBottom: 4 }}
                  >
                    Ativos
                  </Typography>
                </Box>
                <NextLink
                  href={Targets.BACKOFFICE_USERS_CREATE}
                  passHref
                >
                  <Link>Novo usuário</Link>
                </NextLink>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  padding: 3,
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  borderRadius: '6px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Chip
                  size="small"
                  color="warning"
                  sx={{ height: 8, width: 40, marginBottom: 3 }}
                />
                <Box>
                  <Typography variant="h2" component="h2">
                    14
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ marginBottom: 4 }}
                  >
                    Em análise
                  </Typography>
                </Box>
                <NextLink
                  href={Targets.BACKOFFICE_USERS_LIST}
                  passHref
                >
                  <Link
                    color={
                      themes.authentication.palette.warning.main
                    }
                  >
                    Revisar cadastro
                  </Link>
                </NextLink>
              </Card>
            </Box>
            <Box
              gap={2}
              width={{
                lg: '50%',
                md: '100%',
                sm: '100%',
                xs: '100%',
              }}
              display="flex"
              flexDirection={{
                lg: 'row',
                md: 'row',
                sm: 'row',
                xs: 'column',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  border: 'none',
                  borderRadius: '6px',
                }}
              >
                <Box padding={3}>
                  <Chip
                    size="small"
                    color="error"
                    sx={{
                      height: 8,
                      width: 40,
                      marginBottom: 5.4,
                    }}
                  />
                  <Typography variant="h2" component="h2">
                    32
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ marginBottom: 4 }}
                  >
                    Sem KYC
                  </Typography>
                </Box>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  gap: 2,
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'transparent',
                }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    padding: 3,
                    width: '100%',
                    border: 'none',
                    display: 'flex',
                    borderRadius: '6px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="h2" component="h2">
                      17
                    </Typography>
                    <Typography variant="body1" component="p">
                      Bloqueados
                    </Typography>
                  </Box>
                  <Chip
                    size="small"
                    sx={{
                      height: 8,
                      width: 40,
                      marginBottom: 5,
                      backgroundColor:
                        themes.authentication.palette.grey[300],
                    }}
                  />
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    padding: 3,
                    width: '100%',
                    border: 'none',
                    display: 'flex',
                    borderRadius: '6px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="h2" component="h2">
                      9
                    </Typography>
                    <Typography variant="body1" component="p">
                      Desativados
                    </Typography>
                  </Box>
                  <Chip
                    size="small"
                    sx={{
                      height: 8,
                      width: 40,
                      marginBottom: 5,
                      backgroundColor:
                        themes.authentication.palette.grey[300],
                    }}
                  />
                </Card>
              </Card>
            </Box>
          </Stack>
          */}
        </Box>
      </Box>
    </Grid>
  );
};

export default Panel;
