import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { IProfile, UserIdProps } from '@2ndmarket/types';

import {
  MaskedInput,
  ModalDialog,
  MuiLoader,
  themes,
} from '@2ndmarket/components';

import {
  updateProfile,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import {
  RegisterDocuments,
  SocialNameExist,
  IProfilePF,
  HttpStatus,
  HttpError,
  Documents,
  Targets,
  Masks,
  UserStatuses,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

import { schemaPF, defaultValuesPF } from './formProfile';

const ProfilePF: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();

  const {
    error,
    data: profile,
    mutate: mutateUserProfile,
  } = useProfile(user_id);

  const { data: user } = useMe(user_id);

  let textInput = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [socialNameExist, setSocialNameExist] = useState('');

  let defaultValues = defaultValuesPF;
  const {
    reset,
    control,
    register,
    setError,
    setValue,
    getValues,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaPF),
  });

  const socialNameSelect = useWatch({
    control,
    name: 'social_name_exist',
  });

  const socialNameOptions = [
    { value: SocialNameExist.NO, label: 'Não' },
    { value: SocialNameExist.YES, label: 'Sim' },
  ];

  useEffect(() => {
    if (error || !profile || !user) {
      if (error.status == HttpStatus.ServerErrorInternal) {
        router.push(Targets.SERVER_ERROR_INTERNAL);
      }
      return;
    }

    if (profile.social_name !== '') {
      setSocialNameExist(SocialNameExist.YES);
      setValue('social_name_exist', SocialNameExist.YES);
    }

    if (profile.social_name === '') {
      setSocialNameExist(SocialNameExist.NO);
      setValue('social_name_exist', SocialNameExist.NO);
    }

    reset({
      name: user.name,
      email: user.email,
      person_type: user.person_type,
      cpf_cnpj: user.cpf_cnpj,
      social_name: profile.social_name ?? '',
      birthdate:
        dayjs(profile.birthdate)
          .locale(ptBR)
          .format('DD/MM/YYYY') ?? '',
      social_name_exist: profile.social_name
        ? SocialNameExist.YES
        : SocialNameExist.NO,
    });
  }, [user, error, reset, router, profile, setValue]);

  const dateCreatedAt = dayjs(user.created_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const dateUpdateAt = dayjs(user.updated_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const handleError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorMessage(error.error);
      setShowModal(true);
    }
  };

  const onSubmit = async (data: IProfilePF) => {
    setIsLoading(true);

    updateProfile(
      {
        ...data,
        birthdate: data.birthdate.split('/').reverse().join('-'),
      },
      user_id,
    )
      .then((data: IProfile) => {
        mutateUserProfile(data);
        router.push(
          `${Targets.BACKOFFICE_USERS_PROFILE}/${user_id}`,
        );
      })
      .catch((error: HttpError) => {
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const SelectStatus = styled(Select)(() => ({
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
  }));

  const PaperColumn = styled(Paper)(() => ({
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  }));

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{ marginBottom: { xs: '5px', sm: 0 } }}
      >
        <InputLabel
          variant="outlined"
          htmlFor="uncontrolled-native"
        >
          Status
        </InputLabel>
        <SelectStatus
          disabled
          fullWidth
          defaultValue={user.status}
        >
          {UserStatuses.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStatus>
      </FormControl>
      <Box
        gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Box width="100%" gap={1} display="grid">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                disabled
                {...field}
                fullWidth
                label="Nome"
                margin="dense"
                value={user.name}
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 200 }}
              />
            )}
          />
          <TextField
            select
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Tipo de cadastro"
            defaultValue={Documents.CPF}
          >
            {RegisterDocuments.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                variant="outlined"
                label="Data de nascimento"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    mask: Masks.DATE,
                  },
                }}
                error={Boolean(formState.errors.birthdate)}
                helperText={
                  formState.errors.birthdate &&
                  formState.errors.birthdate.message
                }
              />
            )}
          />
        </Box>
        <Box width="100%" gap={1} display="grid">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                fullWidth
                margin="dense"
                label="E-mail"
                value={user.email}
                variant="outlined"
                autoComplete="off"
                inputProps={{
                  style: { textTransform: 'lowercase' },
                  maxLength: 75,
                }}
              />
            )}
          />
          <Controller
            name="cpf_cnpj"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                fullWidth
                margin="dense"
                label="N.º CPF"
                variant="outlined"
                value={user.cpf_cnpj}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    mask: Masks.CPF,
                    unmask: true,
                  },
                }}
                inputRef={textInput}
              />
            )}
          />
          <Controller
            name="social_name_exist"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                margin="dense"
                variant="outlined"
                label="Nome social"
                {...register('social_name_exist', {
                  required: true,
                })}
              >
                {socialNameOptions.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={socialNameExist == option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
      </Box>
      {socialNameSelect == SocialNameExist.YES && (
        <Box width="100%">
          <Controller
            name="social_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                variant="outlined"
                label="Nome completo"
                {...register(
                  'social_name',
                  socialNameSelect == SocialNameExist.YES
                    ? { required: true }
                    : {},
                )}
                inputProps={{ maxLength: 90 }}
                error={Boolean(formState.errors.social_name)}
                helperText={
                  formState.errors.social_name &&
                  formState.errors.social_name.message
                }
              />
            )}
          />
        </Box>
      )}
      <Stack
        spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        width="100%"
        direction={{
          xs: 'column',
          sm: 'row',
          md: 'row',
          lg: 'row',
        }}
      >
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Cadastro em"
            value={dateCreatedAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Atualizado em"
            value={dateUpdateAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
      </Stack>
      <Box
        marginY={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Salvar
        </Button>
      </Box>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default ProfilePF;
