import React from 'react';
import Grid from '@mui/material/Grid';

interface RoutineProps {
  full?: boolean;
}

const Routine: React.FC<RoutineProps> = ({ full }) => {
  return (
    <Grid
      item
      md={full ? 12 : 7}
      sm={full ? 12 : 3}
      xs={full ? 12 : false}
      paddingX="9%"
      paddingY={8}
      sx={{
        backgroundImage: 'url(/background.mui.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: {
          sm: 'block',
          xs: `${full ? 'block' : 'none'}`,
        },
      }}
    />
  );
};

export default Routine;
