import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  IProfile,
  StepOnboardingBacicProps,
} from '@2ndmarket/types';

import {
  themes,
  MuiLoader,
  ModalDialog,
  MaskedInput,
  Icon,
} from '@2ndmarket/components';

import {
  RegisterDocuments,
  OnboardingStep,
  legalPerson,
  IProfilePJ,
  ReviewStep,
  HttpStatus,
  HttpError,
  Documents,
  Targets,
  Masks,
} from '@2ndmarket/helpers';

import {
  updateProfile,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { schemaPJ, defaultValuesPJ } from './formProfile';
import { styled } from '@mui/material/styles';

const ProfilePJ: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  const router = useRouter();
  let textInput = useRef(null);

  const {
    error,
    data: profile,
    mutate: mutateUserProfile,
  } = useProfile(user_id);

  const { data: user } = useMe(Number(user_id));
  const is_processing = profile.status == ReviewStep.PROCESSING;

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkStatus, setCheckStatus] = useState(status);

  const [accordion, setAccordion] = useState<string | false>(
    'profile',
  );
  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  let defaultValues = defaultValuesPJ;
  const {
    reset,
    control,
    register,
    setError,
    setValue,
    getValues,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaPJ),
  });

  const handleReset = () => {
    reset();
  };

  const handleError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorMessage(error.error);
      setShowModal(true);
    }
  };

  const onSubmit = (data: IProfilePJ) => {
    setIsLoading(true);
    updateProfile(
      {
        ...data,
        birthdate: data.birthdate.split('/').reverse().join('-'),
      },
      user_id,
    )
      .then((data: IProfile) => {
        mutateUserProfile(data);
      })
      .catch((error: HttpError) => {
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeStatus = (reviewStep: ReviewStep) => {
    status = reviewStep;
    setStatus(status);
  };

  useEffect(() => {
    if (error || !profile) {
      if (error.status == HttpStatus.ServerErrorInternal) {
        router.push(Targets.SERVER_ERROR_INTERNAL);
      }
      return;
    }

    if (step == OnboardingStep.PROFILE) {
      setCheckStatus(status);
    }

    reset({
      ...profile,
      fantasy_name:
        (getValues('fantasy_name') || profile.fantasy_name) ??
        '',
      primary_cnae:
        (getValues('primary_cnae') || profile.primary_cnae) ??
        '',
      contact_cpf:
        (getValues('contact_cpf') || profile.contact_cpf) ?? '',
      contact_name:
        (getValues('contact_name') || profile.contact_name) ??
        '',
      company_title:
        (getValues('company_title') || profile.company_title) ??
        '',
      legal_nature:
        (getValues('legal_nature') || profile.legal_nature) ??
        '',
      birthdate:
        getValues('birthdate') || profile.birthdate
          ? getValues('birthdate')
              .split('-')
              .reverse()
              .join('/') ||
            profile.birthdate.split('-').reverse().join('/')
          : '',
    });
  }, [error, reset, step, status, profile, router, getValues]);

  const ButtonCheckbox = styled(Button)(({ theme }) => ({
    width: '140px',
    height: '48px',
    padding: '15px',
    border: 'none',
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      border: 'none',
    },
    '.MuiButton-startIcon': {
      width: 20,
      height: 20,
      display: 'flex',
      borderRadius: 3,
      border: '1px solid',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'white',
      borderColor: theme.palette.grey[200],
    },
  }));

  const BoxChecked = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    backgroundColor: themes.authentication.palette.primary.main,
  }));

  const ButtonSave = styled(Button)(() => ({
    maxWidth: '172px',
    height: '48px',
    border: 'none',
    boxShadow: 'none',
    padding: '16px 32px',
    '&:hover': {
      border: 'none',
    },
  }));

  return (
    <>
      <Accordion
        expanded={accordion == 'profile'}
        onChange={handleAccordion('profile')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Dados da empresa
          </Typography>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            paddingTop={2}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              <Box width="100%" gap={1} display="grid">
                <TextField
                  disabled
                  fullWidth
                  name="name"
                  margin="dense"
                  value={user.name}
                  variant="outlined"
                  autoComplete="off"
                  label="Razão social"
                  inputProps={{ maxLength: 200 }}
                />
                <TextField
                  select
                  disabled
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Tipo de cadastro"
                  defaultValue={Documents.CNPJ}
                >
                  {RegisterDocuments.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Controller
                  name="fantasy_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Nome fantasia"
                      inputProps={{ maxLength: 200 }}
                      {...register('fantasy_name', {
                        required: true,
                      })}
                      error={Boolean(
                        formState.errors.fantasy_name,
                      )}
                      helperText={
                        formState.errors.fantasy_name &&
                        formState.errors.fantasy_name.message
                      }
                    />
                  )}
                />
                <Controller
                  name="primary_cnae"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="CNAE principal"
                      InputProps={{
                        inputComponent: MaskedInput,
                        value: getValues('primary_cnae'),
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.CNAE,
                          unmask: true,
                        },
                      }}
                      error={Boolean(
                        formState.errors.primary_cnae,
                      )}
                      helperText={
                        formState.errors.primary_cnae &&
                        formState.errors.primary_cnae.message
                      }
                    />
                  )}
                />
              </Box>
              <Box width="100%" gap={1} display="grid">
                <TextField
                  disabled
                  fullWidth
                  name="email"
                  margin="dense"
                  label="E-mail"
                  value={user.email}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={{
                    style: { textTransform: 'lowercase' },
                    maxLength: 75,
                  }}
                />
                <TextField
                  disabled
                  fullWidth
                  margin="dense"
                  name="cpf_cnpj"
                  variant="outlined"
                  label="N.º do CNPJ"
                  value={user.cpf_cnpj}
                  inputRef={textInput}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.CNPJ,
                      unmask: true,
                    },
                  }}
                />
                <Controller
                  name="legal_nature"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      fullWidth
                      {...field}
                      margin="dense"
                      variant="outlined"
                      label="Natureza legal"
                      {...register('legal_nature', {
                        required: true,
                      })}
                      error={Boolean(
                        formState.errors.legal_nature,
                      )}
                      helperText={
                        formState.errors.legal_nature &&
                        formState.errors.legal_nature.message
                      }
                    >
                      {legalPerson.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          selected={
                            profile.legal_nature == option.value
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            <Box paddingTop={2} lineHeight={2}>
              <Typography component="strong" variant="subtitle1">
                Responsável
              </Typography>
              <Typography component="p" variant="body1">
                Preencha os dados do responsável pela empresa que
                será o gestor da conta no ecossistema.
              </Typography>
            </Box>
            <Box
              gap={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              <Box width="100%" gap={1} display="grid">
                <Controller
                  name="contact_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Nome e sobrenome"
                      error={Boolean(
                        formState.errors.contact_name,
                      )}
                      helperText={
                        formState.errors.contact_name &&
                        formState.errors.contact_name.message
                      }
                      InputProps={{
                        inputProps: { maxLength: 200 },
                        value: getValues('contact_name'),
                      }}
                    />
                  )}
                />
                <Controller
                  name="company_title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Cargo"
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        value: getValues('company_title'),
                        inputProps: { maxLength: 200 },
                      }}
                      error={Boolean(
                        formState.errors.company_title,
                      )}
                      helperText={
                        formState.errors.company_title &&
                        formState.errors.company_title.message
                      }
                    />
                  )}
                />
              </Box>
              <Box width="100%" gap={1} display="grid">
                <Controller
                  name="contact_cpf"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="CPF"
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        inputComponent: MaskedInput,
                        value: getValues('contact_cpf'),
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.CPF,
                          unmask: true,
                        },
                      }}
                      inputRef={textInput}
                      error={Boolean(
                        formState.errors.contact_cpf,
                      )}
                      helperText={
                        formState.errors.contact_cpf &&
                        formState.errors.contact_cpf.message
                      }
                    />
                  )}
                />
                <Controller
                  name="birthdate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Data de nascimento"
                      InputProps={{
                        inputComponent: MaskedInput,
                        value: getValues('birthdate'),
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.DATE,
                        },
                      }}
                      error={Boolean(formState.errors.birthdate)}
                      helperText={
                        formState.errors.birthdate &&
                        formState.errors.birthdate.message
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              gap={2}
              width="100%"
              paddingTop={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                gap={2}
                display="flex"
                alignItems="center"
                className="stepStatus"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-start',
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
              >
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.APPROVED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.APPROVED)
                  }
                >
                  Aprovado
                </ButtonCheckbox>
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.REFUSED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.REFUSED)
                  }
                >
                  Rejeitado
                </ButtonCheckbox>
              </Box>
              <Box
                gap={{
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 8,
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
                display="flex"
                alignItems="center"
                className="stepSend"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
              >
                <Typography
                  height={56}
                  component="p"
                  display="flex"
                  variant="body1"
                  alignItems="center"
                >
                  <Button
                    component="a"
                    variant="text"
                    disabled={
                      !formState.isDirty || is_processing
                    }
                    onClick={() => handleReset()}
                    sx={{
                      border: 'none !important',
                      textDecoration: 'underline !important',
                      backgroundColor: 'transparent !important',
                    }}
                  >
                    Cancelar
                  </Button>
                </Typography>
                <ButtonSave
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isDirty || is_processing}
                >
                  Salvar
                </ButtonSave>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ProfilePJ;
