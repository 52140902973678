import { useRouter } from 'next/router';
import { QRCodeSVG } from 'qrcode.react';

import { themes } from '@2ndmarket/components';
import { ReceiptIdProps } from '@2ndmarket/types';
import { WalletTransaction } from '@2ndmarket/helpers';
import { useMe, useCryptoWallet } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

const DepositWallet: React.FC<ReceiptIdProps> = ({
  user_id,
  crypto,
}) => {
  const router = useRouter();
  const coin = crypto as string;

  const { data: user } = useMe(user_id);
  const { data: wallet } = useCryptoWallet(coin, user.id);

  if (typeof navigator !== 'undefined') {
    var sharePromise = navigator.share;
  }

  useEffect(() => {
    if (!wallet) return;
  }, [wallet]);

  const share = async () => {
    try {
      await sharePromise?.({ text: wallet[0].address });
    } catch (e) {}
  };

  const copy = () => {
    navigator.clipboard.writeText(wallet[0].address);
  };

  const Breadcrumb = styled(Button)(() => ({
    height: '28px',
    boxShadow: 'none',
    padding: '5px 10px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor:
          themes.authentication.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: themes.authentication.palette.grey[200],
      '&:hover': {
        backgroundColor: themes.authentication.palette.grey[200],
      },
    },
  }));

  const ButtonCopy = styled(Button)<{ component: 'a' }>(() => ({
    padding: '0',
    height: '28px',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'underline',
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
      textDecoration: 'underline',
      color: themes.authentication.palette.primary.dark,
    },
  }));

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <>
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Breadcrumb
          disabled
          size="small"
          color="secondary"
          variant="contained"
        >
          <Typography
            variant="body2"
            color={themes.authentication.palette.text.secondary}
          >
            Início
          </Typography>
        </Breadcrumb>
        <Breadcrumb
          size="small"
          variant="contained"
          color={
            router.locale === WalletTransaction.DEPOSIT
              ? 'primary'
              : 'secondary'
          }
        >
          <Typography
            variant="body2"
            color={
              router.locale === WalletTransaction.DEPOSIT
                ? themes.authentication.palette.common.white
                : themes.authentication.palette.text.secondary
            }
          >
            Depositar
          </Typography>
        </Breadcrumb>
      </Box>

      <Box
        padding={4}
        marginTop={2}
        width="100%"
        sx={{
          backgroundColor:
            themes.authentication.palette.grey[900],
        }}
      >
        <Box
          gap={2}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <QRCodeSVG
            size={189}
            bgColor="transparent"
            value={wallet[0].address}
            fgColor={themes.authentication.palette.primary.main}
          />
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="body1" component="p">
              {wallet[0].address.substr(0, 10)}...
              {wallet[0].address.substr(25)}
            </Typography>
            <ButtonCopy component="a" onClick={copy}>
              Copiar
            </ButtonCopy>
            {
              //@ts-ignore
              sharePromise && (
                <Button
                  type="button"
                  onClick={share}
                  color="secondary"
                  variant="contained"
                  sx={{ height: 48, width: 250, marginTop: 2 }}
                >
                  Compartilhar
                </Button>
              )
            }
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ButtonReturn
          component="a"
          onClick={() => router.back()}
        >
          Voltar
        </ButtonReturn>
      </Box>
    </>
  );
};

export default DepositWallet;
