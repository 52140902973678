import { yupPt, validateName } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  name: yupPt.string().required(),
});

const defaultValues = {
  name: '',
  code: '',
  mask_branch: '',
  mask_account: '',
};

export { schema, defaultValues };
