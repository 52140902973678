import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  Controller,
  useWatch,
  SubmitHandler,
} from 'react-hook-form';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import {
  getCityInfo,
  getPostcodeInfo,
  updateAddress,
  useAddress,
  useMe,
} from '@2ndmarket/services';

import {
  IAddressUpdate,
  UserIdProps,
  IAddress,
  IState,
  ICity,
} from '@2ndmarket/types';

import {
  Masks,
  IPostCode,
  UserStatuses,
  HttpError,
  HttpStatus,
  Targets,
} from '@2ndmarket/helpers';

import {
  MaskedInput,
  ModalDialog,
  StateDrawer,
  CityDrawer,
  MuiLoader,
  themes,
  Icon,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import {
  schema,
  defaultValues,
} from '../OnboardingBasic/Address/formAddress';

const Address: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const { data: user } = useMe(user_id);

  const { data: address, mutate: mutateUserAddress } =
    useAddress(user_id);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [cityOpen, setCityOpen] = useState(false);
  const [stateOpen, setStateOpen] = useState(false);
  const [changeState, setChangeState] = useState(false);

  const {
    reset,
    resetField,
    control,
    register,
    setError,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const stateWatcher = useWatch({ control, name: 'state_id' });
  const cityWatcher = useWatch({ control, name: 'city_id' });

  const [selectedState, setSelectedState] = useState<IState>({
    id: 0,
    name: '',
  });

  const [selectedCity, setSelectedCity] = useState<IState>({
    id: 0,
    name: '',
  });

  const dateCreatedAt = dayjs(user.created_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const dateUpdateAt = dayjs(user.updated_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const getCepInfos = async (event: React.FormEvent) => {
    setIsLoading(true);
    clearErrors('postcode');

    const postcode = getValues('postcode');
    await getPostcodeInfo(postcode)
      .then((data: IPostCode) => {
        resetField('number');
        resetField('complement');
        setValue('state_id', data.state_id);
        setValue('city_id', data.city_id);
        setValue('street', data.street);
        setValue('district', data.district);

        getCityInfo(data.city_id)
          .then((cityData: ICity) => {
            setValue('state_name', cityData.state.name);
            setValue('city_name', cityData.name);
            setSelectedState({
              id: data.state_id,
              name: cityData.state.name,
            });
            setSelectedCity({
              id: data.city_id,
              name: cityData.name,
            });
          })
          .catch(() => {
            setError('postcode', {
              type: 'custom',
              message: 'Problema ao buscar a cidade',
            });
          });
      })
      .catch(() => {
        setError('postcode', {
          type: 'custom',
          message: 'CEP não encontrado',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit: SubmitHandler<IAddressUpdate> = data => {
    setIsLoading(true);
    updateAddress(
      {
        postcode: data.postcode.replace('-', ''),
        state_id: Number(data.state_id),
        city_id: Number(data.city_id),
        district: data.district,
        street: data.street,
        number: data.number,
        complement: data.complement,
      },
      user_id,
    )
      .then((data: IAddress) => {
        mutateUserAddress(data);
        router.push(
          `${Targets.BACKOFFICE_USERS_ADDRESS}/${user_id}`,
        );
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (changeState) {
      reset({ city_id: 0, city_name: '' });
      setChangeState(false);
    }

    setValue('state_id', selectedState.id);
    setValue('state_name', selectedState.name);
  }, [selectedState, reset, setValue, changeState]);

  useEffect(() => {
    setValue('city_id', selectedCity.id);
    setValue('city_name', selectedCity.name);
  }, [selectedCity, setValue]);

  useEffect(() => {
    if (!address) {
      return;
    }

    setSelectedState({
      id: address.state_id ?? 0,
      name: address.state ? address.state.name : '',
    });

    setSelectedCity({
      id: address.city_id ?? 0,
      name: address.city ? address.city.name : '',
    });

    reset({
      postcode: address.postcode ?? '',
      state_id: address.state_id ?? 0,
      state_name: address.state ? address.state.name : '',
      city_id: address.city_id ?? 0,
      city_name: address.city ? address.city.name : '',
      street: address.street ?? '',
      number: address.number ?? '',
      complement: address.complement ?? '',
      district: address.district ?? '',
    });
  }, [address, reset]);

  const SelectStatus = styled(Select)(() => ({
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
  }));

  const PaperColumn = styled(Paper)(() => ({
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  }));

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{ marginBottom: { xs: '5px', sm: 0 } }}
      >
        <InputLabel
          variant="outlined"
          htmlFor="uncontrolled-native"
        >
          Status
        </InputLabel>
        <SelectStatus
          disabled
          fullWidth
          defaultValue={user.status}
        >
          {UserStatuses.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStatus>
      </FormControl>
      <Box
        gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Box width="100%" gap={1} display="grid">
          <Box
            gap={2}
            width="100%"
            display="flex"
            alignItems="center"
          >
            <Controller
              name="postcode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CEP"
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.CEP,
                      unmask: true,
                    },
                  }}
                  error={Boolean(errors.postcode)}
                  helperText={
                    errors.postcode && errors.postcode.message
                  }
                  sx={{ width: '80%' }}
                />
              )}
            />
            <Button
              fullWidth
              type="button"
              color="secondary"
              variant="contained"
              onClick={getCepInfos}
              sx={{ height: '56px', width: '100px' }}
            >
              Buscar
            </Button>
          </Box>
          <Box width="100%">
            <TextField
              label="Cidade"
              fullWidth
              margin="dense"
              variant="outlined"
              {...register('city_name')}
              onClick={() => setCityOpen(true)}
              error={Boolean(errors.city_id)}
              helperText={
                errors.city_id && errors.city_id.message
              }
              disabled={stateWatcher == 0}
              InputProps={{
                inputProps: { readOnly: true },
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      size={12}
                      name="arrow"
                      rotate="-90deg"
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: cityWatcher ? true : false,
              }}
              sx={{
                input: {
                  cursor: 'pointer',
                  '&.Mui-disabled': { cursor: 'default' },
                },
              }}
            />
            <Input type="hidden" {...register('city_id')} />
          </Box>
          <Box gap={2} width="100%" display="flex">
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Número"
                  variant="outlined"
                  {...register('number')}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.CODE,
                      unmask: true,
                    },
                  }}
                  error={Boolean(errors.number)}
                  helperText={
                    errors.number && errors.number.message
                  }
                  sx={{ width: '50%' }}
                />
              )}
            />
            <Controller
              name="complement"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  variant="outlined"
                  label="Complemento"
                  {...register('complement')}
                  error={Boolean(errors.complement)}
                  helperText={
                    errors.complement &&
                    errors.complement.message
                  }
                  inputProps={{ maxLength: 90 }}
                  sx={{ width: '50%' }}
                />
              )}
            />
          </Box>
        </Box>
        <Box width="100%" gap={1} display="grid">
          <Box width="100%">
            <TextField
              label="Estado"
              fullWidth
              margin="dense"
              variant="outlined"
              {...register('state_name')}
              onClick={() => setStateOpen(true)}
              error={Boolean(errors.state_id)}
              helperText={
                errors.state_id && errors.state_id.message
              }
              InputProps={{
                inputProps: { readOnly: true },
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      name="arrow"
                      size={12}
                      rotate="-90deg"
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: stateWatcher ? true : false,
              }}
              sx={{ input: { cursor: 'pointer' } }}
            />
            <Input type="hidden" {...register('state_id')} />
          </Box>
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Rua"
                margin="dense"
                variant="outlined"
                {...register('street')}
                error={Boolean(errors.street)}
                helperText={
                  errors.street && errors.street.message
                }
                inputProps={{ maxLength: 200 }}
              />
            )}
          />
          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Bairro"
                margin="dense"
                variant="outlined"
                {...register('district')}
                error={Boolean(errors.district)}
                helperText={
                  errors.district && errors.district.message
                }
                inputProps={{ maxLength: 200 }}
              />
            )}
          />
        </Box>
      </Box>
      <Stack
        width="100%"
        spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        direction={{
          xs: 'column',
          sm: 'row',
          md: 'row',
          lg: 'row',
        }}
      >
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Cadastro em"
            value={dateCreatedAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Atualizado em"
            value={dateUpdateAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
      </Stack>
      <Box
        marginY={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Salvar
        </Button>
      </Box>

      <StateDrawer
        stateOpen={stateOpen}
        setStateOpen={setStateOpen}
        setChangeState={setChangeState}
        selectedState={selectedState.id}
        setSelectedState={setSelectedState}
      />

      <CityDrawer
        cityOpen={cityOpen}
        setCityOpen={setCityOpen}
        selectedCity={selectedCity.id}
        selectedState={selectedState.id}
        setSelectedCity={setSelectedCity}
      />

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default Address;
