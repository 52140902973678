import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  IStaff,
  IStaffUpdate,
  StaffIdProps,
} from '@2ndmarket/types';
import { useStaffMe, updateStaff } from '@2ndmarket/services';

import {
  Icon,
  themes,
  MaskedInput,
  MuiLoader,
  ModalDialog,
} from '@2ndmarket/components';

import {
  staffRoles,
  HttpStatus,
  HttpError,
  Masks,
  Targets,
} from '@2ndmarket/helpers';

import { Header, Navigation } from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';
import { schema, defaultValues } from './formTeam';

const TeamEdit: React.FC<StaffIdProps> = ({ team_id }) => {
  const router = useRouter();

  const {
    data: staffMe,
    mutate: mutateStaff,
    error: errorStaffMe,
  } = useStaffMe(team_id);

  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [mask, setMask] = useState(Masks.CPF);

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordAlter, setPasswordAlter] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleDowPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!staffMe) return;

    if (
      errorStaffMe &&
      errorStaffMe.status == HttpStatus.ClientErrorNotFound
    ) {
      setShowError(true);
    }

    reset({
      cpf: staffMe.cpf,
      name: staffMe.name,
      email: staffMe.email,
      phone: staffMe.phone,
      role_id: Number(staffMe.role.id) ?? 0,
      password: '',
    });
  }, [reset, staffMe, errorStaffMe]);

  const handleAlterPassword = () => {
    setPasswordAlter(true);
  };

  const handleReturn = () => {
    setShowError(false);
    router.push(`${Targets.BACKOFFICE_TEAM_LIST}`);
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorUnprocessableEntity:
        for (let fieldError of error.errors) {
          setError(fieldError.field as any, {
            type: 'custom',
            message: fieldError.message,
          });
        }
        break;
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
        setShowModal(true);
        setErrorMessage(error.error);

        reset({
          cpf: staffMe.cpf ?? '',
          name: staffMe.name ?? '',
          email: staffMe.email ?? '',
          phone: staffMe.phone ?? '',
          role_id: Number(staffMe.role.id) ?? 0,
        });
        break;
    }
  };

  const onSubmit = (data: IStaffUpdate) => {
    setIsLoading(true);

    updateStaff(
      team_id,
      data.cpf,
      data.email,
      data.name,
      data.password,
      data.phone,
      data.role_id,
    )
      .then((data: IStaff) => {
        mutateStaff();
        router.push(
          `${Targets.BACKOFFICE_TEAM_EDIT}/${team_id}`,
        );
      })
      .catch((error: HttpError) => {
        handleErrors(error);
      })
      .finally(() => {
        setIsLoading(false);
        setPasswordAlter(false);
      });
  };

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-users"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            padding: {
              xs: '24px 16px',
              lg: '24px 32px',
            },
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Box
            gap={4}
            display="flex"
            marginBottom={1}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton
              onClick={() => router.back()}
              sx={{ ml: -1 }}
            >
              <Icon
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography variant="h2" component="h2">
              Editar membro
            </Typography>
          </Box>
          <Box
            gap={2}
            display="flex"
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          />
          <Box
            gap={3}
            width="100%"
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '20%',
                  lg: '20%',
                },
              }}
            >
              <Button
                variant="outlined"
                endIcon={
                  <Icon
                    size={12}
                    name="arrow"
                    rotate="-90deg"
                    color={
                      themes.authentication.palette.grey[300]
                    }
                  />
                }
                sx={{
                  width: '100%',
                  height: '54px',
                  border: 'none',
                  borderRadius: '6px',
                  justifyContent: 'space-between',
                  backgroundColor:
                    themes.authentication.palette.grey[100],
                  '&:hover': {
                    border: 'none',
                    cursor: 'default',
                    backgroundColor:
                      themes.authentication.palette.grey[100],
                  },
                }}
              >
                Cadastro
              </Button>
            </Box>
            <Box
              gap={3}
              display="flex"
              flexDirection="column"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '80%',
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                color={themes.authentication.palette.grey[600]}
              >
                Cadastro
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:
                      themes.authentication.palette.grey[900],
                  },
                }}
              >
                <Box
                  gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                  display="flex"
                  flexDirection={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nome"
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.name)}
                          inputProps={{ maxLength: 200 }}
                          helperText={
                            errors.name && errors.name.message
                          }
                        />
                      )}
                    />
                    <Controller
                      name="cpf"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          label="CPF"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: {
                              inputMode: 'numeric',
                              unmask: true,
                              mask: mask,
                            },
                          }}
                          error={Boolean(errors.cpf)}
                          helperText={
                            errors.cpf && errors.cpf.message
                          }
                        />
                      )}
                    />
                    {staffMe?.cpf && !passwordAlter ? (
                      <Box
                        width="100%"
                        height="56px"
                        marginTop={1}
                      >
                        <Button
                          fullWidth
                          size="large"
                          component="a"
                          color="secondary"
                          variant="contained"
                          onClick={handleAlterPassword}
                          sx={{ height: '56px' }}
                        >
                          Alterar senha
                        </Button>
                      </Box>
                    ) : (
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Senha"
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleShowPassword}
                                    onMouseDown={
                                      handleDowPassword
                                    }
                                    aria-label="toggle password visibility"
                                  >
                                    {showPassword ? (
                                      <Icon
                                        size={20}
                                        name="view"
                                        color={
                                          themes.authentication
                                            .palette.grey[300]
                                        }
                                      />
                                    ) : (
                                      <Icon
                                        size={20}
                                        name="hide"
                                        color={
                                          themes.authentication
                                            .palette.grey[300]
                                        }
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            type={
                              showPassword ? 'text' : 'password'
                            }
                            error={Boolean(errors.password)}
                            helperText={
                              errors.password &&
                              errors.password.message
                            }
                          />
                        )}
                      />
                    )}
                  </Box>
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="dense"
                          label="E-mail"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.email)}
                          inputProps={{
                            maxLength: 75,
                            style: {
                              textTransform: 'lowercase',
                            },
                          }}
                          helperText={
                            errors.email && errors.email.message
                          }
                        />
                      )}
                    />
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="dense"
                          label="Telefone"
                          variant="outlined"
                          InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: {
                              inputMode: 'numeric',
                              mask: Masks.PHONE,
                              unmask: true,
                            },
                          }}
                          error={Boolean(errors.phone)}
                          helperText={
                            errors.phone && errors.phone.message
                          }
                        />
                      )}
                    />
                    <Controller
                      name="role_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          fullWidth
                          {...field}
                          label="Função"
                          margin="dense"
                          variant="outlined"
                          error={Boolean(errors.role_id)}
                          helperText={
                            errors.role_id &&
                            errors.role_id.message
                          }
                        >
                          {staffRoles.map((option, key) => (
                            <MenuItem
                              key={key}
                              value={option.id}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  marginY={2}
                  display="flex"
                  alignItems="center"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  }}
                  justifyContent="space-between"
                >
                  <ButtonReturn
                    component="a"
                    onClick={() => router.back()}
                  >
                    Voltar
                  </ButtonReturn>
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      paddingX: 8,
                      width: {
                        xs: '100%',
                        sm: 172,
                        md: 172,
                        lg: 172,
                      },
                    }}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>

              {isLoading && (
                <Backdrop
                  open={isLoading}
                  sx={{
                    right: 0,
                    left: 'auto',
                    width: '100%',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    color:
                      themes.authentication.palette.common.white,
                  }}
                >
                  <MuiLoader loaderState={isLoading} />
                </Backdrop>
              )}

              {showModal && (
                <ModalDialog
                  error
                  maxWidth="sm"
                  open={showModal}
                  errorMessage={errorMessage}
                  onClose={() => setShowModal(false)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {showError && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showError}
          errorMessage={errorStaffMe.error}
          errorTitle="Usuário não encontrado"
          onClose={handleReturn}
        />
      )}
    </Grid>
  );
};

export default TeamEdit;
