import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';

import { StepOnboardingBacicProps } from '@2ndmarket/types';

import {
  updateDocument,
  useDocument,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
  MuiButtonFile,
  CustomInputFile,
} from '@2ndmarket/components';

import {
  Masks,
  IDocument,
  Documents,
  HttpError,
  HttpStatus,
  ReviewStep,
  documentTypes,
  OnboardingStep,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { schema, defaultValues } from './formDocument';
import { styled } from '@mui/material/styles';

import BoxBtnsReviewStep from '../../../../components/BoxBtnsReviewStep';

const Document: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  let textInput = useRef(null);

  const { data: documents, mutate: mutateDocuments } =
    useDocument(user_id);

  const { data: profile } = useProfile(user_id);
  const { data: user } = useMe(user_id);

  const {
    reset,
    control,
    register,
    setValue,
    setError,
    getValues,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const documentSelected = useWatch({
    control,
    name: 'type',
  });

  let is_processing = documents.status == ReviewStep.PROCESSING;
  const [accordion, setAccordion] = useState<string | false>(
    'document',
  );

  const [inputFileBack, setInputFileBack] = useState(false);
  const [inputFileFront, setInputFileFront] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  const [nameFileBack] = useState('file_back');
  const [nameFileFront] = useState('file_front');
  const [errorMessage, setErrorMessage] = useState('');

  const [fileBack, setFileBack] = useState<File>();
  const [fileFront, setFileFront] = useState<File>();
  const [checkStatus, setCheckStatus] = useState(status);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const handleReset = () => {
    if (documents.file_back !== '') {
      setInputFileBack(false);
    }

    if (documents.file_front !== '') {
      setInputFileFront(false);
    }

    reset({
      type: documents.type ?? '',
      mothers_name: documents.mothers_name ?? '',
      file_front: documents.file_front ?? new File([], ''),
      file_back: documents.file_back ?? new File([], ''),
    });

    setFileFront({ name: '', size: 0, type: '' } as File);
    setFileBack({ name: '', size: 0, type: '' } as File);
  };

  const handleFileFront = (file: File) => {
    const inputFront = document.getElementById(
      'file_front',
    ) as HTMLInputElement;

    if (inputFront.name === nameFileFront) {
      formState.errors.file_front = undefined;

      setBtnDisabled(!formState.isDirty);
      setValue('file_front', file.name);
      setFileFront(file);
    }
  };

  const handleFileBack = (file: File) => {
    const inputBack = document.getElementById(
      'file_back',
    ) as HTMLInputElement;

    if (inputBack.name === nameFileBack) {
      formState.errors.file_back = undefined;

      setBtnDisabled(!formState.isDirty);
      setValue('file_back', file.name);
      setFileBack(file);
    }
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IDocument) => {
    if (data.file_front == '') {
      return (formState.errors.file_front =
        'Selecione um arquivo de frente' as never);
    } else if (fileFront) {
      data.file_front = fileFront;
    }

    if (data.file_back == '') {
      return (formState.errors.file_back =
        'Selecione um arquivo de verso' as never);
    } else if (fileBack) {
      data.file_back = fileBack;
    }

    if (data.expiration_date) {
      data.expiration_date = data.expiration_date
        .split('/')
        .reverse()
        .join('-');
    }

    setIsLoading(true);
    updateDocument({ ...data }, user_id)
      .then((data: IDocument) => {
        mutateDocuments();
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputFileBack = () => {
    setInputFileBack(true);
    setBtnDisabled(false);
  };

  const handleInputFileFront = () => {
    setInputFileFront(true);
    setBtnDisabled(false);
  };

  useEffect(() => {
    if (step === OnboardingStep.DOCUMENT) {
      setCheckStatus(status);
    }

    reset({
      type: (getValues('type') || documents.type) ?? '',
      mothers_name:
        (getValues('mothers_name') || documents.mothers_name) ??
        '',
      expiration_date: documents.expiration_date
        ? documents.expiration_date
            .split('-')
            .reverse()
            .join('/')
        : '',
      file_front: documents.file_front ?? '',
      file_back: documents.file_back ?? '',
    });
  }, [step, reset, status, documents, getValues]);

  const ButtonSave = styled(Button)(() => ({
    maxWidth: '172px',
    height: '48px',
    border: 'none',
    boxShadow: 'none',
    padding: '16px 32px',
    '&:hover': {
      border: 'none',
    },
  }));

  const ButtonFile = styled(Button)(({ theme }) => ({
    width: 207,
    height: 56,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[400],
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  }));

  return (
    <>
      <Accordion
        expanded={accordion == 'document'}
        onChange={handleAccordion('document')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Documento &nbsp;
            {user.person_type === Documents.CNPJ &&
              'do Responsável'}
          </Typography>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            paddingTop={2}
            component="form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(onSubmit)}
          >
            {user.person_type === Documents.CNPJ && (
              <Controller
                name="contact_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    disabled
                    margin="dense"
                    label="Responsável"
                    value={profile?.contact_name}
                    inputProps={{ maxLength: 200 }}
                    sx={{ marginBottom: 2 }}
                  />
                )}
              />
            )}
            <Box
              gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              <Box width="100%">
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      margin="dense"
                      label="Tipo de documento"
                      error={Boolean(formState.errors.type)}
                      InputProps={{
                        value: getValues('type'),
                      }}
                      helperText={
                        formState.errors.type &&
                        formState.errors.type.message
                      }
                    >
                      {documentTypes.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          selected={
                            documents.type === option.value
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                {Documents.CNH == documentSelected && (
                  <Controller
                    name="expiration_date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        margin="dense"
                        label="Data de expiração"
                        {...register('expiration_date')}
                        InputProps={{
                          inputComponent: MaskedInput,
                          inputProps: {
                            inputMode: 'numeric',
                            mask: Masks.DATE,
                          },
                        }}
                        inputRef={textInput}
                        error={Boolean(
                          formState.errors.expiration_date,
                        )}
                        helperText={
                          formState.errors.expiration_date &&
                          formState.errors.expiration_date
                            .message
                        }
                      />
                    )}
                  />
                )}
              </Box>
              <Box width="100%">
                <Controller
                  name="mothers_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      label="Nome da mãe"
                      InputProps={{
                        value: getValues('mothers_name'),
                        inputProps: { maxLength: 200 },
                      }}
                      error={Boolean(
                        formState.errors.mothers_name,
                      )}
                      helperText={
                        formState.errors.mothers_name &&
                        formState.errors.mothers_name.message
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              gap={2}
              marginY={2}
              width="100%"
              display="flex"
              flexDirection="column"
            >
              {documents.file_front && !inputFileFront ? (
                <Box>
                  <Typography component="span" variant="body1">
                    Frente: Foto e assinatura
                  </Typography>
                  <Stack
                    gap={2}
                    spacing={1}
                    marginTop={2}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '56px',
                        borderRadius: '3px',
                        backgroundColor:
                          themes.authentication.palette
                            .grey[400],
                      }}
                    >
                      <MuiButtonFile
                        fileName={documents.file_front}
                        title="Ver arquivo"
                        userId={user_id}
                      />
                    </Box>
                    <ButtonFile
                      color="secondary"
                      variant="contained"
                      onClick={handleInputFileFront}
                    >
                      Atualizar
                    </ButtonFile>
                  </Stack>
                </Box>
              ) : (
                <CustomInputFile
                  id="file_front"
                  nameFile={nameFileFront}
                  label="Frente: Foto e assinatura"
                  error={Boolean(formState.errors.file_front)}
                  helperText={
                    formState.errors.file_front?.message
                  }
                  onChange={handleFileFront || ''}
                />
              )}

              {documents.file_back && !inputFileBack ? (
                <Box>
                  <Typography component="span" variant="body1">
                    Verso: Dados
                  </Typography>
                  <Stack
                    gap={2}
                    spacing={1}
                    marginTop={2}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '56px',
                        borderRadius: '3px',
                        backgroundColor:
                          themes.authentication.palette
                            .grey[400],
                      }}
                    >
                      <MuiButtonFile
                        fileName={documents.file_back}
                        title="Ver arquivo"
                        userId={user_id}
                      />
                    </Box>
                    <ButtonFile
                      color="secondary"
                      variant="contained"
                      onClick={handleInputFileBack}
                    >
                      Atualizar
                    </ButtonFile>
                  </Stack>
                </Box>
              ) : (
                <CustomInputFile
                  id="file_back"
                  label="Verso: Dados"
                  nameFile={nameFileBack}
                  error={Boolean(formState.errors.file_back)}
                  helperText={
                    formState.errors.file_back?.message
                  }
                  onChange={handleFileBack || ''}
                />
              )}
            </Box>
            <Box
              gap={2}
              width="100%"
              paddingTop={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <BoxBtnsReviewStep
                status={status}
                user_id={user_id}
                setStatus={setStatus}
                step={OnboardingStep.DOCUMENT}
              />
              <Box
                gap={{
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 8,
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
                display="flex"
                alignItems="center"
                className="stepSend"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
              >
                <Typography
                  height={56}
                  component="p"
                  display="flex"
                  variant="body1"
                  alignItems="center"
                >
                  <Button
                    component="a"
                    variant="text"
                    disabled={
                      !btnDisabled == !formState.isDirty
                        ? true
                        : false
                    }
                    onClick={() => handleReset()}
                    sx={{
                      border: 'none !important',
                      textDecoration: 'underline !important',
                      backgroundColor: 'transparent !important',
                    }}
                  >
                    Cancelar
                  </Button>
                </Typography>
                <ButtonSave
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={
                    !btnDisabled == !formState.isDirty
                      ? true
                      : false
                  }
                >
                  Salvar
                </ButtonSave>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Document;
