import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { usePhone, updateUserPhone } from '@2ndmarket/services';
import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
} from '@2ndmarket/components';

import {
  IPhoneInput,
  StepOnboardingBacicProps,
} from '@2ndmarket/types';

import {
  Masks,
  ReviewStep,
  OnboardingStep,
  HttpError,
  HttpStatus,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { schema, defaultValues } from './formPhone';
import { styled } from '@mui/material/styles';

import BoxBtnsReviewStep from '../../../../components/BoxBtnsReviewStep';

const Phone: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  const { data: phone, mutate: mutatePhone } = usePhone(user_id);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkStatus, setCheckStatus] = useState(status);

  const is_processing = phone.status == ReviewStep.PROCESSING;

  const {
    reset,
    control,
    setError,
    getValues,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [accordion, setAccordion] = useState<string | false>(
    'phone',
  );

  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  const handleReset = () => {
    reset({
      number: phone.ddd + phone.number ?? '',
    });
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IPhoneInput) => {
    setIsLoading(true);
    const cleanPhone = data.number.replace(/[\(\)\-\s]/g, '');
    const ddd = cleanPhone.substring(0, 2);
    const number = cleanPhone.substring(2);

    const params = {
      ddi: '55',
      ddd: ddd,
      number: number,
      status: ReviewStep.APPROVED,
    };

    updateUserPhone(user_id, params)
      .then((data: IPhoneInput) => {
        mutatePhone();
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (step === OnboardingStep.PHONE) {
      setCheckStatus(status);
    }

    reset({
      number:
        (getValues('number') || phone.ddd + phone.number) ?? '',
    });
  }, [phone, reset, step, status, getValues]);

  const ButtonSave = styled(Button)(() => ({
    maxWidth: '172px',
    height: '48px',
    border: 'none',
    boxShadow: 'none',
    padding: '16px 32px',
    '&:hover': {
      border: 'none',
    },
  }));

  return (
    <>
      <Accordion
        expanded={accordion == 'phone'}
        onChange={handleAccordion('phone')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Telefone
          </Typography>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  margin="dense"
                  label="Digite o celular"
                  InputProps={{
                    inputComponent: MaskedInput,
                    value: getValues('number'),
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.PHONE,
                    },
                  }}
                  error={Boolean(formState.errors.number)}
                  helperText={
                    formState.errors.number &&
                    formState.errors.number.message
                  }
                />
              )}
            />
            <Box
              gap={2}
              width="100%"
              paddingTop={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <BoxBtnsReviewStep
                status={status}
                user_id={user_id}
                setStatus={setStatus}
                step={OnboardingStep.PHONE}
              />
              <Box
                gap={{
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 8,
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
                display="flex"
                alignItems="center"
                className="stepSend"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
              >
                <Typography
                  height={56}
                  component="p"
                  display="flex"
                  variant="body1"
                  alignItems="center"
                >
                  <Button
                    component="a"
                    variant="text"
                    disabled={
                      !formState.isDirty || is_processing
                    }
                    onClick={() => handleReset()}
                    sx={{
                      border: 'none !important',
                      textDecoration: 'underline !important',
                      backgroundColor: 'transparent !important',
                    }}
                  >
                    Cancelar
                  </Button>
                </Typography>
                <ButtonSave
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isDirty || is_processing}
                >
                  Salvar
                </ButtonSave>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Phone;
