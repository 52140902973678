import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState, MouseEvent } from 'react';

import { Icon, themes } from '@2ndmarket/components';
import { ProductUrls, Targets } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const Menu: React.FC = () => {
  const router = useRouter();

  const [openUser, setOpenUser] = useState(false);
  const [openTeam, setOpenTeam] = useState(false);
  const [openConf, setOpenConf] = useState(false);

  const [selected, setSelected] = useState(0);

  const handleConf = () => {
    setOpenConf(!openConf);
  };

  const handleTeam = () => {
    setOpenTeam(!openTeam);
  };

  const handleUser = () => {
    setOpenUser(!openUser);
  };

  const handleListClick = (
    event: MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelected(index);

    switch (index) {
      case 0:
        setOpenUser(false);
        setOpenTeam(false);
        setOpenConf(false);
        break;
      case 1:
        setOpenUser(!openUser);
        setOpenTeam(false);
        setOpenConf(false);
        break;
      case 2:
        setOpenUser(false);
        setOpenTeam(!openTeam);
        setOpenConf(false);
        break;
      case 3:
        setOpenUser(false);
        setOpenTeam(false);
        setOpenConf(!openConf);
        break;
    }
  };

  const linkMenuBank = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_BANK_LIST:
      case Targets.BACKOFFICE_CONF_BANK_CREATE:
      case Targets.BACKOFFICE_CONF_BANKID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  const linkMenuCity = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_CITY_LIST:
      case Targets.BACKOFFICE_CONF_CITY_CREATE:
      case Targets.BACKOFFICE_CONF_CITYID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  const linkMenuState = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_STATE_LIST:
      case Targets.BACKOFFICE_CONF_STATE_CREATE:
      case Targets.BACKOFFICE_CONF_STATEID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  const linkMenuCountry = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_COUNTRY_LIST:
      case Targets.BACKOFFICE_CONF_COUNTRY_CREATE:
      case Targets.BACKOFFICE_CONF_COUNTRYID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  const linkPermission = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_PERMISSION_LIST:
      case Targets.BACKOFFICE_CONF_PERMISSION_CREATE:
      case Targets.BACKOFFICE_CONF_PERMISSIONID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  const linkFunctions = () => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_FUNCTION_LIST:
      case Targets.BACKOFFICE_CONF_FUNCTION_CREATE:
      case Targets.BACKOFFICE_CONF_FUNCTIONID_EDIT:
        return themes.authentication.palette.primary.main;
    }
  };

  useEffect(() => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_DASHBOARD:
        setSelected(0);
        break;
      case Targets.BACKOFFICE_USERS_LIST:
      case Targets.BACKOFFICE_USERS_CREATE:
      case Targets.BACKOFFICE_ONBOARDING_USER_ID:
      case Targets.BACKOFFICE_USERS_PROFILE_USER_ID:
      case Targets.BACKOFFICE_USERS_ADDRESS_USER_ID:
      case Targets.BACKOFFICE_USERS_DOCUMENT_USER_ID:
      case Targets.BACKOFFICE_USERS_PHONE_USER_ID:
      case Targets.BACKOFFICE_USERS_PASSWORD_USER_ID:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_USER_ID:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_RECEIPT_ID:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT:
      case Targets.BACKOFFICE_USERS_WALLET_FIAT_TICKET:
      case Targets.BACKOFFICE_USERS_CRIPTO_BTC_USER_ID:
      case Targets.BACKOFFICE_USERS_CRIPTO_BKAI_USER_ID:
      case Targets.BACKOFFICE_USERS_WALLET_CRIPTO_RECEIPT_ID:
      case Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BTC_TRANSFER:
      case Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_TRANSFER:
      case Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BTC_DEPOSIT:
      case Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_DEPOSIT:
        setSelected(1);
        break;
      case Targets.BACKOFFICE_TEAM_LIST:
      case Targets.BACKOFFICE_TEAM_CREATE:
      case Targets.BACKOFFICE_TEAMID_EDIT:
        setSelected(2);
        break;
      case Targets.BACKOFFICE_CONF_BANK_LIST:
      case Targets.BACKOFFICE_CONF_BANK_CREATE:
      case Targets.BACKOFFICE_CONF_BANKID_EDIT:
      case Targets.BACKOFFICE_CONF_CITY_LIST:
      case Targets.BACKOFFICE_CONF_CITY_CREATE:
      case Targets.BACKOFFICE_CONF_CITYID_EDIT:
      case Targets.BACKOFFICE_CONF_STATE_LIST:
      case Targets.BACKOFFICE_CONF_STATE_CREATE:
      case Targets.BACKOFFICE_CONF_STATEID_EDIT:
      case Targets.BACKOFFICE_CONF_COUNTRY_LIST:
      case Targets.BACKOFFICE_CONF_COUNTRY_CREATE:
      case Targets.BACKOFFICE_CONF_COUNTRYID_EDIT:
      case Targets.BACKOFFICE_CONF_PERMISSION_LIST:
      case Targets.BACKOFFICE_CONF_PERMISSION_CREATE:
      case Targets.BACKOFFICE_CONF_PERMISSIONID_EDIT:
      case Targets.BACKOFFICE_CONF_FUNCTION_LIST:
      case Targets.BACKOFFICE_CONF_FUNCTION_CREATE:
      case Targets.BACKOFFICE_CONF_FUNCTIONID_EDIT:
        setSelected(3);
        break;
    }
  }, [router]);

  const ListItemButtonDashboard = styled(ListItemButton)(
    ({ theme }) => ({
      gap: '16px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '6px',
      backgroundColor:
        router.pathname == Targets.BACKOFFICE_DASHBOARD &&
        selected == 0
          ? theme.palette.secondary.main
          : '',
      color:
        router.pathname == Targets.BACKOFFICE_DASHBOARD &&
        selected == 0
          ? theme.palette.primary.main
          : theme.palette.grey[600],
    }),
  );

  const ListItemButtonUser = styled(ListItemButton)(
    ({ theme }) => ({
      gap: '16px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '6px',
      backgroundColor:
        router.pathname ==
          (Targets.BACKOFFICE_USERS_LIST ||
            Targets.BACKOFFICE_USERS_CREATE ||
            Targets.BACKOFFICE_ONBOARDING_USER_ID ||
            Targets.BACKOFFICE_USERS_PROFILE_USER_ID ||
            Targets.BACKOFFICE_USERS_ADDRESS_USER_ID ||
            Targets.BACKOFFICE_USERS_DOCUMENT_USER_ID ||
            Targets.BACKOFFICE_USERS_PHONE_USER_ID ||
            Targets.BACKOFFICE_USERS_PASSWORD_USER_ID ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_USER_ID ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_RECEIPT_ID ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT ||
            Targets.BACKOFFICE_USERS_WALLET_FIAT_TICKET ||
            Targets.BACKOFFICE_USERS_CRIPTO_BTC_USER_ID ||
            Targets.BACKOFFICE_USERS_CRIPTO_BKAI_USER_ID ||
            Targets.BACKOFFICE_USERS_WALLET_CRIPTO_RECEIPT_ID) &&
        selected == 1
          ? theme.palette.secondary.main
          : '',
      color:
        selected == 1
          ? theme.palette.primary.main
          : theme.palette.grey[600],
    }),
  );

  const ListItemButtonTeam = styled(ListItemButton)(
    ({ theme }) => ({
      gap: '16px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '6px',
      backgroundColor:
        router.pathname ==
          (Targets.BACKOFFICE_TEAM_LIST ||
            Targets.BACKOFFICE_TEAM_CREATE ||
            Targets.BACKOFFICE_TEAMID_EDIT) && selected == 2
          ? theme.palette.secondary.main
          : '',
      color:
        selected == 2
          ? theme.palette.primary.main
          : theme.palette.grey[600],
    }),
  );

  const ListItemButtonConf = styled(ListItemButton)(
    ({ theme }) => ({
      gap: '16px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '6px',
      backgroundColor:
        router.pathname ==
          (Targets.BACKOFFICE_CONF_BANK_LIST ||
            Targets.BACKOFFICE_CONF_BANK_CREATE ||
            Targets.BACKOFFICE_CONF_BANKID_EDIT ||
            Targets.BACKOFFICE_CONF_CITY_LIST ||
            Targets.BACKOFFICE_CONF_CITY_CREATE ||
            Targets.BACKOFFICE_CONF_CITYID_EDIT ||
            Targets.BACKOFFICE_CONF_STATE_LIST ||
            Targets.BACKOFFICE_CONF_STATE_CREATE ||
            Targets.BACKOFFICE_CONF_STATEID_EDIT ||
            Targets.BACKOFFICE_CONF_PERMISSION_LIST ||
            Targets.BACKOFFICE_CONF_PERMISSION_CREATE ||
            Targets.BACKOFFICE_CONF_PERMISSIONID_EDIT ||
            Targets.BACKOFFICE_CONF_FUNCTION_LIST ||
            Targets.BACKOFFICE_CONF_FUNCTION_CREATE ||
            Targets.BACKOFFICE_CONF_FUNCTIONID_EDIT) &&
        selected == 3
          ? theme.palette.secondary.main
          : '',
      color:
        selected == 3
          ? theme.palette.primary.main
          : theme.palette.grey[600],
    }),
  );

  const ListMenu = styled(List)<{ component: 'div' }>(
    ({ theme }) => ({
      marginLeft: 3,
      marginBottom: 1,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
    }),
  );

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <List
        component="nav"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <NextLink href={Targets.BACKOFFICE_DASHBOARD} passHref>
          <ListItemButtonDashboard
            selected={selected == 0}
            onClick={(event: any) => handleListClick(event, 0)}
          >
            <Icon
              name="home"
              size={20}
              color={
                router.pathname ==
                  Targets.BACKOFFICE_DASHBOARD && selected == 0
                  ? themes.authentication.palette.primary.main
                  : themes.authentication.palette.grey[300]
              }
            />
            <ListItemText primary="Dashboard" />
          </ListItemButtonDashboard>
        </NextLink>
        <Divider />

        <ListItemButtonUser
          selected={selected == 1}
          onClick={(event: any) => {
            handleListClick(event, 1);
            handleUser();
          }}
        >
          <Icon
            size={20}
            name="user"
            color={
              selected == 1
                ? themes.authentication.palette.primary.main
                : themes.authentication.palette.grey[300]
            }
          />
          <ListItemText primary="Usuários" />
          {openUser ? (
            <ExpandLess
              sx={{
                color: themes.authentication.palette.grey[500],
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          )}
        </ListItemButtonUser>
        <Collapse
          in={
            openUser
              ? true
              : false ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_ONBOARDING_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_PROFILE_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_ADDRESS_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_DOCUMENT_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_PHONE_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_PASSWORD_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_RECEIPT_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_FIAT_TICKET ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_CRIPTO_BTC_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_CRIPTO_BKAI_USER_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_CRIPTO_RECEIPT_ID ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BTC_TRANSFER ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_TRANSFER ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BTC_DEPOSIT ||
                router.pathname ==
                  Targets.BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_DEPOSIT
          }
          timeout="auto"
          unmountOnExit
        >
          <ListMenu component="div" disablePadding>
            <NextLink
              href={Targets.BACKOFFICE_USERS_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{
                  paddingLeft: 3,
                  color:
                    router.pathname ==
                    Targets.BACKOFFICE_USERS_LIST
                      ? themes.authentication.palette.primary
                          .main
                      : themes.authentication.palette.grey[600],
                }}
              >
                <ListItemText primary="Cadastrados" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_USERS_CREATE}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{
                  paddingLeft: 3,
                  color:
                    router.pathname ==
                    Targets.BACKOFFICE_USERS_CREATE
                      ? themes.authentication.palette.primary
                          .main
                      : themes.authentication.palette.grey[600],
                }}
              >
                <ListItemText primary="Adicionar" />
              </ListItemButton>
            </NextLink>
          </ListMenu>
        </Collapse>
        <Divider />

        <ListItemButtonTeam
          selected={selected == 2}
          onClick={(event: any) => {
            handleListClick(event, 2);
            handleTeam();
          }}
          sx={{ gap: 2, marginY: 1 }}
        >
          <Icon
            size={20}
            name="work"
            color={
              selected == 2
                ? themes.authentication.palette.primary.main
                : themes.authentication.palette.grey[300]
            }
          />
          <ListItemText primary="Equipe" />
          {openUser ? (
            <ExpandLess
              sx={{
                color: themes.authentication.palette.grey[500],
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          )}
        </ListItemButtonTeam>
        <Collapse
          in={
            openTeam
              ? true
              : false ||
                router.pathname ==
                  Targets.BACKOFFICE_TEAM_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_TEAM_CREATE ||
                router.pathname == Targets.BACKOFFICE_TEAMID_EDIT
          }
          timeout="auto"
          unmountOnExit
        >
          <ListMenu component="div" disablePadding>
            <NextLink
              href={Targets.BACKOFFICE_TEAM_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{
                  paddingLeft: 3,
                  color:
                    router.pathname ==
                    (Targets.BACKOFFICE_TEAM_LIST ||
                      Targets.BACKOFFICE_TEAMID_EDIT)
                      ? themes.authentication.palette.primary
                          .main
                      : themes.authentication.palette.grey[600],
                }}
              >
                <ListItemText primary="Cadastrados" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_TEAM_CREATE}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{
                  paddingLeft: 3,
                  color:
                    router.pathname ==
                    Targets.BACKOFFICE_TEAM_CREATE
                      ? themes.authentication.palette.primary
                          .main
                      : themes.authentication.palette.grey[600],
                }}
              >
                <ListItemText primary="Adicionar" />
              </ListItemButton>
            </NextLink>
          </ListMenu>
        </Collapse>
        <Divider />

        <ListItemButtonConf
          selected={selected == 3}
          onClick={(event: any) => {
            handleListClick(event, 3);
            handleConf();
          }}
          sx={{ gap: 2, marginY: 1 }}
        >
          <Icon
            size={20}
            name="settings"
            color={
              selected == 3
                ? themes.authentication.palette.primary.main
                : themes.authentication.palette.grey[300]
            }
          />
          <ListItemText primary="Configurações" />
          {openUser ? (
            <ExpandLess
              sx={{
                color: themes.authentication.palette.grey[500],
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          )}
        </ListItemButtonConf>
        <Collapse
          in={
            openConf
              ? true
              : false ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_BANK_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_BANK_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_BANKID_EDIT ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_CITY_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_CITY_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_CITYID_EDIT ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_STATE_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_STATE_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_STATEID_EDIT ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_COUNTRY_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_COUNTRY_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_COUNTRYID_EDIT ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_PERMISSION_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_PERMISSION_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_PERMISSIONID_EDIT ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_FUNCTION_LIST ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_FUNCTION_CREATE ||
                router.pathname ==
                  Targets.BACKOFFICE_CONF_FUNCTIONID_EDIT
          }
          timeout="auto"
          unmountOnExit
        >
          <ListMenu component="div" disablePadding>
            <NextLink
              href={Targets.BACKOFFICE_CONF_BANK_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkMenuBank() }}
              >
                <ListItemText primary="Bancos" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_CONF_CITY_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkMenuCity() }}
              >
                <ListItemText primary="Cidades" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_CONF_STATE_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkMenuState() }}
              >
                <ListItemText primary="Estados" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_CONF_FUNCTION_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkFunctions() }}
              >
                <ListItemText primary="Funções" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_CONF_COUNTRY_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkMenuCountry() }}
              >
                <ListItemText primary="Países" />
              </ListItemButton>
            </NextLink>
            <NextLink
              href={Targets.BACKOFFICE_CONF_PERMISSION_LIST}
              passHref
            >
              <ListItemButton
                component="a"
                sx={{ paddingLeft: 3, color: linkPermission() }}
              >
                <ListItemText primary="Permissões" />
              </ListItemButton>
            </NextLink>
          </ListMenu>
        </Collapse>
      </List>
      <List
        component="nav"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ListItemButton
          component="a"
          target="_blank"
          sx={{ gap: 2, marginBottom: 2 }}
          href={ProductUrls.SUPPORT}
        >
          <Icon
            size={20}
            name="help"
            color={themes.authentication.palette.grey[300]}
          />
          <ListItemText
            primary="Ajuda"
            color={themes.authentication.palette.grey[600]}
          />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default Menu;
