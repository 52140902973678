import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { formatBRL } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';
import { useBank, useBill } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

interface BillProps {
  user_id: number;
  dataIntent: any;
  valueAmount?: number;
  onResponse?: (response: any) => void;
}

const BillSummary: React.FC<BillProps> = ({
  user_id,
  dataIntent,
  onResponse,
  valueAmount,
}) => {
  const { data: bill } = useBill(
    dataIntent.register_id,
    user_id,
  );

  const { data: bank } = useBank(bill.bank_id);

  const [updatedValueAmount, setUpdatedValueAmount] =
    useState<number>();

  const billDueDate = dayjs(bill.due_date)
    .locale(ptBR)
    .format('DD/MM/YYYY');

  const dateNow = dayjs(new Date())
    .locale(ptBR)
    .format('DD/MM/YYYY');

  useEffect(() => {
    if (!bill) {
      return;
    }

    if (valueAmount === 0) {
      setUpdatedValueAmount(bill.nominal_amount);
    }

    if (valueAmount !== 0) {
      setUpdatedValueAmount(valueAmount);
    }
  }, [bill, valueAmount]);

  const responseDataTicket = [
    {
      title: 'Data do vencimento',
      value: (
        <Typography
          variant="body1"
          component="span"
          color={themes.authentication.palette.text.primary}
        >
          {bill.due_date === null
            ? ''
            : billDueDate > dateNow
            ? dayjs(bill.due_date)
                .locale(ptBR)
                .format('DD/MM/YYYY') +
              (
                <Icon
                  size={16}
                  name="info"
                  color={
                    themes.authentication.palette.warning.main
                  }
                />
              )
            : dayjs(bill.due_date)
                .locale(ptBR)
                .format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Código do boleto',
      value: bill.barcode,
    },
    {
      title: 'Valor do boleto',
      value: `${formatBRL(bill.nominal_amount)}`,
    },
    {
      title: 'Pagando',
      value: (
        <Typography
          color={
            Number(updatedValueAmount) < bill.nominal_amount
              ? themes.authentication.palette.warning.main
              : themes.authentication.palette.text.primary
          }
        >
          {valueAmount == 0
            ? formatBRL(updatedValueAmount as any)
            : formatBRL(valueAmount as any)}
        </Typography>
      ),
    },
  ];

  const responseRecipient = [
    {
      title: 'Nome',
      value: bill.beneficiary_name,
    },
    {
      title: 'CPF/CNPJ',
      value: bill.beneficiary_number,
    },
    {
      title: 'Instituição',
      value: bank.name,
    },
  ];

  useEffect(() => {
    if (!bill) {
      return;
    }

    onResponse?.(bill);
  }, [bill, onResponse]);

  const Line = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '13px 0',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  }));

  return (
    <>
      <Box
        gap={2}
        marginTop={4}
        display="flex"
        alignItems="center"
      >
        <Icon
          size={20}
          name="boleto"
          color={themes.authentication.palette.primary.main}
        />
        <Typography
          variant="body1"
          component="span"
          color={themes.authentication.palette.text.primary}
        >
          Dados do Boleto
        </Typography>
      </Box>
      <Box
        width="100%"
        marginTop={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {responseDataTicket.map((item, key) => (
          <Line key={key}>
            <Typography
              component="p"
              variant="body1"
              color={themes.authentication.palette.text.disabled}
              sx={{ width: '50%' }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              sx={{ width: '50%', textAlign: 'right' }}
            >
              {item.value}
            </Typography>
          </Line>
        ))}
      </Box>
      <Box
        gap={2}
        marginTop={4}
        display="flex"
        alignItems="center"
      >
        <Icon
          size={20}
          name="send"
          color={themes.authentication.palette.primary.main}
        />
        <Typography
          variant="body1"
          component="span"
          color={themes.authentication.palette.text.primary}
        >
          Destinatário
        </Typography>
      </Box>
      <Box
        width="100%"
        marginTop={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {responseRecipient.map((item, key) => (
          <Line key={key}>
            <Typography
              component="p"
              variant="body1"
              color={themes.authentication.palette.text.disabled}
              sx={{ width: '50%' }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              sx={{ width: '50%', textAlign: 'right' }}
            >
              {item.value}
            </Typography>
          </Line>
        ))}
      </Box>
      {Number(updatedValueAmount) < bill.nominal_amount && (
        <Alert
          severity="warning"
          variant="outlined"
          icon={<Icon name="clock" size={18} />}
          sx={{
            border: 1,
            marginTop: '20px',
            borderRadius: '3px',
            marginBottom: '10px',
            color: 'text.primary',
            borderColor: 'warning.main',
            backgroundColor: 'warning.contrastText',
          }}
        >
          Você está efetuando o pagamento desse boleto com um
          valor menor que o original.
        </Alert>
      )}
    </>
  );
};

export default BillSummary;
