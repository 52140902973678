import { yupPt } from '@2ndmarket/helpers';

function isValidDate(dateString: string) {
  const [day, month, year] = dateString.split('/').map(Number);

  if (
    isNaN(day) ||
    isNaN(month) ||
    isNaN(year) ||
    day < 1 ||
    day > 31 ||
    month < 1 ||
    month > 12
  ) {
    return false;
  }

  const date = new Date(year, month - 1, day);

  return (
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  );
}

export const schema = yupPt.object().shape({
  type: yupPt
    .string()
    .test('type', '', (value, { createError }) => {
      if (value === undefined || value === null) {
        return createError({
          message: 'Selecione um tipo de documento',
        });
      }
      return true;
    })
    .nullable(),
  mothers_name: yupPt
    .string()
    .test('mothers_name', '', (value, { createError }) => {
      if (value === undefined || value === null) {
        return createError({
          message: 'Informe o nome da Mãe',
        });
      }
      return true;
    })
    .nullable(),
  expiration_date: yupPt.string().when('type', {
    is: 'cnh',
    then: yupPt
      .string()
      .test(
        'expiration_date',
        'Data de expiração inválida',
        value => {
          if (!value) {
            return false;
          }
          if (!isValidDate(value as any)) {
            return false;
          }
          const [day, month, year] = value
            .split('/')
            .map(Number);
          const currentDate = new Date();
          const inputDate = new Date(year, month - 1, day);

          return inputDate > currentDate;
        },
      ),
  }),
  file_front: yupPt
    .mixed()
    .required('Selecione a imagem de frente'),
  file_back: yupPt
    .mixed()
    .required('Selecione a imagem de verso'),
});

export const defaultValues = {
  contact_name: '',
  type: '',
  mothers_name: '',
  expiration_date: '',
  file_front: '',
  file_back: '',
};
