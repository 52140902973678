import NextLink from 'next/link';

import { Targets } from '@2ndmarket/helpers';
import { useResponsive } from '@2ndmarket/hooks';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import DrawerMenu from '../DrawerMenu';
import DrawerProfile from '../DrawerProfile';

const Header: React.FC = () => {
  const mediumDow = useResponsive('down', 'md', 'xs');

  return (
    <Box
      padding={3}
      height={90}
      width="100%"
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="space-between"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      <Box
        gap={5}
        margin={0}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <NextLink href={Targets.BACKOFFICE_DASHBOARD} passHref>
          <Link sx={{ textDecoration: 'none' }}>
            <Icon
              size={28}
              name="bkai-coin"
              color={themes.authentication.palette.primary.main}
            />
          </Link>
        </NextLink>
        {mediumDow && <DrawerMenu />}
      </Box>
      <DrawerProfile />
    </Box>
  );
};

export default Header;
