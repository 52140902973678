import { yupPt } from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  amount: yupPt.string().required(),
});

export const defaultValues = {
  amount: '',
  schedule_date: '',
};
