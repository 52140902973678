import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { themes, Icon } from '@2ndmarket/components';
import { Targets, months } from '@2ndmarket/helpers';

import {
  useMe,
  useCryptoWallet,
  useCryptoStatement,
} from '@2ndmarket/services';

import {
  IReceipts,
  IReceiptsData,
  WalletCryptoProps,
} from '@2ndmarket/types';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';

import Paginator from '../../../components/Paginator';
import StatementFilter from '../../../components/StatementFilter';

const ReceiptWalletCripto: React.FC<WalletCryptoProps> = ({
  user_id,
  coin,
}) => {
  const router = useRouter();
  const date = new Date();

  const currentYear = date.getFullYear().toString();
  const monthDate =
    date.getMonth() < 9
      ? '0' + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString();

  const initialFilters = {
    year: currentYear,
    month: monthDate,
    ordering: 'desc',
    page: 1,
    page_size: 5,
  };

  const [filters, setFilters] = useState(initialFilters);

  const { data: user } = useMe(user_id);
  const { data: wallet, loading: loading_wallet } =
    useCryptoWallet(coin, user.id);

  const wallet_id = wallet[0]?.id;

  const { data: statement, loading } = useCryptoStatement(
    coin,
    wallet_id,
    filters,
    user.id,
  );

  const [filterOpen, setFilterOpen] = useState(false);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(monthDate);

  const onChange = (key: string, value: string | number) => {
    switch (key) {
      case 'page':
        initialFilters.page = value as number;
        router.query.page = initialFilters.page.toString();
        break;
      case 'page_size':
        initialFilters.page_size = value as number;
        router.query.page_size =
          initialFilters.page_size.toString();
        break;
      case 'ordering':
        initialFilters.ordering = value as string;
        router.query.ordering = initialFilters.ordering;
        break;
      case 'year':
        initialFilters.year = value as string;
        router.query.year = initialFilters.year;
        break;
      case 'month':
        initialFilters.month = value as string;
        router.query.month = initialFilters.month;
        break;
    }

    const params = new URLSearchParams({
      ordering:
        (router.query.ordering as string) ??
        initialFilters.ordering,
      page:
        (router.query.page as string) ??
        initialFilters.page.toString(),
      page_size:
        (router.query.page_size as string) ??
        initialFilters.page_size.toString(),
      year:
        (router.query.year as string) ??
        initialFilters.year.toString(),
      month:
        (router.query.month as string) ??
        initialFilters.month.toString(),
    });

    router.push({
      pathname: `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${coin}/${user.id}`,
      query: params.toString(),
    });

    setFilters({ ...filters, [key]: value });
  };

  const handleClick = (
    receipt_id: string,
    operation: string,
  ) => {
    const rota = `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${coin}/${user.id}/comprovante/${operation}/${receipt_id}`;
    router.push(rota);
  };

  useEffect(() => {
    if (!statement || !wallet) {
      return;
    }

    if (!router.query) {
      router.push(
        `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${coin}/${user.id}`,
      );
    }
  }, [router, statement, wallet, user, coin]);

  return (
    <Box>
      <Box
        marginTop={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="body1" component="span">
          Extrato
        </Typography>
      </Box>
      <Box
        marginTop={2}
        borderRadius="3px"
        sx={{ backgroundColor: 'white' }}
      >
        <Box display="flex" gap={2} padding="11px 20px">
          <Button
            variant="text"
            onClick={() => setFilterOpen(true)}
            sx={{
              gap: 1,
              padding: 0,
              display: 'flex',
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
                backgroundColor: 'common.white',
              },
            }}
          >
            <Icon
              size={20}
              name="configure"
              color={themes.authentication.palette.grey[300]}
            />
            Filtro
          </Button>
          <List
            sx={{
              gap: '6px',
              '.MuiListItem-root': {
                paddingY: '4px',
                paddingX: '8px',
                fontSize: '14px',
                borderRadius: '3px',
                color: 'primary.main',
                backgroundColor: 'grey.400',
              },
            }}
          >
            <ListItem>{selectedYear}</ListItem>
            <ListItem>
              {months.map(item => {
                if (
                  item.alternativeIndex == Number(selectedMonth)
                ) {
                  return item.value;
                }
              })}
            </ListItem>
          </List>
        </Box>
        <TableContainer
          sx={{
            gap: 2,
            display: 'flex',
            paddingBottom: '15px',
            flexDirection: 'column',
          }}
        >
          <Table
            aria-label="table"
            sx={{ paddingX: '15px', borderCollapse: 'inherit' }}
          >
            <TableBody>
              {loading_wallet || loading ? (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography
                      component="p"
                      variant="body2"
                      sx={{ textAlign: 'center' }}
                    >
                      Carregando resultados...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : statement.results.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '16px',
                      color: 'grey.300',
                      textAlign: 'center',
                    }}
                  >
                    Sem transações no período selecionado.
                  </TableCell>
                </TableRow>
              ) : (
                statement.results.map(
                  (row: IReceipts, index: number) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleClick(row.id, row.operation)
                        }
                      >
                        {row.data.map(
                          (
                            column: IReceiptsData,
                            index: number,
                          ) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{
                                  width:
                                    column.type === 'icon'
                                      ? '20px'
                                      : 'auto',
                                }}
                              >
                                {column.type === 'icon' ? (
                                  <Icon
                                    size={20}
                                    name={column.value}
                                    color={column.color}
                                  />
                                ) : (
                                  column.value
                                )}
                              </TableCell>
                            );
                          },
                        )}
                        <TableCell sx={{ width: '20px' }}>
                          <Icon
                            size={20}
                            name="list"
                            color={
                              themes.bankai.palette.grey[300]
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  },
                )
              )}
            </TableBody>
          </Table>
          {/* {!loading && (
            <Paginator
              showLimit
              data={statement}
              onChange={onChange}
            />
          )} */}
        </TableContainer>
      </Box>

      <StatementFilter
        open={filterOpen}
        setOpen={setFilterOpen}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        onChange={onChange}
      />
    </Box>
  );
};

export default ReceiptWalletCripto;
