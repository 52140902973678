import { toPng } from 'html-to-image';
import { useRouter } from 'next/router';

import { Product, TypesReceipt } from '@2ndmarket/helpers';
import { ReceiptIdProps } from '@2ndmarket/types';
import { useMe, useCryptoReceipt } from '@2ndmarket/services';
import { Icon, themes, MuiReceipt } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const ReceiptCripto: React.FC<ReceiptIdProps> = ({
  operation,
  receipt_id,
  crypto,
  user_id,
}) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);
  const { data: receipt, error } = useCryptoReceipt(
    operation as string,
    receipt_id as string,
    crypto as TypesReceipt,
    user.id,
  );

  const fileName = () => {
    const proof = operation ?? '';
    return `comprovante-${proof.toUpperCase()}-${receipt?.date}`;
  };

  const htmlToImageConvert = async () => {
    const dataUrl = await toPng(
      document.getElementById('receipt')!,
    );

    const link = document.createElement('a');
    link.download = fileName();
    link.href = dataUrl;
    link.click();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => router.back()}
          sx={{ padding: '5px 10px', height: '28px' }}
        >
          Voltar
        </Button>
        <IconButton
          onClick={htmlToImageConvert}
          sx={{
            marginRight: {
              lg: '100px',
              md: '0',
              sm: '0',
              xs: '0',
            },
          }}
        >
          <Icon
            size={20}
            name="export"
            color={themes.authentication.palette.grey[300]}
          />
        </IconButton>
      </Box>
      {receipt.data.length > 0 ? (
        <MuiReceipt infos={receipt} type={Product.BACKOFFICE} />
      ) : (
        <Box paddingX="100px" id="receipt">
          <Box
            gap={2}
            width="100%"
            display="flex"
            padding="26px 30px"
            flexDirection="column"
            sx={{
              borderRadius: '3px',
              backgroundColor: 'white',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography component="p" variant="body2">
                {error?.error}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ReceiptCripto;
