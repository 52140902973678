import { mask, unMask } from 'remask';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import dayjs from 'dayjs';

import {
  IBanksResult,
  TedProps,
  WalletFiatTedProps,
} from '@2ndmarket/types';

import {
  useMe,
  useStaffMe,
  sendFiatTed,
  getStaffMePin,
} from '@2ndmarket/services';

import {
  Masks,
  Status,
  Documents,
  HttpError,
  HttpStatus,
  AccountTypes,
  TypesReceipt,
  FiatOperation,
} from '@2ndmarket/helpers';

import {
  Icon,
  themes,
  BankDrawer,
  DateDrawer,
  MaskedInput,
  ModalDialog,
  NumericCustom,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';

import { schema, defaultValues } from './formTed';
import { TransferAwait, Loader } from '../../../../components';

const Ted: React.FC<TedProps> = ({
  user_id,
  operation,
  transferData,
  setStepStatus,
  setTransferData,
}) => {
  const router = useRouter();

  const { data: staff } = useStaffMe();
  const { data: user } = useMe(user_id);

  const defaultDate = 'Agora';
  const [masked, setMasked] = useState('');

  const [showPin, setShowPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalValidPin, setModalValidPin] = useState(false);

  const [stepTed, setStepTed] = useState(true);
  const [stepPin, setStepPin] = useState(false);

  const [transferPromise, setTransferPromise] = useState({
    id: 0,
    type: '',
    register: false,
  });

  const [bankOpen, setBankOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState<IBanksResult>(
    {
      code: '',
      name: '',
    },
  );

  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const bankWatcher = useWatch({ control, name: 'bank' });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const originalValue = unMask(event.target.value);
    const maskedValue = mask(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);

    setMasked(maskedValue);
  };

  const handleShowPin = () => {
    setShowPin(show => !show);
  };

  const handleMouseDownPin = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleReturnTed = () => {
    reset();
    setMasked('');
    setSelectedDate(defaultDate);
    setSelectedBank({ code: '', name: '' });

    setStepStatus(true);

    setStepTed(true);
    setStepPin(false);
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setModalValidPin(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const handleStepPin = async (data: WalletFiatTedProps) => {
    data.cpf_cnpj = masked.replace(/[^\d]/g, '');
    data.amount = data.amount.replace(/[^\d]/g, '');

    setTransferData(data);
    setStepTed(false);
    setStepPin(true);
    setStepStatus(false);
  };

  const onSubmit = async (data: WalletFiatTedProps) => {
    setIsLoading(true);
    const dataTransfer = transferData as WalletFiatTedProps;

    if (transferData.hasOwnProperty('pin')) {
      const pin = data.pin as string;

      await getStaffMePin(staff.id)
        .then(response => {
          if (response.status == Status.APPROVED) {
            setTimeout(async () => {
              await sendFiatTed(
                pin,
                data.bank_code,
                data.agency_number,
                dayjs(
                  dataTransfer.schedule_date,
                  'DD/MM/YYYY',
                ).format('YYYY-MM-DD'),
                '',
                dataTransfer.account_number,
                dataTransfer.account_type,
                Number(dataTransfer.amount),
                '',
                dataTransfer.recipient,
                Number(dataTransfer.cpf_cnpj.length) == 11
                  ? Documents.CPF
                  : Documents.CNPJ,
                dataTransfer.cpf_cnpj,
                user.id,
              )
                .then((responseTransaction: any) => {
                  const intent = responseTransaction
                    .split('?')[1]
                    .split('&')
                    .reduce(
                      (accumulator: any, item: any) => {
                        const [key, value] = item.split('=');
                        accumulator[key] = value;
                        return accumulator;
                      },
                      {
                        amount: data.amount,
                      },
                    );
                  setTransferPromise({
                    type: intent.type,
                    id: parseInt(intent.intent_id),
                    register: Boolean(intent.is_register),
                  });
                })
                .catch((error: HttpError) => {
                  handleErrors(error);
                });
            }, 5000);
          } else {
            setIsLoading(false);
            setModalValidPin(true);
            setErrorMessage('Pin inválido');
          }
        })
        .catch((error: HttpError) => {
          handleErrors(error);
        });
    }
  };

  useEffect(() => {
    setValue('bank_code', selectedBank.code);
    setValue('bank', selectedBank.name);
  }, [selectedBank, setValue]);

  useEffect(() => {
    setValue('schedule_date', selectedDate);
  }, [selectedDate, setValue]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box marginTop={1}>
      <Alert
        severity="warning"
        variant="outlined"
        icon={<Icon name="clock" size={18} />}
        sx={{
          border: 1,
          width: '261px',
          borderRadius: '3px',
          marginBottom: '10px',
          color: 'warning.main',
          borderColor: 'warning.main',
          backgroundColor: 'warning.contrastText',
        }}
      >
        Disponível das 09h15 às 15h45
      </Alert>
      {operation == TypesReceipt.TED && stepTed ? (
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handleStepPin)}
          sx={{
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiInputBase-root': {
              backgroundColor:
                themes.authentication.palette.grey[900],
            },
          }}
        >
          <Box
            gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
            display="flex"
            flexDirection={{
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
          >
            <Box width="100%">
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Valor"
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    error={Boolean(errors.amount)}
                    helperText={
                      errors.amount && errors.amount.message
                    }
                    InputProps={{
                      inputComponent: NumericCustom as any,
                      inputProps: {
                        inputMode: 'numeric',
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="cpf_cnpj"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    margin="dense"
                    value={masked}
                    label="CPF/CNPJ"
                    variant="outlined"
                    autoComplete="off"
                    onChange={onChange}
                    inputProps={{ inputMode: 'numeric' }}
                    error={Boolean(errors.cpf_cnpj)}
                    helperText={
                      errors.cpf_cnpj && errors.cpf_cnpj.message
                    }
                  />
                )}
              />
              <Controller
                name="account_type"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    {...field}
                    margin="dense"
                    variant="outlined"
                    label="Tipo de conta"
                    error={Boolean(errors.account_type)}
                    helperText={
                      errors.account_type &&
                      errors.account_type.message
                    }
                  >
                    {AccountTypes.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="account_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    label="Conta (com dígito)"
                    error={Boolean(errors.account_number)}
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        inputMode: 'numeric',
                        mask: Masks.BRANCH,
                        unmask: true,
                      },
                    }}
                    helperText={
                      errors.account_number
                        ? errors.account_number.message
                        : ''
                    }
                  />
                )}
              />
            </Box>
            <Box width="100%">
              <Controller
                name="recipient"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    label="Destinarário"
                    error={Boolean(errors.recipient)}
                    helperText={
                      errors.recipient &&
                      errors.recipient.message
                    }
                  />
                )}
              />
              <Box width="100%">
                <Controller
                  name="bank"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      label="Banco"
                      margin="dense"
                      variant="outlined"
                      onClick={() => setBankOpen(true)}
                      error={
                        selectedBank.name == ''
                          ? Boolean(errors.bank)
                          : false
                      }
                      helperText={
                        selectedBank.name == ''
                          ? errors.bank && errors.bank.message
                          : ''
                      }
                      InputProps={{
                        inputProps: { readOnly: true },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              size={12}
                              name="arrow"
                              rotate="-90deg"
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: bankWatcher ? true : false,
                      }}
                      sx={{ input: { cursor: 'pointer' } }}
                    />
                  )}
                />
                <Input
                  type="hidden"
                  value={selectedBank.code}
                  {...register('bank_code')}
                />
              </Box>
              <Controller
                name="agency_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    label="Agência (sem dígito)"
                    error={Boolean(errors.agency_number)}
                    helperText={
                      errors.agency_number &&
                      errors.agency_number.message
                    }
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        mask: Masks.AGENCY,
                        inputMode: 'numeric',
                      },
                    }}
                  />
                )}
              />
              <Box width="100%">
                <Controller
                  name="schedule_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      label="Quando"
                      margin="dense"
                      variant="outlined"
                      onClick={() => setDateOpen(true)}
                      error={Boolean(errors.schedule_date)}
                      helperText={
                        errors.schedule_date &&
                        errors.schedule_date.message
                      }
                      InputProps={{
                        inputProps: { readOnly: true },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              '& i': {
                                color: 'text.disabled',
                              },
                            }}
                          >
                            <Icon name="calendar" size={16} />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ input: { cursor: 'pointer' } }}
                    />
                  )}
                />
                <Input
                  type="hidden"
                  {...register('schedule_date')}
                />
              </Box>
            </Box>
          </Box>
          <Box
            paddingY={2}
            marginTop={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              borderTop: 1,
              borderBottom: 1,
              borderColor: 'grey.200',
            }}
          >
            <Typography variant="body1" component="p">
              Tarifa
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="primary.main"
            >
              Gratuita
            </Typography>
          </Box>
          <Box
            marginY={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ButtonReturn
              component="a"
              onClick={() => router.back()}
            >
              Voltar
            </ButtonReturn>
            <Button
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                paddingX: 8,
                width: 172,
              }}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiInputBase-root': {
              backgroundColor:
                themes.authentication.palette.grey[900],
            },
          }}
        >
          <Box display="flex">
            <TextField
              fullWidth
              label="Pin"
              margin="dense"
              autoComplete="off"
              type={showPin ? 'text' : 'password'}
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  inputMode: 'numeric',
                  mask: Masks.CODE,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="start"
                      onClick={handleShowPin}
                      onMouseDown={handleMouseDownPin}
                      aria-label="toggle password visibility"
                    >
                      {showPin ? (
                        <Icon
                          size={20}
                          name="view"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        />
                      ) : (
                        <Icon
                          size={20}
                          name="hide"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('pin', { required: true })}
              error={Boolean(errors.pin)}
              helperText={errors.pin && errors.pin.message}
            />
          </Box>
          <Box
            marginY={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ButtonReturn
              component="a"
              onClick={handleReturnTed}
            >
              Voltar
            </ButtonReturn>
            <Button
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                paddingX: 8,
                width: 172,
              }}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      )}

      <BankDrawer
        bankOpen={bankOpen}
        setBankOpen={setBankOpen}
        selectedBank={selectedBank}
        setSelectedBank={setSelectedBank}
      />

      <DateDrawer
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        defaultDateText={defaultDate}
      />

      {isLoading && (
        <ModalDialog
          maxWidth="sm"
          open={isLoading}
          onClose={() => setIsLoading(false)}
        >
          <Box
            gap={2}
            paddingX={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Box width="100px">
              <Loader loaderState={isLoading} />
            </Box>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: 600 }}
            >
              [{FiatOperation.TED}]
            </Typography>
            <Typography variant="body1" component="p">
              Estamos realizando sua transferência...
            </Typography>
            <Button
              disabled
              fullWidth
              variant="contained"
              sx={{ marginBottom: 2 }}
            >
              Ver comprovante
            </Button>
          </Box>
        </ModalDialog>
      )}

      {modalValidPin && (
        <ModalDialog
          error
          maxWidth="sm"
          open={modalValidPin}
          errorMessage={errorMessage}
          onClose={() => setModalValidPin(false)}
          errorTitle="Problema ao enviar os dados"
        />
      )}

      {transferPromise.type == FiatOperation.TED && (
        <TransferAwait
          user_id={user.id}
          data={transferPromise}
        />
      )}
    </Box>
  );
};

export default Ted;
