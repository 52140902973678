import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';

import {
  ICity,
  IState,
  IAddress,
  IAddressUpdate,
  StepOnboardingBacicProps,
} from '@2ndmarket/types';

import {
  getPostcodeInfo,
  updateAddress,
  getCityInfo,
  useAddress,
  useCities,
  useStates,
  useMe,
} from '@2ndmarket/services';

import {
  Icon,
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
  CityDrawer,
  StateDrawer,
} from '@2ndmarket/components';

import {
  OnboardingStep,
  ReviewStep,
  HttpStatus,
  HttpError,
  IPostCode,
  Masks,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { schema, defaultValues } from './formAddress';
import { styled } from '@mui/material/styles';

const Address: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  const router = useRouter();
  const { data: user } = useMe(user_id);
  const { data: address, mutate: mutateUserAddress } =
    useAddress(user_id);

  let is_processing = address.status == ReviewStep.PROCESSING;

  const [cityOpen, setCityOpen] = useState(false);
  const [stateOpen, setStateOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeState, setChangeState] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [checkStatus, setCheckStatus] = useState(status);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [selectedState, setSelectedState] = useState<IState>({
    id: 0,
    name: '',
  });

  const [selectedCity, setSelectedCity] = useState<IState>({
    id: 0,
    name: '',
  });

  const [accordion, setAccordion] = useState<string | false>(
    'address',
  );

  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  const {
    reset,
    control,
    register,
    setError,
    setValue,
    getValues,
    formState,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const stateWatcher = useWatch({ control, name: 'state_id' });
  const cityWatcher = useWatch({ control, name: 'city_id' });

  const handleReset = () => {
    reset({
      postcode: address.postcode ?? '',
      state_id: address.state_id ?? 0,
      state_name: address.state?.name ?? '',
      city_id: address.city_id ?? 0,
      city_name: address.city?.name ?? '',
      district: address.district ?? '',
      street: address.street ?? '',
      number: address.number ?? '',
      complement: address.complement ?? '',
    });
  };

  const getCepInfos = async (event: React.FormEvent) => {
    setIsLoading(true);
    clearErrors('postcode');

    if (getValues('number') != '') {
      setValue('number', '');
      setValue('complement', '');
    }

    const postcode = getValues('postcode');
    await getPostcodeInfo(postcode)
      .then((data: IPostCode) => {
        setValue('state_id', data.state_id);
        setValue('city_id', data.city_id);
        setValue('street', data.street);
        setValue('district', data.district);

        getCityInfo(data.city_id)
          .then((cityData: ICity) => {
            setValue('state_name', cityData.state.name);
            setValue('city_name', cityData.name);
            setSelectedState({
              id: data.state_id,
              name: cityData.state.name,
            });
            setSelectedCity({
              id: data.city_id,
              name: cityData.name,
            });
          })
          .catch(() => {
            setError('postcode', {
              type: 'custom',
              message: 'Problema ao buscar a cidade',
            });
          });
      })
      .catch(() => {
        setError('postcode', {
          type: 'custom',
          message: 'CEP não encontrado',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IAddressUpdate) => {
    setIsLoading(true);
    updateAddress(
      {
        postcode: data.postcode.replace('-', ''),
        state_id: Number(data.state_id),
        city_id: Number(data.city_id),
        district: data.district,
        street: data.street,
        number: data.number,
        complement: data.complement,
      },
      user_id,
    )
      .then((data: IAddress) => {
        mutateUserAddress(data);
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeStatus = (reviewStep: ReviewStep) => {
    status = reviewStep;
    setStatus(status);
    setBtnDisabled(!btnDisabled);
  };

  useEffect(() => {
    if (changeState) {
      reset({ city_id: 0, city_name: '' });
      setChangeState(false);
    }

    setValue('state_id', selectedState.id);
    setValue('state_name', selectedState.name);
  }, [selectedState, reset, setValue, changeState]);

  useEffect(() => {
    setValue('city_id', selectedCity.id);
    setValue('city_name', selectedCity.name);
  }, [selectedCity, setValue]);

  useEffect(() => {
    if (step == OnboardingStep.ADDRESS) {
      setCheckStatus(status);
    }

    reset({
      postcode:
        (getValues('postcode') || address.postcode) ?? '',
      state_id: (getValues('state_id') || address.state_id) ?? 0,
      state_name:
        (getValues('state_name') || address.state?.name) ?? '',
      city_id: (getValues('city_id') || address.city_id) ?? 0,
      city_name:
        (getValues('city_name') || address.city?.name) ?? '',
      district:
        (getValues('district') || address.district) ?? '',
      street: (getValues('street') || address.street) ?? '',
      number: (getValues('number') || address.number) ?? '',
      complement:
        (getValues('complement') || address.complement) ?? '',
    });
  }, [step, reset, status, address, getValues]);

  const ButtonSave = styled(Button)(() => ({
    maxWidth: '172px',
    height: '48px',
    border: 'none',
    boxShadow: 'none',
    padding: '16px 32px',
    '&:hover': {
      border: 'none',
    },
  }));

  const ButtonCheckbox = styled(Button)(({ theme }) => ({
    width: '140px',
    height: '48px',
    padding: '15px',
    border: 'none',
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      border: 'none',
    },
    '.MuiButton-startIcon': {
      width: 20,
      height: 20,
      display: 'flex',
      borderRadius: 3,
      border: '1px solid',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'white',
      borderColor: theme.palette.grey[200],
    },
  }));

  const BoxChecked = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    backgroundColor: themes.authentication.palette.primary.main,
  }));

  return (
    <>
      <Accordion
        expanded={accordion == 'address'}
        onChange={handleAccordion('address')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Endereço
          </Typography>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            paddingTop={2}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              <Box width="100%" gap={1} display="grid">
                <Box
                  gap={2}
                  width="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Controller
                    name="postcode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="CEP"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          inputComponent: MaskedInput,
                          inputProps: {
                            value: getValues('postcode'),
                            inputMode: 'numeric',
                            mask: Masks.CEP,
                            unmask: true,
                          },
                        }}
                        error={Boolean(
                          formState.errors.postcode,
                        )}
                        helperText={
                          formState.errors.postcode &&
                          formState.errors.postcode.message
                        }
                        sx={{ width: '80%' }}
                      />
                    )}
                  />
                  <Button
                    fullWidth
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={getCepInfos}
                    sx={{ height: '56px', width: '100px' }}
                  >
                    Buscar
                  </Button>
                </Box>
                <Box width="100%">
                  <TextField
                    label="Cidade"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register('city_name')}
                    onClick={() => setCityOpen(true)}
                    error={Boolean(formState.errors.city_id)}
                    helperText={
                      formState.errors.city_id &&
                      formState.errors.city_id.message
                    }
                    disabled={stateWatcher == 0}
                    InputProps={{
                      inputProps: { readOnly: true },
                      value: getValues('city_name'),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            size={12}
                            name="arrow"
                            rotate="-90deg"
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: cityWatcher ? true : false,
                    }}
                    sx={{
                      input: {
                        cursor: 'pointer',
                        '&.Mui-disabled': { cursor: 'default' },
                      },
                    }}
                  />
                  <Input
                    type="hidden"
                    {...register('city_id')}
                    inputProps={{
                      value: getValues('city_id'),
                    }}
                  />
                </Box>
                <Box gap={2} width="100%" display="flex">
                  <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        label="Número"
                        variant="outlined"
                        {...register('number')}
                        InputProps={{
                          inputComponent: MaskedInput,
                          inputProps: {
                            value: getValues('number'),
                            inputMode: 'numeric',
                            mask: Masks.CODE,
                            unmask: true,
                          },
                        }}
                        error={Boolean(formState.errors.number)}
                        helperText={
                          formState.errors.number &&
                          formState.errors.number.message
                        }
                        sx={{ width: '50%' }}
                      />
                    )}
                  />
                  <Controller
                    name="complement"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        variant="outlined"
                        label="Complemento"
                        {...register('complement')}
                        error={Boolean(
                          formState.errors.complement,
                        )}
                        helperText={
                          formState.errors.complement &&
                          formState.errors.complement.message
                        }
                        InputProps={{
                          inputProps: { maxLength: 90 },
                          value: getValues('complement'),
                        }}
                        sx={{ width: '50%' }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box width="100%" gap={1} display="grid">
                <Box width="100%">
                  <TextField
                    label="Estado"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register('state_name')}
                    onClick={() => setStateOpen(true)}
                    error={Boolean(formState.errors.state_id)}
                    helperText={
                      formState.errors.state_id &&
                      formState.errors.state_id.message
                    }
                    InputProps={{
                      inputProps: { readOnly: true },
                      value: getValues('state_name'),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            name="arrow"
                            size={12}
                            rotate="-90deg"
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: stateWatcher ? true : false,
                    }}
                    sx={{ input: { cursor: 'pointer' } }}
                  />
                  <Input
                    type="hidden"
                    {...register('state_id')}
                    inputProps={{
                      value: getValues('state_id'),
                    }}
                  />
                </Box>
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Rua"
                      margin="dense"
                      variant="outlined"
                      {...register('street')}
                      error={Boolean(formState.errors.street)}
                      helperText={
                        formState.errors.street &&
                        formState.errors.street.message
                      }
                      InputProps={{
                        inputProps: { maxLength: 200 },
                        value: getValues('street'),
                      }}
                    />
                  )}
                />
                <Controller
                  name="district"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Bairro"
                      margin="dense"
                      variant="outlined"
                      {...register('district')}
                      error={Boolean(formState.errors.district)}
                      helperText={
                        formState.errors.district &&
                        formState.errors.district.message
                      }
                      InputProps={{
                        inputProps: { maxLength: 200 },
                        value: getValues('district'),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              gap={2}
              width="100%"
              paddingTop={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                gap={2}
                display="flex"
                alignItems="center"
                className="stepStatus"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-start',
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
              >
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.APPROVED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.APPROVED)
                  }
                >
                  Aprovado
                </ButtonCheckbox>
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.REFUSED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.REFUSED)
                  }
                >
                  Rejeitado
                </ButtonCheckbox>
              </Box>
              <Box
                gap={{ xs: 2, sm: 2, md: 2, lg: 8 }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
                display="flex"
                alignItems="center"
                className="stepSend"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
              >
                <Typography
                  height={56}
                  component="p"
                  display="flex"
                  variant="body1"
                  alignItems="center"
                >
                  <Button
                    component="a"
                    variant="text"
                    disabled={
                      !btnDisabled == !formState.isDirty
                        ? true
                        : false
                    }
                    onClick={() => handleReset()}
                    sx={{
                      border: 'none !important',
                      textDecoration: 'underline !important',
                      backgroundColor: 'transparent !important',
                    }}
                  >
                    Cancelar
                  </Button>
                </Typography>
                <ButtonSave
                  fullWidth
                  type="submit"
                  disabled={
                    !btnDisabled == !formState.isDirty
                      ? true
                      : false
                  }
                  variant="contained"
                >
                  Salvar
                </ButtonSave>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <StateDrawer
        stateOpen={stateOpen}
        setStateOpen={setStateOpen}
        setChangeState={setChangeState}
        selectedState={selectedState.id}
        setSelectedState={setSelectedState}
      />

      <CityDrawer
        cityOpen={cityOpen}
        setCityOpen={setCityOpen}
        selectedCity={selectedCity.id}
        selectedState={selectedState.id}
        setSelectedCity={setSelectedCity}
      />

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Address;
