import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { ReceiptIdProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';
import { Targets, TypesReceipt } from '@2ndmarket/helpers';

import TED from './TED';
import PIX from './PIX';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const DepositWallet: React.FC<ReceiptIdProps> = ({
  user_id,
}) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  const Breadcrumb = styled(Button)(() => ({
    height: '28px',
    boxShadow: 'none',
    padding: '5px 10px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor:
          themes.authentication.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: themes.authentication.palette.grey[200],
      '&:hover': {
        backgroundColor: themes.authentication.palette.grey[200],
      },
    },
  }));

  return (
    <>
      <Alert
        severity="warning"
        variant="outlined"
        icon={<Icon name="info" size={18} />}
        sx={{
          border: 1,
          borderRadius: '3px',
          marginBottom: '8px',
          borderColor: 'warning.main',
          backgroundColor: 'warning.contrastText',
        }}
      >
        No momento, o cliente só pode realizar depósitos de mesma
        titularidade.
      </Alert>
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Breadcrumb
          disabled
          size="small"
          color="secondary"
          variant="contained"
        >
          <Typography
            variant="body2"
            color={themes.authentication.palette.text.secondary}
          >
            Início
          </Typography>
        </Breadcrumb>
        <Breadcrumb
          size="small"
          variant="contained"
          color={
            router.pathname ==
            Targets.BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT
              ? 'primary'
              : 'secondary'
          }
        >
          <Typography
            variant="body2"
            color={
              router.pathname ==
              Targets.BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT
                ? themes.authentication.palette.common.white
                : themes.authentication.palette.text.secondary
            }
          >
            Depósito
          </Typography>
        </Breadcrumb>
      </Box>

      <Box marginTop={2}>
        <TED user_id={user.id} operation={TypesReceipt.TED} />
        <PIX user_id={user.id} operation={TypesReceipt.PIX} />
      </Box>
      <Box>
        <ButtonReturn
          component="a"
          onClick={() => router.back()}
        >
          Voltar
        </ButtonReturn>
      </Box>
    </>
  );
};

export default DepositWallet;
