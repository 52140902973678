import { SocialNameExist, yupPt } from '@2ndmarket/helpers';

const schemaPF = yupPt.object().shape({
  birthdate: yupPt
    .string()
    .required('Data de nascimento é obrigatória')
    //@ts-ignore
    .dateBirth(),
  social_name: yupPt.string().optional().max(60),
});

const defaultValuesPF = {
  name: '',
  email: '',
  cpf_cnpj: '',
  birthdate: '',
  person_type: '',
  social_name: '',
  social_name_exist: SocialNameExist.NO,
};

const schemaPJ = yupPt.object().shape({
  fantasy_name: yupPt.string().required().max(60),
  legal_nature: yupPt.string().required().max(60),
  primary_cnae: yupPt.string().required().max(60),
  contact_name: yupPt.string().required().max(60),
  company_title: yupPt.string().required().max(60),
  contact_cpf: yupPt.string().min(11),
});

const defaultValuesPJ = {
  birthdate: '',
  fantasy_name: '',
  legal_nature: '',
  primary_cnae: '',
  contact_name: '',
  contact_cpf: '',
  company_title: '',
};

export { schemaPF, schemaPJ, defaultValuesPF, defaultValuesPJ };
