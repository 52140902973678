import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { UserIdProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';
import { SubMenu as subMenu } from '@2ndmarket/helpers';

import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';

import { styled } from '@mui/material/styles';

const SubMenu: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const pathname = router.pathname.split('/');

  const { data: user } = useMe(user_id);

  const handleLink = (id: number, path: string) => {
    router.push(`${path}/${id}`);
  };

  const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '54px',
    border: 'none',
    borderRadius: '6px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  }));

  return (
    <List
      sx={{
        gap: 1,
        paddingY: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {subMenu.map((item, index) => (
        <ListItem key={index} sx={{ padding: 0 }} component="li">
          <Buttons
            variant="outlined"
            onClick={() => handleLink(user.id, item.path)}
            sx={{
              color:
                item.value.toString() ===
                  pathname[2].toString() ||
                item.value.toString() ===
                  pathname[3].toString() ||
                item.value.toString() == router.query.crypto
                  ? `${themes.authentication.palette.primary.main} !important`
                  : `${themes.authentication.palette.grey[600]} !important`,
            }}
            endIcon={
              <Icon
                size={12}
                name="arrow"
                rotate="-90deg"
                color={themes.authentication.palette.grey[300]}
              />
            }
          >
            {item.label}
          </Buttons>
        </ListItem>
      ))}
    </List>
  );
};

export default SubMenu;
