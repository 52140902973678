import NextLink from 'next/link';
import { useContext, useEffect, useState } from 'react';

import { WalletCryptoProps } from '@2ndmarket/types';
import { themes, Icon } from '@2ndmarket/components';

import {
  AppContext,
  Crypto,
  Targets,
  WalletTransaction,
  formatBRL,
  toBitcoin,
  toBitcoinNumber,
} from '@2ndmarket/helpers';

import {
  useCryptoBalance,
  useCryptoWallet,
  useMe,
  usePrice,
} from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

const BalanceWalletCripto: React.FC<WalletCryptoProps> = ({
  user_id,
  coin,
}) => {
  const { data: user } = useMe(user_id);
  const { data: wallet } = useCryptoWallet(coin, user.id);
  const { data: price } = usePrice(coin);

  const { data: balance } = useCryptoBalance(
    coin,
    wallet[0].id,
    user.id,
  );

  const context = useContext(AppContext);

  const [showBalance, setShowBalance] = useState(true);

  const handleBalance = () => {
    context?.setShowBalance(!context.showBalance);

    localStorage.setItem(
      '@2ndMarket:showBalance',
      (!context?.showBalance).toString(),
    );

    setShowBalance(!showBalance);
  };

  useEffect(() => {
    if (!balance) {
      return;
    }
  }, [balance]);

  const cards = [
    {
      icon: 'send',
      title: 'Transferir',
      link: `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${coin}/${user.id}/${WalletTransaction.TRANSFER}`,
    },
    {
      icon: 'receive',
      title: 'Depositar',
      link: `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${coin}/${user.id}/${WalletTransaction.DEPOSIT}`,
    },
  ];

  return (
    <>
      <Box
        height={90}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="14.5px 10px 14.5px 20px"
        sx={{
          borderRadius: '3px',
          backgroundColor: 'white',
        }}
      >
        <FormControl variant="outlined" sx={{ width: '50%' }}>
          <InputLabel
            variant="outlined"
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              transform: 'none',
              position: 'relative',
            }}
          >
            <Typography component="span" variant="subtitle2">
              Saldo
            </Typography>
            <Typography
              component="p"
              variant="body1"
              color={themes.authentication.palette.primary.main}
            >
              {showBalance
                ? toBitcoin(balance.free_balance)
                : '*'}
              &nbsp;
              {coin.toUpperCase()}
            </Typography>
            <Typography component="span" variant="subtitle2">
              {showBalance
                ? '≈ ' +
                  formatBRL(
                    toBitcoinNumber(
                      toBitcoin(balance.free_balance),
                    ) * price.value,
                  )
                : '*'}
              &nbsp;
              {Crypto.BRL.toUpperCase()}
            </Typography>
          </InputLabel>
        </FormControl>
        <IconButton onClick={handleBalance}>
          {showBalance ? (
            <Icon
              size={20}
              name="view"
              color={themes.authentication.palette.grey[300]}
            />
          ) : (
            <Icon
              size={20}
              name="hide"
              color={themes.authentication.palette.grey[300]}
            />
          )}
        </IconButton>
      </Box>
      <Box
        gap="12px"
        display="flex"
        marginTop="10px"
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {cards.map((item, index) => (
          <NextLink href={item.link} passHref key={index}>
            <Link
              sx={{
                width: {
                  xs: '100%',
                  sm: '50%',
                  lg: '50%',
                },
                height: 80,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Card
                key={index}
                sx={{
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow:
                      '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
                  },
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar>
                      <Icon
                        size={20}
                        name={item.icon}
                        color={
                          themes.authentication.palette.primary
                            .main
                        }
                      />
                    </Avatar>
                  }
                  title={
                    <Typography component="p" variant="body1">
                      {item.title}
                    </Typography>
                  }
                />
              </Card>
            </Link>
          </NextLink>
        ))}
      </Box>
    </>
  );
};

export default BalanceWalletCripto;
