import {
  yupPt,
  Documents,
  validateCpf,
  validateCnpj,
  validateName,
  validateEmail,
} from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  name: yupPt
    .string()
    .test('name', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        if (Documents.CPF) {
          return createError({
            message: 'Nome e sobrenome é obrigatório',
          });
        } else {
          return createError({
            message: 'Razão social é obrigatório',
          });
        }
      }

      if (!validateName(value)) {
        if (Documents.CPF) {
          return createError({
            message:
              'Nome e sobrenome deve conter pelo menos 2 palavras',
          });
        } else {
          return createError({
            message:
              'Razão social deve conter pelo menos 2 palavras',
          });
        }
      }

      return true;
    }),
  email: yupPt
    .string()
    .max(75)
    .test('email', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'E-mail é obrigatório',
        });
      }

      if (!validateEmail(value)) {
        return createError({
          message: 'E-mail inválido',
        });
      }

      return true;
    }),
  cpf_cnpj: yupPt
    .string()
    .required('Documento obrigatório')
    .test('cpf_cnpj', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Documento é obrigatório',
        });
      }

      if (value.length <= 14) {
        if (!validateCpf(value)) {
          return createError({
            message: 'Documento CPF inválido.',
          });
        }
      } else {
        if (!validateCnpj(value)) {
          return createError({
            message: 'Documento CNPJ inválido.',
          });
        }
      }

      return true;
    }),
});

const defaultValues = {
  person_type: '',
  name: '',
  email: '',
  cpf_cnpj: '',
  updated_at: '',
  created_at: '',
};

export { schema, defaultValues };
