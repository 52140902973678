import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useIntent } from '@2ndmarket/services';

import {
  IntentStatus,
  Targets,
  dayjsUtc,
  formatBRL,
  parseBRL,
} from '@2ndmarket/helpers';

import {
  DateDrawer,
  Icon,
  ModalDialog,
  MuiLoader,
  themes,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import { schema, defaultValues } from './formReviewTicket';
import BillSummary from '../../../../components/BillSummary';

interface ReviewProps {
  user_id: number;
  data: any;
  onResponsePin: (result: any) => void;
  setStepLineTicket: (value: boolean) => void;
  setStepRevieTicket: (value: boolean) => void;
}

const StepReview: React.FC<ReviewProps> = ({
  user_id,
  data,
  onResponsePin,
  setStepLineTicket,
  setStepRevieTicket,
}) => {
  const router = useRouter();
  const [refreshInterval, setRefreshInterval] = useState(1000);

  const { intent, loading } = useIntent(
    user_id,
    data?.id,
    data?.type,
    data?.register,
    refreshInterval,
  );

  const defaultDateText =
    'Hoje, ' + dayjsUtc().format('DD/MM/YYYY');

  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState(defaultDateText);

  const [isLoading, setIsLoading] = useState(loading);
  const [errorModal, setErrorModal] = useState(false);
  const [resultTicket, setResultTicket] = useState({});

  const [valueAmount, setValueAmount] = useState<number>(0);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('schedule_date', selectedDate);
  }, [selectedDate, setValue]);

  const onResponse = async (result: any) => {
    setIsLoading(true);

    setValue(
      'amount',
      valueAmount
        ? formatBRL(valueAmount)
        : formatBRL(result.nominal_amount),
    );

    const paying =
      getValues('amount').replace(/[^\d]/g, '') || 0;
    const scheduling =
      getValues('schedule_date').split(', ')[1] ||
      getValues('schedule_date');

    let review = {
      amount: Number(paying),
      schedule_date: scheduling,
    };

    Object.assign(result, review);
    setResultTicket(result);
  };

  const onSubmitReview = () => {
    onResponsePin(resultTicket);
  };

  const handleReturnReview = () => {
    setResultTicket({});

    setStepLineTicket(true);
    setStepRevieTicket(false);
  };

  const handleClose = () => {
    router.push(
      `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user_id}`,
    );
  };

  useEffect(() => {
    if (!intent) return;

    switch (intent.intent_status) {
      case IntentStatus.NONE:
        setIsLoading(true);
        break;
      case IntentStatus.FAILURE:
        setErrorModal(true);
        setRefreshInterval(0);
        break;
      case IntentStatus.DONE:
        setRefreshInterval(0);
        break;
    }
  }, [intent]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <>
      {loading && isLoading && (
        <Backdrop
          open={loading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={loading} />
        </Backdrop>
      )}
      <Box
        component="form"
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor:
              themes.authentication.palette.grey[900],
          },
        }}
      >
        <Box gap={2} display="flex" alignItems="center">
          <Box width={{ xs: '100%', lg: '50%' }}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Valor"
                  variant="outlined"
                  autoComplete="off"
                  error={Boolean(errors.amount)}
                  helperText={
                    errors.amount && errors.amount.message
                  }
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) =>
                    setValueAmount(parseBRL(event.target.value))
                  }
                />
              )}
            />
          </Box>
          <Box width={{ xs: '100%', lg: '50%' }}>
            <Controller
              name="schedule_date"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  margin="dense"
                  variant="outlined"
                  label="Data do pagamento"
                  onClick={() => setDateOpen(true)}
                  error={Boolean(errors.schedule_date)}
                  helperText={
                    errors.schedule_date &&
                    errors.schedule_date.message
                  }
                  InputProps={{
                    inputProps: { readOnly: true },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          '& i': {
                            color: 'text.disabled',
                          },
                        }}
                      >
                        <Icon name="calendar" size={16} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    input: { cursor: 'pointer' },
                    marginY: 0,
                  }}
                />
              )}
            />
          </Box>
        </Box>
        {!loading &&
          intent.intent_status == IntentStatus.DONE && (
            <BillSummary
              user_id={user_id}
              dataIntent={intent}
              onResponse={onResponse}
              valueAmount={valueAmount}
            />
          )}
        <Box
          marginTop={3}
          display="flex"
          justifyContent="space-between"
        >
          <ButtonReturn
            component="a"
            onClick={handleReturnReview}
          >
            Voltar
          </ButtonReturn>
          <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{ paddingX: 8, width: 172 }}
            onClick={handleSubmit(onSubmitReview)}
          >
            Continuar
          </Button>
        </Box>
        <DateDrawer
          dateOpen={dateOpen}
          setDateOpen={setDateOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          defaultDateText={defaultDateText}
        />
      </Box>

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorTitle="Erro ao gerar boleto"
          errorMessage="Por favor, tente novamente mais tarde. Caso o problema persista, entre em contato conosco."
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default StepReview;
