import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { MaskedInput, themes } from '@2ndmarket/components';
import { sendBillRegister, useMe } from '@2ndmarket/services';
import {
  HttpError,
  HttpStatus,
  Masks,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';

import { schema, defaultValues } from './formTicket';

interface TypeableProps {
  user_id: number;
  stepLineTicket?: boolean;
  stepRevieTicket?: boolean;
  setStepLineTicket: (value: boolean) => void;
  setStepRevieTicket: (value: boolean) => void;
  setTransferPromise: (value: any) => void;
  setStepMenuTicket: (value: boolean) => void;
}

const StepTypeableLine: React.FC<TypeableProps> = ({
  user_id,
  setStepLineTicket,
  setStepRevieTicket,
  setTransferPromise,
  setStepMenuTicket,
}) => {
  const { data: user } = useMe(user_id);

  const [errorMessage, setErrorMessage] = useState('');

  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setErrorMessage(error.error);
        break;
    }
  };

  const onSubmitLine = async (data: {
    typeable_line: string;
  }) => {
    let digitableLine = data.typeable_line.replace(/[^\d]/g, '');
    if (digitableLine.length < 46 || digitableLine.length > 48) {
      setError('typeable_line', {
        type: 'custom',
        message: 'Linha digitável inválida',
      });

      return;
    }

    setStepLineTicket(false);
    setStepRevieTicket(true);

    await sendBillRegister(digitableLine, '', user.id)
      .then(response => {
        const billRegister = response
          ?.split('?')[1]
          .split('&')
          .reduce((accumulator: any, item: any) => {
            const [key, value] = item.split('=');
            accumulator[key] = value;
            return accumulator;
          }, {});

        setTransferPromise({
          type: billRegister.type,
          id: parseInt(billRegister.intent_id),
          register: Boolean(billRegister.is_register),
        });
      })
      .catch((error: HttpError) => {
        handleErrors(error);
      });
  };

  const handleReturnLine = () => {
    setStepLineTicket(false);
    setStepMenuTicket(true);
  };

  useEffect(() => {
    reset({
      typeable_line: '',
    });
  }, [reset]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Controller
        name="typeable_line"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            {...field}
            variant="outlined"
            label="Linha digitável"
            error={Boolean(errors.typeable_line)}
            helperText={
              errors.typeable_line &&
              errors.typeable_line.message
            }
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                unmask: true,
                mask: Masks.TYPEABLE_LINE,
                inputMode: 'numeric',
              },
            }}
          />
        )}
      />
      <Box
        marginTop={3}
        display="flex"
        justifyContent="space-between"
      >
        <ButtonReturn component="a" onClick={handleReturnLine}>
          Voltar
        </ButtonReturn>
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{ paddingX: 8, width: 172 }}
          onClick={handleSubmit(onSubmitLine)}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default StepTypeableLine;
