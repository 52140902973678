import {
  yupPt,
  validateCpf,
  validateName,
  validateEmail,
} from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  name: yupPt
    .string()
    .test('name', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Nome é obrigatório',
        });
      }

      if (!validateName(value)) {
        return createError({
          message: 'Nome deve conter pelo menos 2 palavras',
        });
      }

      return true;
    }),
  email: yupPt
    .string()
    .max(75)
    .test('email', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'E-mail é obrigatório',
        });
      }

      if (!validateEmail(value)) {
        return createError({
          message: 'E-mail inválido',
        });
      }

      return true;
    }),
  cpf: yupPt
    .string()
    .required('Documento obrigatório')
    .test('cpf', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Documento é obrigatório',
        });
      }

      if (!validateCpf(value)) {
        return createError({
          message: 'Documento CPF inválido.',
        });
      }

      return true;
    }),
});

const defaultValues = {
  name: '',
  email: '',
  cpf: '',
  phone: '',
  password: '',
  role_id: 0,
};

const schemaCreate = yupPt.object().shape({
  name: yupPt
    .string()
    .test('name', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Nome é obrigatório',
        });
      }

      if (!validateName(value)) {
        return createError({
          message: 'Nome deve conter pelo menos 2 palavras',
        });
      }

      return true;
    }),
  email: yupPt
    .string()
    .max(75)
    .test('email', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'E-mail é obrigatório',
        });
      }

      if (!validateEmail(value)) {
        return createError({
          message: 'E-mail inválido',
        });
      }

      return true;
    }),
  cpf: yupPt
    .string()
    .required('Documento obrigatório')
    .test('cpf', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Documento é obrigatório',
        });
      }

      if (!validateCpf(value)) {
        return createError({
          message: 'Documento CPF inválido.',
        });
      }

      return true;
    }),
  phone: yupPt.string().required('Telefone obrigatório'),
  password: yupPt.string().required('Senha obrigatória'),
  role: yupPt.string().required('Função obrigatório'),
});

const defaultValuesCreate = {
  name: '',
  email: '',
  cpf: '',
  phone: '',
  password: '',
  role: '',
};

export {
  schema,
  defaultValues,
  defaultValuesCreate,
  schemaCreate,
};
