import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, set } from 'react-hook-form';

import { WalletCryptoProps } from '@2ndmarket/types';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import {
  Icon,
  ModalDialog,
  MuiLoader,
  Receipt,
  themes,
} from '@2ndmarket/components';

import {
  createCryptoWallet,
  useCryptoWallet,
  useMe,
} from '@2ndmarket/services';

import {
  Crypto,
  HttpError,
  HttpStatus,
  Status,
  Targets,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { schema, defaultValues } from './formNameWallet';

import BalanceWalletCripto from './BalanceWalletCripto';
import AccountWalletCripto from './AccountWalletCripto';
import ReceiptWalletCripto from './ReceiptWalletCripto';

interface StepsProps {
  crypto: string;
  user_id?: number;
  setStepInfoWallet?: (value: boolean) => void;
  setStepNameWallet?: (value: boolean) => void;
  setStepBalanceWallet?: (value: boolean) => void;
}

const WalletCripto: React.FC<WalletCryptoProps> = ({
  user_id,
  coin,
}) => {
  const { data: user } = useMe(user_id);
  const { data: wallet } = useCryptoWallet(coin, user_id);

  const [crypto, setCrypto] = useState('');

  const [stepInfoWallet, setStepInfoWallet] = useState(true);
  const [stepNameWallet, setStepNameWallet] = useState(false);
  const [stepBalanceWallet, setStepBalanceWallet] =
    useState(false);

  useEffect(() => {
    if (!user || !wallet) {
      return;
    }

    switch (coin) {
      case Crypto.BTC:
        setCrypto(Crypto.BTC);
        break;
      case Crypto.BKAI:
        setCrypto(Crypto.BKAI);
        break;
    }

    wallet.map(wallet => {
      if (wallet.status == Status.NONE) {
        setStepInfoWallet(true);
        setStepNameWallet(false);
        setStepBalanceWallet(false);
        return;
      } else if (wallet.status == Status.APPROVED) {
        setStepInfoWallet(false);
        setStepNameWallet(false);
        setStepBalanceWallet(true);
        return;
      }
    });
  }, [user, wallet, coin]);

  return (
    <Box width="100%">
      {wallet.map((wallet, key) => (
        <Box key={key}>
          <>
            {stepInfoWallet && (
              <StepInfo
                crypto={crypto}
                setStepInfoWallet={setStepInfoWallet}
                setStepNameWallet={setStepNameWallet}
              />
            )}

            {stepNameWallet && (
              <StepName
                crypto={crypto}
                user_id={user.id}
                setStepInfoWallet={setStepInfoWallet}
                setStepNameWallet={setStepNameWallet}
                setStepBalanceWallet={setStepBalanceWallet}
              />
            )}

            {stepBalanceWallet && (
              <>
                <BalanceWalletCripto
                  wallet_id={wallet.id}
                  user_id={user.id}
                  coin={coin}
                />
                <AccountWalletCripto
                  wallet_id={wallet.id}
                  user_id={user.id}
                  coin={coin}
                />
                <ReceiptWalletCripto
                  wallet_id={wallet.id}
                  user_id={user.id}
                  coin={coin}
                />
              </>
            )}
          </>
        </Box>
      ))}
    </Box>
  );
};

const StepInfo: React.FC<StepsProps> = ({
  crypto,
  setStepInfoWallet,
  setStepNameWallet,
}) => {
  const handleStepInfo = () => {
    if (setStepInfoWallet && setStepNameWallet) {
      setStepInfoWallet(false);
      setStepNameWallet(true);
    }
  };

  return (
    <Box
      gap={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <Box gap={2} display="flex">
        <Avatar
          sx={{
            width: 88,
            height: 88,
            marginLeft: '0 !important',
            backgroundColor:
              themes.authentication.palette.grey[100],
          }}
        >
          <Icon
            size={42}
            name="wallet"
            viewBox="0 0 42 42"
            format={IconFormat.SVG}
          />
        </Avatar>
        <Box display="flex" flexDirection="column">
          <Typography
            component="p"
            variant="body1"
            fontWeight={500}
          >
            Carteira cripto
          </Typography>
          <Typography component="p" variant="body1">
            O cliente ainda não possui uma &nbsp;
            <span
              style={{
                color:
                  themes.authentication.palette.primary.main,
              }}
            >
              carteira&nbsp;
              {crypto == Crypto.BTC ? 'bitcoin' : 'bkai'}.
            </span>
          </Typography>
          <Typography variant="body1" component="p">
            Deseja abrir uma carteira?
          </Typography>
        </Box>
      </Box>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={handleStepInfo}
        sx={{ height: 50, width: 190 }}
      >
        Abrir carteira&nbsp;
        {crypto == Crypto.BTC ? 'bitcoin' : 'bkai'}
      </Button>
    </Box>
  );
};

const StepName: React.FC<StepsProps> = ({
  crypto,
  user_id,
  setStepInfoWallet,
  setStepNameWallet,
  setStepBalanceWallet,
}) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const handleStepName = () => {
    if (setStepNameWallet && setStepInfoWallet) {
      setStepNameWallet(false);
      setStepInfoWallet(true);
    }
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setShowModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const onSubmit = (data: { name: string }) => {
    setIsLoading(true);

    createCryptoWallet(
      {
        name: data.name,
        coin: crypto,
      },
      user_id,
    )
      .then(() => {
        setIsLoading(true);
        if (
          setStepInfoWallet &&
          setStepNameWallet &&
          setStepBalanceWallet
        ) {
          setStepInfoWallet(false);
          setStepNameWallet(false);
          setStepBalanceWallet(true);

          router.push(
            `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${crypto}/${user_id}`,
          );
        }
      })
      .catch((error: HttpError) => {
        handleErrors(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (setStepNameWallet) {
      setStepNameWallet(true);
    }
  }, [setStepNameWallet]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiInputBase-root': {
            backgroundColor:
              themes.authentication.palette.grey[900],
          },
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              variant="outlined"
              autoComplete="off"
              label="Nome da carteira"
              error={Boolean(errors.name)}
              inputProps={{ maxLength: 200 }}
              helperText={errors.name && errors.name.message}
            />
          )}
        />
        <Box
          marginY={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <ButtonReturn component="a" onClick={handleStepName}>
            Cancelar
          </ButtonReturn>
          <Button
            size="large"
            type="submit"
            color="primary"
            variant="contained"
            sx={{ paddingX: 8, width: 172 }}
          >
            Continuar
          </Button>
        </Box>
      </Box>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          errorTitle="Falha ao criar carteira"
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default WalletCripto;
