import { useState } from 'react';

import { usePin } from '@2ndmarket/services';
import { StepOnboardingBacicProps } from '@2ndmarket/types';
import { OnboardingStep, ReviewStep } from '@2ndmarket/helpers';

import {
  Icon,
  themes,
  ModalDialog,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';

import BoxBtnsReviewStep from '../../../../components/BoxBtnsReviewStep';

const Password: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  const { data: pin, mutate: mutatePin } = usePin(user_id);

  const [modalPin, setModalPin] = useState(false);
  const [accordion, setAccordion] = useState<string | false>(
    'password',
  );

  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  const handlePinModal = () => {
    setModalPin(true);
  };

  return (
    <>
      <Accordion
        expanded={accordion == 'password'}
        onChange={handleAccordion('password')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Senha &nbsp;
          </Typography>
          <Link
            component="button"
            color="secondary.main"
            onClick={handlePinModal}
            sx={{
              zIndex: 99,
              position: 'relative',
              textDecoration: 'none',
            }}
          >
            <Icon
              size={14}
              name="info"
              color={themes.authentication.palette.grey[300]}
            />
          </Link>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            gap={2}
            marginY={2}
            display="flex"
            alignItems="center"
          >
            {pin.status == ReviewStep.NONE ? (
              <>
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor:
                      themes.authentication.palette.warning
                        .contrastText,
                  }}
                >
                  <Icon
                    size={20}
                    name="key"
                    color={
                      themes.authentication.palette.warning.main
                    }
                  />
                </Avatar>
                <Typography component="p" variant="body1">
                  Usuário não possui senha cadastrada
                </Typography>
              </>
            ) : (
              <>
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor:
                      themes.authentication.palette.success
                        .contrastText,
                  }}
                >
                  <Icon
                    size={20}
                    name="key"
                    color={
                      themes.authentication.palette.success.main
                    }
                  />
                </Avatar>
                <Typography component="p" variant="body1">
                  O usuário possui uma senha cadastrada.
                </Typography>
              </>
            )}
          </Box>
          <Box
            gap={2}
            width="100%"
            paddingTop={2}
            display="flex"
            flexDirection={{
              xs: 'column',
              sm: 'row',
              md: 'row',
              lg: 'row',
            }}
            alignItems="center"
            justifyContent="space-between"
          >
            <BoxBtnsReviewStep
              status={status}
              user_id={user_id}
              setStatus={setStatus}
              step={OnboardingStep.PIN}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {modalPin && (
        <ModalDialog
          maxWidth="xs"
          open={modalPin}
          onClose={() => setModalPin(false)}
        >
          <Box
            sx={{
              gap: '16px',
              display: 'flex',
              padding: '10px 20px',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              color={themes.authentication.palette.grey[700]}
            >
              Senha
            </Typography>
            <Typography
              component="p"
              variant="body1"
              color={themes.authentication.palette.grey[600]}
            >
              O PIN é utilizado pelo cliente para aprovar as
              transações realizadas na plataforma.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setModalPin(false)}
              sx={{ marginTop: '90px' }}
            >
              Entendi
            </Button>
          </Box>
        </ModalDialog>
      )}
    </>
  );
};

export default Password;
