import { yupPt } from '@2ndmarket/helpers';

const message = 'Campo obrigatório';

const schema = yupPt.object().shape({
  name: yupPt.string().required(message),
  initials: yupPt.string().required(message),
  country_id: yupPt.number().required(message),
});

const defaultValues = {
  name: '',
  initials: '',
  country_id: '',
};

export { schema, defaultValues };
