import { useState } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { ReceiptIdProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';

import StepReview from './StepReview';
import StepPin from './StepPin';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const TransferWallet: React.FC<ReceiptIdProps> = ({
  user_id,
  crypto,
}) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);

  const [stepTransfer, setStepTransfer] = useState(true);
  const [stepReview, setStepReview] = useState(false);
  const [stepPin, setStepPin] = useState(false);

  const [result, setResult] = useState({});

  const transfers = [
    {
      icon: 'wallet',
      title: 'Bank.ai',
      type: `${crypto}`,
      description: 'Gratuíta entre carteiras Bank.ai',
      path: '',
    },
    {
      icon: 'qrcode',
      title: 'Carteira Externa',
      type: null,
      description: 'Transferência com taxa de rede',
      path: '',
    },
  ];

  const handleSteps = () => {
    setStepTransfer(false);
    setStepReview(true);
  };

  const onRequestPin = (result: {}) => {
    setStepReview(false);
    setStepPin(true);

    setResult(result);
  };

  const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '68px',
    border: 'none',
    borderRadius: '3px',
    padding: '15px 20px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  }));

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  const Breadcrumb = styled(Button)(() => ({
    height: '28px',
    boxShadow: 'none',
    padding: '5px 10px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor:
          themes.authentication.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: themes.authentication.palette.grey[200],
      '&:hover': {
        backgroundColor: themes.authentication.palette.grey[200],
      },
    },
  }));

  return (
    <>
      <Box
        gap={1}
        display="flex"
        marginBottom={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Breadcrumb
          disabled
          size="small"
          color="secondary"
          variant="contained"
        >
          <Typography
            variant="body2"
            color={themes.authentication.palette.text.secondary}
          >
            Início
          </Typography>
        </Breadcrumb>
        <Breadcrumb
          size="small"
          variant="contained"
          color={stepTransfer == true ? 'primary' : 'secondary'}
        >
          <Typography
            variant="body2"
            color={
              stepTransfer == true
                ? themes.authentication.palette.common.white
                : themes.authentication.palette.text.secondary
            }
          >
            Transferência
          </Typography>
        </Breadcrumb>

        {!stepTransfer && (
          <Breadcrumb
            size="small"
            variant="contained"
            color={stepReview == true ? 'primary' : 'secondary'}
          >
            <Typography
              variant="body2"
              color={
                stepReview == true
                  ? themes.authentication.palette.common.white
                  : themes.authentication.palette.text.secondary
              }
            >
              Revisão
            </Typography>
          </Breadcrumb>
        )}

        {stepPin && (
          <Breadcrumb
            size="small"
            variant="contained"
            color={stepPin == true ? 'primary' : 'secondary'}
          >
            <Typography
              variant="body2"
              color={
                stepPin == true
                  ? themes.authentication.palette.common.white
                  : themes.authentication.palette.text.secondary
              }
            >
              PIN
            </Typography>
          </Breadcrumb>
        )}
      </Box>

      {stepTransfer && (
        <>
          <Box marginTop={2}>
            <List
              sx={{
                gap: '10px',
                paddingY: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {transfers.map(
                (item, index) =>
                  item.type === crypto && (
                    <ListItem
                      key={index}
                      component="li"
                      sx={{ padding: 0 }}
                    >
                      <Buttons
                        variant="outlined"
                        onClick={handleSteps}
                        endIcon={
                          <Icon
                            size={12}
                            name="arrow"
                            rotate="-90deg"
                            color={
                              themes.authentication.palette
                                .grey[300]
                            }
                          />
                        }
                      >
                        <Box
                          gap={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon name={item.icon} size={20} />
                          <Box textAlign="left">
                            <Typography
                              variant="body1"
                              component="span"
                              color={
                                themes.authentication.palette
                                  .text.primary
                              }
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              component="p"
                              variant="body2"
                              color={
                                themes.authentication.palette
                                  .text.secondary
                              }
                            >
                              {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Buttons>
                    </ListItem>
                  ),
              )}
            </List>
          </Box>
          <Box>
            <ButtonReturn
              component="a"
              onClick={() => router.back()}
            >
              Voltar
            </ButtonReturn>
          </Box>
        </>
      )}

      {stepReview && (
        <StepReview
          user_id={user.id}
          crypto={crypto as string}
          onRequestPin={onRequestPin}
          setStepReview={setStepReview}
          setStepTransfer={setStepTransfer}
        />
      )}

      {stepPin && (
        <StepPin
          user_id={user.id}
          onResponsePin={result}
          setStepPin={setStepPin}
          crypto={crypto as string}
          setStepReview={setStepReview}
        />
      )}
    </>
  );
};

export default TransferWallet;
