import { useState } from 'react';
import { Icon, themes } from '@2ndmarket/components';

import {
  OnboardingProgress,
  RegisterType,
  UserStatus,
  Status,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';

import TextFilters from '../TextFilters';

interface DrawerFilterProps {
  onChange: (key: string, value: string | number) => void;
}

const DrawerFilter: React.FC<DrawerFilterProps> = ({
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const [filterOrdering, setFilterOrdering] = useState('asc');
  const ordering = [
    { value: 'asc', label: 'A-Z' },
    { value: 'desc', label: 'Z-A' },
  ];

  const handleFilterOrderChange = (event: SelectChangeEvent) => {
    setFilterOrdering(event.target.value as string);
  };

  const [filterPersonType, setFilterPersonType] = useState('--');
  const personType = [
    { value: '--', label: 'Todos' },
    { value: RegisterType.CPF, label: 'Pessoa Física' },
    { value: RegisterType.CNPJ, label: 'Pessoa Jurídica' },
  ];

  const handleFilterRegisterChange = (
    event: SelectChangeEvent,
  ) => {
    setFilterPersonType(event.target.value as string);
  };

  const [filterOnboardingProgress, setFilterOnboardingProgress] =
    useState('--');

  const onboardingProgress = [
    { value: '--', label: 'Todos' },
    {
      value: OnboardingProgress.BASIC,
      label: 'Cadastro Básico',
    },
    { value: OnboardingProgress.BANK, label: 'Conta Bancária' },
    {
      value: OnboardingProgress.DIGITAL,
      label: 'Conta Digital',
    },
    { value: OnboardingProgress.NEWUSER, label: 'Novo Usuário' },
  ];

  const handleFilterEtapaChange = (event: SelectChangeEvent) => {
    setFilterOnboardingProgress(event.target.value as string);
  };

  const [
    filterOnboardingProgressStatus,
    setFilterOnboardingProgressStatus,
  ] = useState('--');

  const onboardingProgressStatus = [
    { value: '--', label: 'Todos' },
    { value: Status.APPROVED, label: 'Aprovado' },
    { value: Status.NONE, label: 'Nenhum' },
    { value: Status.PENDING, label: 'Pendente' },
    { value: Status.PROCESSING, label: 'Processando' },
    { value: Status.RECEIVED, label: 'Recebido' },
    { value: Status.REFUSED, label: 'Recusado' },
  ];

  const handleFilterApprovalChange = (
    event: SelectChangeEvent,
  ) => {
    setFilterOnboardingProgressStatus(
      event.target.value as string,
    );
  };

  const [filterUserStatus, setFilterUserStatus] = useState('--');
  const userStatus = [
    { value: '--', label: 'Todos' },
    { value: UserStatus.APPROVED, label: 'Aprovado' },
    { value: UserStatus.BLOCKED, label: 'Bloqueado' },
    { value: UserStatus.DISABLED, label: 'Desabilitado' },
    { value: UserStatus.PENDING, label: 'Pendente' },
    { value: UserStatus.REFUSED, label: 'Recusado' },
  ];
  const handleFilterStatusChange = (
    event: SelectChangeEvent,
  ) => {
    setFilterUserStatus(event.target.value as string);
  };

  const toogleDrawerFilter = (open: boolean) => () => {
    setOpen(open);
  };

  const handleClearFilter = () => {
    setFilterOrdering('asc');
    setFilterPersonType('--');
    setFilterUserStatus('--');
    setFilterOnboardingProgress('--');
    setFilterOnboardingProgressStatus('--');
  };

  const onSearch = () => {
    const drawerFilter = [
      { key: 'ordering', value: filterOrdering },
      { key: 'personType', value: filterPersonType },
      { key: 'userStatus', value: filterUserStatus },
      {
        key: 'onboardingProgress',
        value: filterOnboardingProgress,
      },
      {
        key: 'onboardingProgressStatus',
        value: filterOnboardingProgressStatus,
      },
    ];

    onChange('drawer', drawerFilter as never);
    setOpen(false);
  };

  const DrawerHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 4, 2, 4),
  }));

  const DrawerBody = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 4),
  }));

  const DrawerFooter = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 4),
  }));

  return (
    <>
      <Button
        component="a"
        color="secondary"
        variant="contained"
        onClick={toogleDrawerFilter(true)}
        sx={{
          ':hover': {
            backgroundColor:
              themes.authentication.palette.secondary.light,
          },
          boxShadow: 'none',
          marginTop: 1,
          width: 160,
          height: 56,
          gap: 1,
        }}
      >
        <Icon
          name="settings"
          color={themes.authentication.palette.grey[300]}
        />
        <Typography
          color={themes.authentication.palette.grey[600]}
        >
          Filtro
        </Typography>
      </Button>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={onSearch}
        sx={{
          '.MuiPaper-root': {
            height: '100%',
            backgroundColor:
              themes.authentication.palette.common.white,
          },
        }}
      >
        <DrawerHeader width={385}>
          <Stack
            gap={6}
            width="80%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Filtros
            </Typography>
          </Stack>
        </DrawerHeader>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <DrawerBody width={385}>
            <Box width="100%">
              <FormControl fullWidth margin="dense">
                <InputLabel id="filter-order-label">
                  Ordenar
                </InputLabel>
                <Select
                  label="Ordenar"
                  value={filterOrdering}
                  id="filter-order-select"
                  labelId="filter-order-label"
                  onChange={handleFilterOrderChange}
                >
                  {ordering.map((item: any, key: number) => (
                    <MenuItem key={key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="filter-register-label">
                  Tipo de cadastro
                </InputLabel>
                <Select
                  value={filterPersonType}
                  label="Tipo de cadastro"
                  id="filter-register-select"
                  labelId="filter-register-label"
                  onChange={handleFilterRegisterChange}
                >
                  {personType.map((item: any, key: number) => (
                    <MenuItem key={key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="filter-etapa-label">
                  Etapa do cadastro
                </InputLabel>
                <Select
                  id="filter-etapa-select"
                  label="Etapa do cadastro"
                  labelId="filter-etapa-label"
                  value={filterOnboardingProgress}
                  onChange={handleFilterEtapaChange}
                >
                  {onboardingProgress.map(
                    (item: any, key: number) => (
                      <MenuItem key={key} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="filter-approval-label">
                  Status da Etapa do Cadastro
                </InputLabel>
                <Select
                  id="filter-approval-select"
                  labelId="filter-approval-label"
                  label="Status da Etapa do Cadastro"
                  value={filterOnboardingProgressStatus}
                  onChange={handleFilterApprovalChange}
                >
                  {onboardingProgressStatus.map(
                    (item: any, key: number) => (
                      <MenuItem key={key} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="filter-status-label">
                  Status do usuário
                </InputLabel>
                <Select
                  value={filterUserStatus}
                  label="Status do usuário"
                  id="filter-status-select"
                  labelId="filter-status-label"
                  onChange={handleFilterStatusChange}
                >
                  {userStatus.map((item: any, key: number) => (
                    <MenuItem key={key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Link
              onClick={handleClearFilter}
              sx={{ marginTop: 1 }}
              color="primary.main"
              component="button"
              type="button"
            >
              Limpar filtros
            </Link>
            <Divider sx={{ marginY: 2 }} />
            <TextFilters />
          </DrawerBody>
          <DrawerFooter
            width={385}
            position="fixed"
            bottom={0}
            sx={{
              backgroundColor: 'white',
              padding: '20px 24px',
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={onSearch}
              sx={{ width: '100%', boxShadow: 'none' }}
            >
              Aplicar
            </Button>
          </DrawerFooter>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
