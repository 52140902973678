import {
  yupPt,
  Documents,
  validateCpf,
  validateCnpj,
  validateName,
  validateEmail,
} from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  amount: yupPt.string().required('Campo obrigatório'),
  cpf_cnpj: yupPt
    .string()
    .required('Campo obrigatório')
    .test('cpf_cnpj', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Campo é obrigatório',
        });
      }

      return true;
    }),
  recipient: yupPt.string().required('Campo obrigatório'),
});

export const defaultValues = {
  amount: '',
  cpf_cnpj: '',
  recipient: '',
};
