import NextLink from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';

import { themes } from '@2ndmarket/components';
import { useStates } from '@2ndmarket/services';
import { useDebounce } from '@2ndmarket/hooks';

import {
  TableColumnStateList,
  Targets,
} from '@2ndmarket/helpers';

import {
  Header,
  Paginator,
  TableConf,
  Navigation,
} from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';

const StateList: React.FC = () => {
  const router = useRouter();

  const initialFilters = {
    ordering: 'asc',
    pageSize: 20,
    filter: '',
    status: '',
    page: 1,
  };

  const [filters, setFilters] = useState(initialFilters);

  if (router.query.page) {
    initialFilters.page = Number(router.query.page);
  }

  let debounceFilter = useDebounce(
    filters.filter,
    filters.filter ? 300 : 0,
  );

  const { data: states, loading } = useStates(
    debounceFilter,
    filters.ordering,
    filters.page,
    filters.pageSize,
  );

  const onChange = (key: string, value: string | number) => {
    switch (key) {
      case 'filter':
        debounceFilter = value as string;
        router.query.filter = debounceFilter;
        break;
      case 'pageSize':
        initialFilters.pageSize = value as number;
        router.query.pageSize =
          initialFilters.pageSize.toString();
        break;
      case 'page':
        initialFilters.page = value as number;
        router.query.page = initialFilters.page.toString();
        break;
    }

    const params = new URLSearchParams({
      ordering:
        (router.query.ordering as string) ??
        initialFilters.ordering,
      page:
        (router.query.page as string) ??
        initialFilters.page.toString(),
      pageSize:
        (router.query.pageSize as string) ??
        initialFilters.pageSize.toString(),
      filter: (router.query.filter as string) ?? debounceFilter,
    });

    if (params.get('filter') === '') {
      params.delete('filter');
    }

    router.push({
      pathname: router.pathname,
      query: params.toString(),
    });

    setFilters({ ...filters, [key]: value });
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-staffs"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            padding: {
              xs: '24px 16px',
              lg: '24px 32px',
            },
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Box
            gap={4}
            display="flex"
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <Typography variant="h2" component="h2">
              Estados
            </Typography>
            <NextLink
              href={Targets.BACKOFFICE_CONF_STATE_CREATE}
              passHref
            >
              <Link marginTop={1}>Adicionar</Link>
            </NextLink>
          </Box>
          <Box
            gap={2}
            display="flex"
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <TextField
              fullWidth
              autoFocus
              name="search"
              margin="normal"
              variant="outlined"
              label="Digite o nome do estado"
              sx={{
                borderWidth: 1,
                backgroundColor:
                  themes.authentication.palette.grey[100],
              }}
              inputProps={{ maxLength: 75 }}
              onChange={event =>
                onChange('filter', event.target.value)
              }
            />
          </Box>
          <TableContainer
            sx={{
              paddingY: 3,
              boxShadow: 'none',
              borderRadius: '6px',
              textAlign: 'center',
              backgroundColor:
                themes.authentication.palette.grey[100],
            }}
          >
            {loading ? (
              <>
                <CircularProgress />
                <Typography
                  component="p"
                  variant="body2"
                  sx={{ textAlign: 'center' }}
                >
                  Carregando estados...
                </Typography>
              </>
            ) : (
              <>
                <Paginator data={states} onChange={onChange} />
                <Divider
                  variant="fullWidth"
                  sx={{ marginX: 2 }}
                />
                <TableConf
                  data={states.results}
                  columns={TableColumnStateList}
                />
                <Divider
                  variant="fullWidth"
                  sx={{ marginX: 2, marginBottom: 2 }}
                />
                <Paginator
                  showLimit
                  data={states}
                  onChange={onChange}
                />
              </>
            )}
          </TableContainer>
        </Box>
      </Box>
    </Grid>
  );
};

export default StateList;
