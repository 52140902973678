import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { UserIdProps, IPhoneInput } from '@2ndmarket/types';

import {
  useMe,
  usePhone,
  updateUserPhone,
} from '@2ndmarket/services';

import {
  Icon,
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
} from '@2ndmarket/components';

import {
  Masks,
  Targets,
  HttpError,
  HttpStatus,
  ReviewStep,
  UserStatuses,
  OnboardingProgress,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import {
  schema,
  defaultValues,
} from '../OnboardingBasic/Phone/formPhone';

const Phone: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const { data: user } = useMe(user_id);
  const { data: phone, mutate: mutatePhone } = usePhone(user.id);

  const {
    reset,
    control,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IPhoneInput) => {
    setIsLoading(true);
    const cleanPhone = data.number.replace(/[\(\)\-\s]/g, '');
    const ddd = cleanPhone.substring(0, 2);
    const number = cleanPhone.substring(2);

    const params = {
      ddi: '55',
      ddd: ddd,
      number: number,
      status: ReviewStep.APPROVED,
    };

    updateUserPhone(user.id, params)
      .then((data: IPhoneInput) => {
        mutatePhone();
        router.push(
          `${Targets.BACKOFFICE_USERS_PHONE}/${user.id}`,
        );
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset({
      number:
        (getValues('number') || phone.ddd + phone.number) ?? '',
    });
  }, [phone, reset, getValues]);

  const SelectStatus = styled(Select)(() => ({
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
  }));

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{ marginBottom: { xs: '5px', sm: 0 } }}
      >
        <InputLabel
          variant="outlined"
          htmlFor="uncontrolled-native"
        >
          Status
        </InputLabel>
        <SelectStatus
          disabled
          fullWidth
          defaultValue={user.status}
        >
          {UserStatuses.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStatus>
      </FormControl>
      {user.onboarding_progress === OnboardingProgress.DIGITAL &&
      phone.number !== '' ? (
        <>
          <Box>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled
                  fullWidth
                  {...field}
                  margin="dense"
                  label="Digite o celular"
                  InputProps={{
                    inputComponent: MaskedInput,
                    value: getValues('number'),
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.PHONE,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          size={20}
                          name="validation"
                          color={
                            themes.authentication.palette.success
                              .main
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.number)}
                  helperText={
                    errors.number && errors.number.message
                  }
                />
              )}
            />
          </Box>
          <Alert
            severity="warning"
            icon={<Icon name="info" size={18} />}
            sx={{
              border: 1,
              borderRadius: '3px',
              marginBottom: '30px',
              borderColor:
                themes.authentication.palette.warning.main,
            }}
          >
            Para alterar o número de telefone entre em contato
            com a equipe de desenvolvimento.
          </Alert>
        </>
      ) : (
        <>
          <Box>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  margin="dense"
                  label="Digite o celular"
                  InputProps={{
                    inputComponent: MaskedInput,
                    value: getValues('number'),
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.PHONE,
                      unmask: true,
                    },
                  }}
                  error={Boolean(errors.number)}
                  helperText={
                    errors.number && errors.number.message
                  }
                />
              )}
            />
          </Box>
          <Box
            marginY={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                paddingX: 8,
                width: {
                  xs: '100%',
                  sm: 172,
                  md: 172,
                  lg: 172,
                },
              }}
            >
              Salvar
            </Button>
          </Box>

          {isLoading && (
            <Backdrop
              open={isLoading}
              sx={{
                right: 0,
                left: 'auto',
                width: '100%',
                zIndex: theme => theme.zIndex.drawer + 1,
                color:
                  themes.authentication.palette.common.white,
              }}
            >
              <MuiLoader loaderState={isLoading} />
            </Backdrop>
          )}

          {showModal && (
            <ModalDialog
              error
              maxWidth="sm"
              open={showModal}
              errorMessage={errorMessage}
              onClose={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Phone;
