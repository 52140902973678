import NextLink from 'next/link';
import { useState } from 'react';

import { useRouter } from 'next/router';
import { Targets } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';

import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Menu from '../Menu';

const DrawerMenu: React.FC = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const toogleDrawerMenu = (open: boolean) => () => {
    setOpen(open);
  };

  const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    ...theme.mixins.toolbar,
    padding: theme.spacing(2, 10, 4, 1),
  }));

  return (
    <Box
      gap={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      alignContent="center"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toogleDrawerMenu(true)}
      >
        <Icon
          name="menu"
          size={20}
          color={themes.authentication.palette.grey[700]}
        />
      </IconButton>
      <Drawer
        open={open}
        anchor="left"
        role="presentation"
        onClose={toogleDrawerMenu(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor:
              themes.authentication.palette.grey[900],
          },
        }}
      >
        <DrawerHeader
          sx={{ width: 315, paddingX: 3, paddingY: 4 }}
        >
          <NextLink href={Targets.BACKOFFICE_DASHBOARD} passHref>
            <Link sx={{ textDecoration: 'none' }}>
              <Icon
                size={28}
                name="bkai-coin"
                color={
                  themes.authentication.palette.primary.main
                }
              />
            </Link>
          </NextLink>
        </DrawerHeader>
        <Menu />
      </Drawer>
    </Box>
  );
};

export default DrawerMenu;
