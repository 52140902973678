import {
  yupPt,
  validateCpf,
  validateCnpj,
  validateName,
} from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  amount: yupPt.string().required(),
  // cpf_cnpj: yupPt.string().required(),
  recipient: yupPt
    .string()
    .test('recipient', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Nome do beneficiário é obrigatório',
        });
      }

      if (!validateName(value)) {
        return createError({
          message:
            'Nome do beneficiário deve conter pelo menos 2 palavras',
        });
      }

      return true;
    }),
  schedule_date: yupPt.string().required(),
});

export const defaultValues = {
  amount: '',
  cpf_cnpj: '',
  recipient: '',
  schedule_date: '',
  pin: '',
};
