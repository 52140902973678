import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { UserIdProps } from '@2ndmarket/types';

import {
  updateDocument,
  useDocument,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
  MuiButtonFile,
  CustomInputFile,
} from '@2ndmarket/components';

import {
  Masks,
  IDocument,
  HttpError,
  HttpStatus,
  UserStatuses,
  documentTypes,
  Documents as DocumentTypes,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

import {
  schema,
  defaultValues,
} from '../OnboardingBasic/Documents/formDocument';

const Documents: React.FC<UserIdProps> = ({ user_id }) => {
  const { data: user } = useMe(user_id);
  const { data: profile } = useProfile(user.id);
  const { data: documents, mutate: mutateDocuments } =
    useDocument(user.id);

  const {
    reset,
    control,
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const documentSelected = useWatch({
    control,
    name: 'type',
  });

  const [nameFileBack] = useState('file_back');
  const [nameFileFront] = useState('file_front');

  const [fileBack, setFileBack] = useState<File>();
  const [fileFront, setFileFront] = useState<File>();

  const [inputFileBack, setInputFileBack] = useState(false);
  const [inputFileFront, setInputFileFront] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dateCreatedAt = dayjs(documents.created_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const dateUpdateAt = dayjs(documents.updated_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const handleInputFileBack = () => {
    setInputFileBack(true);
  };

  const handleInputFileFront = () => {
    setInputFileFront(true);
  };

  const handleFileFront = (file: File) => {
    const inputFront = document.getElementById(
      'file_front',
    ) as HTMLInputElement;

    if (inputFront.name === nameFileFront) {
      errors.file_front = undefined;

      setValue('file_front', file.name);
      setFileFront(file);
    }
  };

  const handleFileBack = (file: File) => {
    const inputBack = document.getElementById(
      'file_back',
    ) as HTMLInputElement;

    if (inputBack.name === nameFileBack) {
      errors.file_back = undefined;

      setValue('file_back', file.name);
      setFileBack(file);
    }
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IDocument) => {
    if (data.file_front == '') {
      return (errors.file_front =
        'Selecione um arquivo de frente' as never);
    } else if (fileFront) {
      data.file_front = fileFront;
    }

    if (data.file_back == '') {
      return (errors.file_back =
        'Selecione um arquivo de verso' as never);
    } else if (fileBack) {
      data.file_back = fileBack;
    }

    if (data.expiration_date) {
      data.expiration_date = data.expiration_date
        .split('/')
        .reverse()
        .join('-');
    }

    setIsLoading(true);
    updateDocument({ ...data }, user_id)
      .then((data: IDocument) => {
        mutateDocuments();
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset({
      type: (getValues('type') || documents.type) ?? '',
      mothers_name:
        (getValues('mothers_name') || documents.mothers_name) ??
        '',
      expiration_date: documents.expiration_date
        ? documents.expiration_date
            .split('-')
            .reverse()
            .join('/')
        : '',
      file_front: documents.file_front ?? '',
      file_back: documents.file_back ?? '',
    });
  }, [reset, documents, getValues]);

  const SelectStatus = styled(Select)(() => ({
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
  }));

  const PaperColumn = styled(Paper)(() => ({
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  }));

  const ButtonFile = styled(Button)(({ theme }) => ({
    width: 207,
    height: 56,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[200],
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  }));

  return (
    <Box
      component="form"
      autoComplete="off"
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{ marginBottom: { xs: '5px', sm: 0 } }}
      >
        <InputLabel
          variant="outlined"
          htmlFor="uncontrolled-native"
        >
          Status
        </InputLabel>
        <SelectStatus
          disabled
          fullWidth
          defaultValue={user.status}
        >
          {UserStatuses.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStatus>
      </FormControl>
      {user.person_type === DocumentTypes.CNPJ && (
        <Controller
          name="contact_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled
              margin="dense"
              label="Responsável"
              value={profile?.contact_name}
              inputProps={{ maxLength: 200 }}
              sx={{ marginBottom: 0 }}
            />
          )}
        />
      )}
      <Box
        gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Box width="100%" gap={1} display="grid">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                margin="dense"
                label="Tipo de documento"
                error={Boolean(errors.type)}
                InputProps={{
                  value: getValues('type'),
                }}
                helperText={errors.type && errors.type.message}
              >
                {documentTypes.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={documents.type === option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {DocumentTypes.CNH == documentSelected && (
            <Controller
              name="expiration_date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  margin="dense"
                  label="Data de expiração"
                  {...register('expiration_date')}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.DATE,
                    },
                  }}
                  error={Boolean(errors.expiration_date)}
                  helperText={
                    errors.expiration_date &&
                    errors.expiration_date.message
                  }
                />
              )}
            />
          )}
        </Box>
        <Box width="100%" gap={1} display="grid">
          <Controller
            name="mothers_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                label="Nome da mãe"
                InputProps={{
                  value: getValues('mothers_name'),
                  inputProps: { maxLength: 200 },
                }}
                error={Boolean(errors.mothers_name)}
                helperText={
                  errors.mothers_name &&
                  errors.mothers_name.message
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box
        gap={2}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        {documents.file_front && !inputFileFront ? (
          <Box>
            <Typography component="span" variant="body1">
              Frente: Foto e assinatura
            </Typography>
            <Stack
              gap={2}
              spacing={1}
              marginTop={2}
              direction="row"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '56px',
                  borderRadius: '3px',
                  backgroundColor:
                    themes.authentication.palette.grey[200],
                }}
              >
                <MuiButtonFile
                  fileName={documents.file_front}
                  title="Ver arquivo"
                  userId={user_id}
                />
              </Box>
              <ButtonFile
                color="secondary"
                variant="contained"
                onClick={handleInputFileFront}
              >
                Atualizar
              </ButtonFile>
            </Stack>
          </Box>
        ) : (
          <CustomInputFile
            id="file_front"
            nameFile={nameFileFront}
            label="Frente: Foto e assinatura"
            error={Boolean(errors.file_front)}
            helperText={errors.file_front?.message}
            onChange={handleFileFront || ''}
          />
        )}

        {documents.file_back && !inputFileBack ? (
          <Box>
            <Typography component="span" variant="body1">
              Verso: Dados
            </Typography>
            <Stack
              gap={2}
              spacing={1}
              marginTop={2}
              direction="row"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '56px',
                  borderRadius: '3px',
                  backgroundColor:
                    themes.authentication.palette.grey[200],
                }}
              >
                <MuiButtonFile
                  fileName={documents.file_back}
                  title="Ver arquivo"
                  userId={user_id}
                />
              </Box>
              <ButtonFile
                color="secondary"
                variant="contained"
                onClick={handleInputFileBack}
              >
                Atualizar
              </ButtonFile>
            </Stack>
          </Box>
        ) : (
          <CustomInputFile
            id="file_back"
            label="Verso: Dados"
            nameFile={nameFileBack}
            error={Boolean(errors.file_back)}
            helperText={errors.file_back?.message}
            onChange={handleFileBack || ''}
          />
        )}
      </Box>
      <Stack
        width="100%"
        spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        direction={{
          xs: 'column',
          sm: 'row',
          md: 'row',
          lg: 'row',
        }}
      >
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Cadastro em"
            value={dateCreatedAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Atualizado em"
            value={dateUpdateAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
      </Stack>
      <Box
        marginY={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Salvar
        </Button>
      </Box>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default Documents;
