import { yupPt } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  name: yupPt.string().required(),
  state_id: yupPt.number().required(),
});

const defaultValues = {
  name: '',
  state_id: 0,
};

export { schema, defaultValues };
