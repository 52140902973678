import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { IProfile, UserIdProps } from '@2ndmarket/types';

import {
  themes,
  MuiLoader,
  ModalDialog,
  MaskedInput,
} from '@2ndmarket/components';

import {
  updateProfile,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import {
  RegisterDocuments,
  UserStatuses,
  legalPerson,
  IProfilePJ,
  HttpStatus,
  HttpError,
  Documents,
  Targets,
  Masks,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

import { schemaPJ, defaultValuesPJ } from './formProfile';

const ProfilePJ: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const { data: profile, mutate: mutateUserProfile } =
    useProfile(user_id);

  const { data: user } = useMe(user_id);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let defaultValues = defaultValuesPJ;
  const { setError, setValue, formState, handleSubmit } =
    useForm({
      defaultValues,
      resolver: yupResolver(schemaPJ),
    });

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (profile.legal_nature != null) {
      setValue('legal_nature', profile.legal_nature);
    }
  }, [profile, setValue]);

  const dateCreatedAt = dayjs(user.created_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const dateUpdateAt = dayjs(user.updated_at)
    .locale(ptBR)
    .format('DD/MM/YYYY HH:mm:ss');

  const handleError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorMessage(error.error);
      setShowModal(true);
    }
  };

  const onSubmit = (data: IProfilePJ) => {
    setIsLoading(true);
    updateProfile(
      {
        ...data,
        birthdate: data.birthdate.split('/').reverse().join('-'),
      },
      user_id,
    )
      .then((data: IProfile) => {
        mutateUserProfile(data);
        router.push(
          `${Targets.BACKOFFICE_USERS_PROFILE}/${user_id}`,
        );
      })
      .catch((error: HttpError) => {
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const SelectStatus = styled(Select)(() => ({
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
  }));

  const PaperColumn = styled(Paper)(() => ({
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  }));

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <FormControl
        fullWidth
        sx={{ marginBottom: { xs: '5px', sm: 0 } }}
      >
        <InputLabel
          variant="outlined"
          htmlFor="uncontrolled-native"
        >
          Status
        </InputLabel>
        <SelectStatus
          disabled
          fullWidth
          defaultValue={user.status}
        >
          {UserStatuses.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStatus>
      </FormControl>
      <Box
        gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Box width="100%" gap={1} display="grid">
          <TextField
            disabled
            fullWidth
            name="name"
            margin="dense"
            value={user.name}
            variant="outlined"
            autoComplete="off"
            label="Razão social"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            select
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Tipo de cadastro"
            defaultValue={Documents.CNPJ}
          >
            {RegisterDocuments.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Nome fantasia"
            value={profile.fantasy_name}
            inputProps={{ maxLength: 200 }}
            error={Boolean(formState.errors.fantasy_name)}
            helperText={
              formState.errors.fantasy_name &&
              formState.errors.fantasy_name.message
            }
          />
          <TextField
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="CNAE principal"
            value={profile.primary_cnae}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                inputMode: 'numeric',
                mask: Masks.CNAE,
                unmask: true,
              },
            }}
            error={Boolean(formState.errors.primary_cnae)}
            helperText={
              formState.errors.primary_cnae &&
              formState.errors.primary_cnae.message
            }
          />
        </Box>
        <Box width="100%" gap={1} display="grid">
          <TextField
            disabled
            fullWidth
            name="email"
            margin="dense"
            label="E-mail"
            value={user.email}
            variant="outlined"
            autoComplete="off"
            inputProps={{
              style: { textTransform: 'lowercase' },
              maxLength: 75,
            }}
          />
          <TextField
            disabled
            fullWidth
            margin="dense"
            name="cpf_cnpj"
            variant="outlined"
            label="N.º do CNPJ"
            value={user.cpf_cnpj}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                inputMode: 'numeric',
                mask: Masks.CNPJ,
                unmask: true,
              },
            }}
          />
          <TextField
            select
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Natureza legal"
            defaultValue={profile.legal_nature}
            error={Boolean(formState.errors.legal_nature)}
            helperText={
              formState.errors.legal_nature &&
              formState.errors.legal_nature.message
            }
          >
            {legalPerson.map((option, index: number) => (
              <MenuItem
                key={index}
                value={option.label}
                selected={profile.legal_nature === option.label}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box lineHeight={2}>
        <Typography component="strong" variant="subtitle1">
          Responsável
        </Typography>
        <Typography component="p" variant="body1">
          Preencha os dados do responsável pela empresa que será
          o gestor da conta no ecossistema.
        </Typography>
      </Box>
      <Box
        gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Box width="100%" gap={1} display="grid">
          <TextField
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Nome e sobrenome"
            value={profile.contact_name}
            inputProps={{ maxLength: 200 }}
            error={Boolean(formState.errors.contact_name)}
            helperText={
              formState.errors.contact_name &&
              formState.errors.contact_name.message
            }
          />
          <TextField
            disabled
            fullWidth
            label="Cargo"
            margin="dense"
            variant="outlined"
            value={profile.company_title}
            inputProps={{ maxLength: 200 }}
            error={Boolean(formState.errors.company_title)}
            helperText={
              formState.errors.company_title &&
              formState.errors.company_title.message
            }
          />
        </Box>
        <Box width="100%" gap={1} display="grid">
          <TextField
            disabled
            fullWidth
            label="CPF"
            margin="dense"
            variant="outlined"
            value={profile.contact_cpf}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                inputMode: 'numeric',
                mask: Masks.CPF,
                unmask: true,
              },
            }}
            error={Boolean(formState.errors.contact_cpf)}
            helperText={
              formState.errors.contact_cpf &&
              formState.errors.contact_cpf.message
            }
          />
          <TextField
            disabled
            fullWidth
            margin="dense"
            variant="outlined"
            label="Data de nascimento"
            value={profile.birthdate}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                inputMode: 'numeric',
                mask: Masks.DATE,
                unmask: true,
              },
            }}
            error={Boolean(formState.errors.birthdate)}
            helperText={
              formState.errors.birthdate &&
              formState.errors.birthdate.message
            }
          />
        </Box>
      </Box>
      <Stack
        spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        width="100%"
        direction={{
          xs: 'column',
          sm: 'row',
          md: 'row',
          lg: 'row',
        }}
      >
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Cadastro em"
            value={dateCreatedAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
        <PaperColumn
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            },
          }}
        >
          <TextField
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            label="Atualizado em"
            value={dateUpdateAt}
            InputProps={{
              inputComponent: MaskedInput,
              inputProps: {
                mask: Masks.DATE_TIME,
                inputMode: 'numeric',
                unmask: true,
              },
            }}
            sx={{ width: '100%' }}
          />
        </PaperColumn>
      </Stack>
      <Box
        marginY={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          disabled
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Salvar
        </Button>
      </Box>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default ProfilePJ;
