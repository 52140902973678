import { useEffect, useState } from 'react';

import { WalletCryptoProps } from '@2ndmarket/types';
import { themes, Icon } from '@2ndmarket/components';
import { useMe, useCryptoWallet } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const AccountWalletCripto: React.FC<WalletCryptoProps> = ({
  user_id,
  coin,
}) => {
  const { data: user } = useMe(user_id);
  const { data: wallet } = useCryptoWallet(coin, user.id);

  const [, setCopiedData] = useState<string>('');

  useEffect(() => {
    if (!wallet) return;
  }, [wallet]);

  const copyData = (id: string, data: { address: string }) => {
    const copied = `${data.address}`;
    navigator.clipboard.writeText(copied);
    setCopiedData(copied);
  };

  return (
    <Box>
      <Box
        marginTop={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="body1" component="span">
          Carteiras
        </Typography>
      </Box>
      {wallet?.map((item, index) => (
        <Box
          key={index}
          marginTop={2}
          display="flex"
          borderRadius="3px"
          padding="11px 20px"
          justifyContent="space-between"
          sx={{ backgroundColor: 'white' }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            gap={{ xs: '10px', sm: '20px', lg: '30px' }}
          >
            <Typography
              variant="body1"
              component="span"
              color={
                themes.authentication.palette.text.secondary
              }
            >
              {item.name || 'Carteira Principal'}
            </Typography>
          </Box>
          <Box>
            <Typography
              marginRight={1}
              variant="body2"
              component="span"
              color={
                themes.authentication.palette.text.secondary
              }
            >
              {item.address.substr(0, 10)}...
              {item.address.substr(25)}
            </Typography>
            <IconButton
              onClick={() => copyData('balance-bank', item)}
            >
              <Icon
                name="copy"
                size={20}
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AccountWalletCripto;
