import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { UserIdProps } from '@2ndmarket/types';
import { useResponsive } from '@2ndmarket/hooks';

import {
  Icon,
  themes,
  ModalDialog,
} from '@2ndmarket/components';

import {
  Targets,
  ReviewStep,
  SubMenuOnboarding,
  HttpStatus,
  ExternalUrls,
} from '@2ndmarket/helpers';

import OnboardingBasic from './OnboardingBasic';
import { Header, Navigation } from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const UserOnboarding: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const { data: user, loading, error } = useMe(user_id);
  const [showError, setShowError] = useState(false);

  const mediumDow = useResponsive('down', 'md', 'xs');

  const handleLinkPage = (value: string) => {
    if (value == SubMenuOnboarding.REGISTER) {
      router.push(`${Targets.BACKOFFICE_USERS_CREATE}`);
    }
  };

  const handleReturn = () => {
    setShowError(false);
    router.push(`${Targets.BACKOFFICE_USERS_LIST}`);
  };

  let countApproved = 0;
  useEffect(() => {
    if (
      error &&
      error.status == HttpStatus.ClientErrorNotFound
    ) {
      setShowError(true);
    }

    if (user.onboarding_basic != null) {
      for (const [key, value] of Object.entries(
        user.onboarding_basic,
      )) {
        if (key != 'status') {
          if (value == ReviewStep.APPROVED) {
            countApproved++;
          }
        }
      }
    }

    if (countApproved == 5) {
      if (user.onboarding_basic.status === ReviewStep.APPROVED) {
        router.push(
          `${Targets.BACKOFFICE_USERS_PROFILE}/${user.id}`,
        );
      }
    }
  }, [user, router, countApproved, error]);

  const SubMenu = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '54px',
    borderRadius: '6px',
    border: 'none !important',
    justifyContent: 'space-between',
    backgroundColor: `${themes.authentication.palette.grey[100]} !important`,
    '&:hover': {
      border: 'none',
      cursor: 'default',
      backgroundColor: themes.authentication.palette.grey[100],
    },
  }));

  const SelectMobile = styled(Select)(({ theme }) => ({
    width: '100%',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: 'white',
    '.MuiSelect-select': {
      color: themes.authentication.palette.primary.main,
    },
  }));

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      {user.onboarding_basic == null && (
        <Box
          width="100%"
          height="90%"
          display="flex"
          className="container-users"
        >
          <Navigation />
          <Box
            paddingY={3}
            paddingX={4}
            sx={{
              padding: {
                xs: '24px 16px',
                lg: '24px 32px',
              },
              overflowY: 'scroll',
              width: {
                xs: '100%',
                sm: '100%',
                md: '80%',
                lg: '80%',
              },
            }}
          >
            <Box
              gap={3}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              sx={{
                padding: '24px',
                borderRadius: '6px',
                backgroundColor: 'white',
              }}
            >
              <Typography
                component="p"
                variant="body1"
                textAlign="center"
              >
                Cadastro do usuário temporariamente com pendencia
                de dados, e estamos verificando algumas
                informações.
                <br /> Dúvidas, por favor entre em contato com
                nossa equipe.
              </Typography>
              <Button
                color="secondary"
                component={Link}
                variant="contained"
                sx={{ width: 250 }}
                href={`mailto:${ExternalUrls.EMAIL_SUPPORT}?subject=Suporte - Preciso de ajuda`}
              >
                <Typography>Falar com suporte</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {!loading && user.onboarding_basic != null && (
        <Box
          width="100%"
          height="90%"
          display="flex"
          className="container-users"
        >
          <Navigation />
          <Box
            paddingY={3}
            paddingX={4}
            sx={{
              padding: {
                xs: '24px 16px',
                lg: '24px 32px',
              },
              overflowY: 'scroll',
              width: {
                xs: '100%',
                sm: '100%',
                md: '80%',
                lg: '80%',
              },
            }}
          >
            <Box
              gap={4}
              display="flex"
              marginBottom={1}
              flexDirection="row"
              alignItems="center"
              alignContent="center"
            >
              <IconButton
                onClick={() => router.back()}
                sx={{ ml: -1 }}
              >
                <Icon
                  rotate="180deg"
                  name="arrow-alternative"
                  color={themes.authentication.palette.grey[300]}
                />
              </IconButton>
              <Typography variant="h2" component="h2">
                {user.name}
              </Typography>
            </Box>
            <Box
              gap={2}
              paddingX={8}
              display="flex"
              marginBottom={{ xs: 2, sm: 2, md: 4, lg: 4 }}
              flexDirection="row"
              alignItems="center"
              alignContent="center"
            >
              <Badge
                variant="dot"
                sx={{
                  backgroundColor:
                    themes.authentication.palette.warning.main,
                  borderRadius: '50%',
                  height: '6px',
                  width: '6px',
                }}
              />
              <Typography component="p" variant="body1">
                Pendente
              </Typography>
            </Box>
            <Box
              gap={3}
              width="100%"
              display="flex"
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                },
              }}
            >
              {mediumDow ? (
                <Box width="100%">
                  <SelectMobile
                    value={SubMenuOnboarding.ONBOARDING}
                    color="primary"
                    onChange={event =>
                      handleLinkPage(
                        event.target.value as string,
                      )
                    }
                  >
                    <MenuItem value={SubMenuOnboarding.REGISTER}>
                      Cadastro
                    </MenuItem>
                    <MenuItem
                      value={SubMenuOnboarding.ONBOARDING}
                      color="primary"
                    >
                      Onboarding
                    </MenuItem>
                  </SelectMobile>
                </Box>
              ) : (
                <Box
                  gap={1}
                  display="flex"
                  flexDirection="column"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '20%',
                      lg: '20%',
                    },
                  }}
                >
                  <SubMenu
                    variant="outlined"
                    sx={{
                      color:
                        themes.authentication.palette.grey[300],
                    }}
                    endIcon={
                      <Icon
                        size={12}
                        name="arrow"
                        rotate="-90deg"
                        color={
                          themes.authentication.palette.grey[300]
                        }
                      />
                    }
                  >
                    Cadastro
                  </SubMenu>
                  <SubMenu
                    variant="outlined"
                    endIcon={
                      <Icon
                        size={12}
                        name="arrow"
                        rotate="-90deg"
                        color={
                          themes.authentication.palette.grey[300]
                        }
                      />
                    }
                  >
                    Onboarding
                  </SubMenu>
                </Box>
              )}
              <Box
                gap={3}
                display="flex"
                flexDirection="column"
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '80%',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  component="h4"
                  color={themes.authentication.palette.grey[600]}
                >
                  Onboarding
                </Typography>
                <OnboardingBasic user={user} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showError && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showError}
          errorMessage={error.error}
          errorTitle="Usuário não encontrado"
          onClose={handleReturn}
        />
      )}
    </Grid>
  );
};

export default UserOnboarding;
