import { useResponsive } from '@2ndmarket/hooks';
import Box from '@mui/material/Box';
import Menu from '../Menu';

const Navigation: React.FC = () => {
  const mediumUp = useResponsive('up', 'md', 'sm');

  return mediumUp ? (
    <Box
      gap={2}
      width="20%"
      height="100%"
      paddingX="20px"
      paddingTop={3}
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      <Menu />
    </Box>
  ) : null;
};

export default Navigation;
