import { useState, useEffect } from 'react';

import { useStaffMe, userLogout } from '@2ndmarket/services';
import { Icon, themes, MuiLoader } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const DrawerProfile: React.FC = () => {
  const { data: userStaff } = useStaffMe();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!userStaff) {
      return;
    }
  }, [userStaff]);

  const toogleDrawerProfile = (open: boolean) => () => {
    setOpen(open);
  };

  const getInitials = (name: any): string => {
    let initials: any = '';
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
  };

  const logout = () => {
    setLoader(true);

    userLogout();
    return (window.location.href = '/login');
  };

  const name = (userStaff && userStaff.name) || '';
  const currentName =
    name.length > 13 ? `${name.substring(0, 13)}...` : name;

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    ...theme.mixins.toolbar,
    padding: theme.spacing(2, 10, 4, 3),
  }));

  return (
    <>
      <Chip
        avatar={
          <Avatar
            sx={{
              width: '40px !important',
              height: '40px !important',
              marginX: '0 !important',
              bgcolor:
                themes.authentication.palette.primary.main,
            }}
          >
            <Typography
              variant="body2"
              component="p"
              color={themes.authentication.palette.common.white}
            >
              {userStaff && getInitials(userStaff.name)}
            </Typography>
          </Avatar>
        }
        label={`Olá, ${currentName}`}
        onClick={toogleDrawerProfile(true)}
        sx={{
          gap: 0,
          height: 56,
          width: 170,
          paddingX: 1,
          borderRadius: 2,
          backgroundColor:
            themes.authentication.palette.grey[400],
          '&:hover': {
            backgroundColor:
              themes.authentication.palette.primary.light,
          },
        }}
      />
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={toogleDrawerProfile(false)}
        sx={{
          '.MuiPaper-root': {
            backgroundColor:
              themes.authentication.palette.common.white,
          },
        }}
      >
        <DrawerHeader
          sx={{
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 415,
            },
          }}
        >
          <Stack
            gap={6}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Perfil
            </Typography>
          </Stack>
        </DrawerHeader>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Avatar
            sx={{
              width: 110,
              height: 110,
              marginLeft: '0 !important',
            }}
          >
            <Typography
              component="p"
              variant="subtitle1"
              color={themes.authentication.palette.primary.main}
            >
              {userStaff && getInitials(userStaff.name)}
            </Typography>
          </Avatar>
          <Typography
            component="p"
            marginTop={2}
            variant="subtitle1"
          >
            Olá, {userStaff && userStaff.name}!
          </Typography>
          <Typography
            component="p"
            variant="body2"
            marginBottom={4}
            color={themes.authentication.palette.grey[300]}
          >
            {userStaff && userStaff.email}
          </Typography>
        </Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Button
            type="button"
            color="secondary"
            variant="contained"
            onClick={() => logout()}
            sx={{
              marginX: 3,
              marginBottom: 4,
              boxShadow: 'none',
              width: 'calc(100% - 48px)',
            }}
          >
            Sair
          </Button>
        </Box>
      </Drawer>

      {loader && (
        <Backdrop
          open={loader}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={loader} />
        </Backdrop>
      )}
    </>
  );
};

export default DrawerProfile;
