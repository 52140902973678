import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { UserIdProps } from '@2ndmarket/types';

import {
  useMe,
  usePin,
  userDeletePin,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  ModalDialog,
} from '@2ndmarket/components';

import {
  HttpError,
  HttpStatus,
  ReviewStep,
  Targets,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import { schema, defaultValues } from './formPassword';

const Password: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const { data: user } = useMe(user_id);
  const { data: pin, mutate } = usePin(user.id);

  const { reset, setError } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleClearPassword = () => {
    setShowModal(false);
    reset({
      password: '',
    });

    onSubmit();
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    userDeletePin(user.id)
      .then(() => {
        mutate({ status: ReviewStep.NONE });
        router.push(
          `${Targets.BACKOFFICE_USERS_PASSWORD}/${user.id}`,
        );
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset({
      password: '',
    });
  }, [reset]);

  return (
    <Box
      gap={1}
      display="flex"
      flexDirection="column"
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      {pin.status === ReviewStep.APPROVED ? (
        <>
          <Typography component="p" variant="body1">
            O usuário possui senha cadastrada, para permitir o
            cadastro de uma nova senha, apague a senha atual.
          </Typography>
          <Box display="flex">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowModal(true);
              }}
              sx={{ width: '140px', height: '56px' }}
            >
              Apagar
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography component="p" variant="body1">
            O usuário ainda não possui senha cadastrada e deve
            realizar o cadastro pelo painel do cliente.
          </Typography>
          <Box display="flex">
            <Button
              disabled
              color="secondary"
              variant="contained"
              sx={{ width: '140px', height: '56px' }}
            >
              Apagar
            </Button>
          </Box>
        </>
      )}

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          maxWidth="sm"
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <Box
            sx={{
              gap: '16px',
              display: 'flex',
              padding: '10px 20px',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              color={themes.authentication.palette.grey[700]}
            >
              Apagando senha...
            </Typography>
            <Typography
              component="p"
              variant="body1"
              color={themes.authentication.palette.grey[600]}
              marginBottom={4}
            >
              Você realmente deseja apagar a senha do usuário?
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Não
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                handleClearPassword();
              }}
              sx={{
                '&:hover': {
                  color:
                    themes.authentication.palette.primary.main,
                  backgroundColor:
                    themes.authentication.palette.grey[200],
                },
              }}
            >
              Sim
            </Button>
          </Box>
        </ModalDialog>
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
        />
      )}
    </Box>
  );
};

export default Password;
