import { useContext, useEffect, useState } from 'react';

import { UserIdProps } from '@2ndmarket/types';
import { themes, Icon } from '@2ndmarket/components';
import { useMe, useBalance } from '@2ndmarket/services';
import { AppContext, formatBRL } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

const BalanceWalletFiat: React.FC<UserIdProps> = ({
  user_id,
}) => {
  const { data: user } = useMe(user_id);
  const { data: balance } = useBalance(user.id);

  const context = useContext(AppContext);

  const [showBalance, setShowBalance] = useState(true);

  const handleBalance = () => {
    context?.setShowBalance(!context.showBalance);

    localStorage.setItem(
      '@2ndMarket:showBalance',
      (!context?.showBalance).toString(),
    );

    setShowBalance(!showBalance);
  };

  useEffect(() => {
    if (!balance) {
      return;
    }
  }, [balance]);

  return (
    <Box
      height={70}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding="14.5px 10px 14.5px 20px"
      sx={{
        borderRadius: '3px',
        backgroundColor: 'white',
      }}
    >
      <FormControl variant="outlined" sx={{ width: '50%' }}>
        <InputLabel
          variant="outlined"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            transform: 'none',
            position: 'relative',
          }}
        >
          Saldo
          <Typography
            component="p"
            variant="body1"
            color={themes.authentication.palette.primary.main}
          >
            {showBalance ? formatBRL(balance.free_balance) : '*'}
          </Typography>
        </InputLabel>
      </FormControl>
      <IconButton onClick={handleBalance}>
        {showBalance ? (
          <Icon
            size={20}
            name="view"
            color={themes.authentication.palette.grey[300]}
          />
        ) : (
          <Icon
            size={20}
            name="hide"
            color={themes.authentication.palette.grey[300]}
          />
        )}
      </IconButton>
    </Box>
  );
};

export default BalanceWalletFiat;
