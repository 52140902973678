import * as yup from 'yup';

export const defaultValues = {
  name: '',
  description: '',
};

const message = 'Campo obrigatório';

export const schema = yup.object().shape({
  name: yup.string().required(message),
  description: yup.string().required(message),
});
