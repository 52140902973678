import NextLink from 'next/link';
import { useEffect, useState } from 'react';

import { UserIdProps } from '@2ndmarket/types';
import { themes, Icon } from '@2ndmarket/components';
import { useMe, useWallet } from '@2ndmarket/services';

import {
  Targets,
  formatCpfCnpj,
  WalletTransaction,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const AccountWalletFiat: React.FC<UserIdProps> = ({
  user_id,
}) => {
  const { data: user } = useMe(user_id);
  const { data: wallet } = useWallet(user.id);

  const [, setCopiedData] = useState<string>('');

  useEffect(() => {
    if (!wallet) return;
  }, [wallet]);

  const copyBalance = [
    {
      title: 'Banco',
      value: wallet?.bank_code,
    },
    {
      title: 'Ag.',
      value: wallet?.bank_branch,
    },
    {
      title: 'Conta',
      value: wallet?.bank_account_number,
    },
    {
      title: 'CPF',
      value: formatCpfCnpj(user.cpf_cnpj),
    },
  ];

  const copyData = (id: string, data: any) => {
    const copied = `Banco: ${data[0].value} \nAg.: ${data[1].value} \nConta: ${data[2].value} \nCPF: ${data[3].value}`;
    navigator.clipboard.writeText(copied);
    setCopiedData(copied);
  };

  const cards = [
    {
      icon: 'send',
      title: 'Transferir',
      link: `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user.id}/${WalletTransaction.TRANSFER}`,
    },
    {
      icon: 'receive',
      title: 'Depositar',
      link: `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user.id}/${WalletTransaction.DEPOSIT}`,
    },
    {
      icon: 'boleto',
      title: 'Boleto',
      link: `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user.id}/${WalletTransaction.TICKET}`,
    },
  ];

  return (
    <Box>
      <Box
        marginTop={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="body1" component="span">
          Conta &nbsp;
        </Typography>
        <Tooltip
          title="Esta conta só pode receber depósitos de mesma titularidade."
          placement="top-start"
        >
          <IconButton sx={{ padding: 0 }}>
            <Icon
              size={16}
              name="info"
              color={themes.authentication.palette.grey[300]}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        marginTop={2}
        display="flex"
        borderRadius="3px"
        padding="11px 20px"
        justifyContent="space-between"
        sx={{ backgroundColor: 'white' }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          gap={{ xs: '10px', sm: '20px', lg: '30px' }}
        >
          {copyBalance.map((item, index) => (
            <Box key={index}>
              <Typography
                variant="body2"
                component="span"
                color={
                  themes.authentication.palette.text.disabled
                }
              >
                {item.title}
              </Typography>
              <Typography
                marginLeft={1}
                variant="body2"
                component="span"
                color={
                  themes.authentication.palette.text.primary
                }
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <IconButton
            onClick={() => copyData('balance-bank', copyBalance)}
          >
            <Icon
              name="copy"
              size={20}
              color={themes.authentication.palette.grey[300]}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        gap="12px"
        display="flex"
        marginTop="10px"
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {cards.map((item, index) => (
          <NextLink href={item.link} passHref key={index}>
            <Link
              sx={{
                width: {
                  xs: '100%',
                  sm: '50%',
                  lg: '33%',
                },
                height: 80,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Card
                key={index}
                sx={{
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow:
                      '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
                  },
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar>
                      <Icon
                        size={20}
                        name={item.icon}
                        color={
                          themes.authentication.palette.primary
                            .main
                        }
                      />
                    </Avatar>
                  }
                  title={item.title}
                />
              </Card>
            </Link>
          </NextLink>
        ))}
      </Box>
    </Box>
  );
};

export default AccountWalletFiat;
