import { unMask } from 'remask';
import { ChangeEvent, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { usePrice, sendBtcFind } from '@2ndmarket/services';

import {
  HttpError,
  HttpStatus,
  formatBRL,
  toBitcoinNumber,
} from '@2ndmarket/helpers';

import {
  Icon,
  ModalDialog,
  MuiLoader,
  themes,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';

import { schema, defaultValues } from './formReview';

interface ReviewProps {
  crypto: string;
  user_id: number;
  onRequestPin: (result: {}) => void;
  setStepReview: (value: boolean) => void;
  setStepTransfer: (value: boolean) => void;
}

const StepReview: React.FC<ReviewProps> = ({
  crypto,
  user_id,
  onRequestPin,
  setStepReview,
  setStepTransfer,
}) => {
  const [cpf_cnpj, setCpf_cnpj] = useState('');
  const [recipient, setRecipient] = useState('');

  const [amount, setAmount] = useState<string>('');
  const [amountComma, setAmountComma] = useState<string>('');

  const { data: mask } = usePrice(crypto);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [result, setResult] = useState({});

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onChangeAmount = (value: string) => {
    let cleaned = value
      .replace(',', '.')
      .replace(/[^0-9.]/g, '');

    const decimal = cleaned.split('.').length - 1;
    if (decimal > 1) {
      cleaned = cleaned.slice(0, cleaned.lastIndexOf('.'));
    }

    setAmountComma(cleaned.replace('.', ','));
    setAmount(cleaned);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const original = event.target.value;
    setCpf_cnpj(original);
    setValue('cpf_cnpj', original);
  };

  const handleReturnReview = () => {
    setResult({});

    setStepReview(false);
    setStepTransfer(true);
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setErrorModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const onRecipient = (data: string) => {
    const destination = data.replace(/[^\d]/g, '');
    setIsLoading(true);

    sendBtcFind(destination, crypto.toUpperCase())
      .then(response => {
        setRecipient(response.name);
        setIsLoading(false);
      })
      .catch(error => {
        handleErrors(error);
        setIsLoading(false);
      });
  };

  const onSubmitPin = (data: {
    amount: string;
    cpf_cnpj: string;
    recipient: string;
  }) => {
    const result = {
      amount_cripto: toBitcoinNumber(amountComma),
      amount_fiat: Number(amount) * mask.value,
      cpf_cnpj: unMask(data.cpf_cnpj),
      recipient: data.recipient,
    };

    setResult(data);
    onRequestPin(result);
  };

  useEffect(() => {
    setValue('amount', amount ?? '');
    setValue('cpf_cnpj', cpf_cnpj ?? '');
    setValue('recipient', recipient ?? '');
  }, [setValue, amount, cpf_cnpj, recipient]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box
      gap={2}
      display="flex"
      component="form"
      autoComplete="off"
      flexDirection="column"
      onSubmit={handleSubmit(onSubmitPin)}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Box
        gap={2}
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', lg: 'row' }}
      >
        <Box width={{ xs: '100%', lg: '50%' }}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Valor"
                variant="outlined"
                autoComplete="off"
                value={amountComma}
                error={Boolean(errors.amount)}
                helperText={
                  errors.amount && errors.amount.message
                }
                onChange={(
                  event: ChangeEvent<HTMLInputElement>,
                ) => {
                  onChangeAmount(event.target.value);
                }}
                InputProps={{
                  inputProps: { inputMode: 'numeric' },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        '.MuiTypography-root': {
                          color: 'grey.300',
                          fontSize: '14px',
                        },
                      }}
                    >
                      {`≈ ${formatBRL(
                        Number(amount) * mask.value,
                      )}`}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box
          width={{ xs: '100%', lg: '50%' }}
          display="flex"
          gap={1}
        >
          <Controller
            name="cpf_cnpj"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                value={cpf_cnpj}
                variant="outlined"
                autoComplete="off"
                label="CPF, CNPJ ou Endereço"
                error={Boolean(errors.cpf_cnpj)}
                InputProps={{ inputProps: { maxLength: 60 } }}
                helperText={
                  errors.cpf_cnpj && errors.cpf_cnpj.message
                }
                onChange={onChange}
              />
            )}
          />
          <Button
            fullWidth
            type="button"
            color="secondary"
            variant="contained"
            onClick={() => onRecipient(cpf_cnpj)}
            sx={{ height: '55px', width: '56px' }}
          >
            <Icon size={20} name="search" />
          </Button>
        </Box>
      </Box>
      <Box width="100%">
        <Controller
          name="recipient"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={recipient}
              variant="outlined"
              autoComplete="off"
              label="Destinatário"
              error={Boolean(errors.recipient)}
              helperText={
                errors.recipient && errors.recipient.message
              }
              onChange={event => {
                setRecipient(event.target.value);
              }}
            />
          )}
        />
      </Box>
      <Box
        paddingY={2}
        marginTop={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
        }}
      >
        <Typography variant="body1" component="p">
          Tarifa
        </Typography>
        <Typography
          component="p"
          variant="body1"
          color="primary.main"
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          Gratuita
          <IconButton onClick={() => setShowModal(true)}>
            <Icon
              size={14}
              name="info"
              color={themes.authentication.palette.text.disabled}
            />
          </IconButton>
        </Typography>
      </Box>
      <Box
        marginTop={3}
        display="flex"
        justifyContent="space-between"
      >
        <ButtonReturn component="a" onClick={handleReturnReview}>
          Voltar
        </ButtonReturn>
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{ paddingX: 8, width: 172 }}
        >
          Continuar
        </Button>
      </Box>

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorTitle="Transferência gratuita"
          errorMessage="Aqui na Bank.ai as transferências cripto entre contas são totalmente gratuitas! Isso mesmo, sem nenhum custo de rede."
          onClose={() => setShowModal(false)}
        />
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorTitle="Erro"
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
        />
      )}

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}
    </Box>
  );
};

export default StepReview;
