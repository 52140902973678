import { useMe } from '@2ndmarket/services';
import { themes } from '@2ndmarket/components';
import { IUser, UserIdProps } from '@2ndmarket/types';
import {
  OnboardingProgress,
  ReviewTitle,
  ReviewStep,
  Status,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const Bullet: React.FC<UserIdProps> = ({ user_id }) => {
  const { data: user, loading } = useMe(user_id);

  const convert = (Color: any) => {
    switch (Color) {
      case Status.PENDING: {
        return themes.authentication.palette.warning.main;
      }
      case Status.REJECTED:
      case Status.BLOCKED: {
        return themes.authentication.palette.error.main;
      }
      case Status.APPROVED: {
        return themes.authentication.palette.primary.main;
      }
      case Status.NONE: {
        return themes.authentication.palette.grey[300];
      }
    }
  };

  const ToolTip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ),
  )({
    ['&.tooltip-default .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.NONE),
    },
    ['&.tooltip-waring .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.PENDING),
    },
    ['&.tooltip-error .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.BLOCKED),
    },
    ['&.tooltip-primary .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.APPROVED),
    },
  });

  const handleBullet = (value: string, user: IUser) => {
    if (user.onboarding_progress == OnboardingProgress.NEWUSER) {
      if (user.onboarding_basic != null) {
        for (const [key, value] of Object.entries(
          user.onboarding_basic,
        )) {
          if (value == ReviewStep.REFUSED) {
            return (
              <>
                <ToolTip
                  placement="top"
                  className="tooltip-default"
                  title={ReviewTitle.REFUSED}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      backgroundColor: convert(Status.NONE),
                      borderRadius: '50%',
                      height: '8px',
                      width: '8px',
                    }}
                  />
                </ToolTip>
                <Typography component="p" variant="body1">
                  {ReviewTitle.REFUSED}
                </Typography>
              </>
            );
          }
          if (value == ReviewStep.NONE) {
            return (
              <>
                <ToolTip
                  placement="top"
                  className="tooltip-default"
                  title={ReviewTitle.PENDING}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      backgroundColor: convert(Status.NONE),
                      borderRadius: '50%',
                      height: '8px',
                      width: '8px',
                    }}
                  />
                </ToolTip>
                <Typography component="p" variant="body1">
                  {ReviewTitle.PENDING}
                </Typography>
              </>
            );
          }
          if (value == ReviewStep.RECEIVED) {
            return (
              <>
                <ToolTip
                  placement="top"
                  className="tooltip-waring"
                  title={ReviewTitle.ANALYZING}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      backgroundColor: convert(Status.PENDING),
                      borderRadius: '50%',
                      height: '8px',
                      width: '8px',
                    }}
                  />
                </ToolTip>
                <Typography component="p" variant="body1">
                  {ReviewTitle.ANALYZING}
                </Typography>
              </>
            );
          }
        }
      }
    } else if (user.status == Status.BLOCKED) {
      return (
        <>
          <ToolTip
            placement="top"
            className="tooltip-error"
            title={ReviewTitle.BLOCKED}
          >
            <Badge
              variant="dot"
              sx={{
                backgroundColor: convert(Status.BLOCKED),
                borderRadius: '50%',
                height: '8px',
                width: '8px',
              }}
            />
          </ToolTip>
          <Typography component="p" variant="body1">
            {ReviewTitle.BLOCKED}
          </Typography>
        </>
      );
    } else if (user.status == Status.REJECTED) {
      return (
        <>
          <ToolTip
            placement="top"
            className="tooltip-error"
            title={ReviewTitle.REJECTED}
          >
            <Badge
              variant="dot"
              sx={{
                backgroundColor: convert(Status.REJECTED),
                borderRadius: '50%',
                height: '8px',
                width: '8px',
              }}
            />
          </ToolTip>
          <Typography component="p" variant="body1">
            {ReviewTitle.REJECTED}
          </Typography>
        </>
      );
    } else if (user.status == Status.PENDING) {
      return (
        <>
          <ToolTip
            placement="top"
            className="tooltip-waring"
            title={ReviewTitle.PENDING}
          >
            <Badge
              variant="dot"
              sx={{
                backgroundColor: convert(Status.PENDING),
                borderRadius: '50%',
                height: '8px',
                width: '8px',
              }}
            />
          </ToolTip>
          <Typography component="p" variant="body1">
            {ReviewTitle.PENDING}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <ToolTip
            placement="top"
            className="tooltip-primary"
            title={ReviewTitle.APPROVED}
          >
            <Badge
              variant="dot"
              sx={{
                backgroundColor: convert(Status.APPROVED),
                borderRadius: '50%',
                height: '8px',
                width: '8px',
              }}
            />
          </ToolTip>
          <Typography component="p" variant="body1">
            {ReviewTitle.APPROVED}
          </Typography>
        </>
      );
    }
  };

  return (
    <Box
      gap={2}
      paddingX={8}
      display="flex"
      marginBottom={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      flexDirection="row"
      alignItems="center"
      alignContent="center"
    >
      {handleBullet('basic', user)}
    </Box>
  );
};

export default Bullet;
