import Grid from '@mui/material/Grid';

import { MuiLoader } from '@2ndmarket/components';

interface LoadingProps {
  loading: boolean;
}

const LoadingSection: React.FC<LoadingProps> = ({ loading }) => {
  return (
    <Grid
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      position="absolute"
      top={0}
    >
      <MuiLoader loaderState={true} text="Carregando..." />
    </Grid>
  );
};

export default LoadingSection;
