import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { ChangeEvent, useRef, useState } from 'react';

import { FormRegister } from '@2ndmarket/types';

import {
  userRegister,
  userRequestToken,
} from '@2ndmarket/services';

import {
  Icon,
  themes,
  MaskedInput,
} from '@2ndmarket/components';

import {
  RegisterDocuments,
  HttpStatus,
  HttpError,
  Documents,
  Targets,
  Masks,
} from '@2ndmarket/helpers';

import { Header, Navigation } from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { schema, defaultValues } from './formInfoRegister';

const UserRegister: React.FC = () => {
  const router = useRouter();

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  let textInput = useRef(null);

  const [documentText, setDocumentText] = useState(
    'Nome e sobrenome',
  );

  const [mask, setMask] = useState(Masks.CPF);
  const [documentType, setDocumentType] = useState(
    Documents.CPF,
  );

  const [loader, setLoader] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const handleDocumentType = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const typeDocument = event.target.value;
    const cnpjText = 'Razão social';
    const cpfText = 'Nome e sobrenome';

    if (typeDocument === Documents.CNPJ) {
      setDocumentType(Documents.CNPJ);
      setDocumentText(cnpjText);
      setMask(Masks.CNPJ);
    } else {
      setDocumentType(Documents.CPF);
      setDocumentText(cpfText);
      setMask(Masks.CPF);
    }
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorUnprocessableEntity:
        for (let fieldError of error.errors) {
          setError(fieldError.field as any, {
            type: 'custom',
            message: fieldError.message,
          });
        }
        break;
      case HttpStatus.ClientErrorConflict:
        setShowErrorModal(true);
        setErrorModalMessage(error.error);
    }
  };

  const onSubmit = async (data: FormRegister) => {
    setLoader(true);
    if (data.person_type === '') {
      data.person_type = Documents.CPF;
    }

    const formMail = data.email;
    const cpf_cnpj = data.cpf_cnpj.replace(/\D/g, '');

    userRegister(
      data.name,
      data.email,
      cpf_cnpj,
      data.person_type,
    )
      .then((data: FormRegister) => {
        userRequestToken(formMail)
          .then(() => {
            router.push(
              `${Targets.BACKOFFICE_ONBOARDING}/${data.id}`,
            );
          })
          .catch((error: HttpError) => {
            handleErrors(error);
          })
          .finally(() => {
            setLoader(false);
          });
      })
      .catch((error: HttpError) => {
        handleErrors(error);
        setLoader(false);
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      });
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-users"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            padding: {
              xs: '24px 16px',
              lg: '24px 32px',
            },
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Box
            gap={4}
            display="flex"
            marginBottom={1}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton
              onClick={() => router.back()}
              sx={{ ml: -1 }}
            >
              <Icon
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography variant="h2" component="h2">
              Adicionar usuário
            </Typography>
          </Box>
          <Box
            gap={2}
            paddingX={8}
            display="flex"
            marginBottom={4}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <Badge
              variant="dot"
              sx={{
                backgroundColor:
                  themes.authentication.palette.primary.main,
                borderRadius: '50%',
                height: '6px',
                width: '6px',
              }}
            />
            <Typography component="p" variant="body1">
              Novo usuário
            </Typography>
          </Box>
          <Box
            gap={3}
            width="100%"
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '20%',
                  lg: '20%',
                },
              }}
            >
              <Button
                variant="outlined"
                endIcon={
                  <Icon
                    size={12}
                    name="arrow"
                    rotate="-90deg"
                    color={
                      themes.authentication.palette.grey[300]
                    }
                  />
                }
                sx={{
                  width: '100%',
                  height: '54px',
                  border: 'none',
                  borderRadius: '6px',
                  justifyContent: 'space-between',
                  backgroundColor:
                    themes.authentication.palette.grey[100],
                  '&:hover': {
                    border: 'none',
                    cursor: 'default',
                    backgroundColor:
                      themes.authentication.palette.grey[100],
                  },
                }}
              >
                Cadastro
              </Button>
            </Box>
            <Box
              gap={3}
              display="flex"
              flexDirection="column"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '80%',
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                color={themes.authentication.palette.grey[600]}
              >
                Cadastro
              </Typography>
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:
                      themes.authentication.palette.grey[900],
                  },
                }}
              >
                <Stack
                  spacing={3}
                  width="100%"
                  direction={{
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <Paper
                    elevation={0}
                    component="div"
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '50%',
                        md: '50%',
                        lg: '50%',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          label={documentText}
                          error={Boolean(errors.name)}
                          inputProps={{ maxLength: 200 }}
                          {...register('name', {
                            required: true,
                          })}
                          helperText={
                            errors.name && errors.name.message
                          }
                        />
                      )}
                    />
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Tipo de cadastro"
                      defaultValue={Documents.CPF}
                      {...register('person_type', {
                        required: true,
                      })}
                      onChange={handleDocumentType}
                    >
                      {RegisterDocuments.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Paper>
                  <Paper
                    elevation={0}
                    component="div"
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '50%',
                        md: '50%',
                        lg: '50%',
                      },
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="dense"
                          label="E-mail"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.email)}
                          inputProps={{
                            style: {
                              textTransform: 'lowercase',
                            },
                            maxLength: 75,
                          }}
                          {...register('email', {
                            required: true,
                          })}
                          helperText={
                            errors.email && errors.email.message
                          }
                        />
                      )}
                    />
                    <Controller
                      name="cpf_cnpj"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          {...register('cpf_cnpj', {
                            required: true,
                          })}
                          label={`N.º do ${documentType.toUpperCase()}`}
                          InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: {
                              inputMode: 'numeric',
                              unmask: true,
                              mask: mask,
                            },
                          }}
                          inputRef={textInput}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          error={Boolean(errors.cpf_cnpj)}
                          helperText={
                            errors.cpf_cnpj &&
                            errors.cpf_cnpj.message
                          }
                        />
                      )}
                    />
                  </Paper>
                </Stack>
                <Box
                  marginY={2}
                  display="flex"
                  alignItems="center"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  }}
                  justifyContent="flex-end"
                >
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      paddingX: 8,
                      width: {
                        xs: '100%',
                        sm: 172,
                        md: 172,
                        lg: 172,
                      },
                    }}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default UserRegister;
