import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const TextFilters = () => {
  return (
    <Box
      gap={1}
      display="flex"
      flexDirection="column"
      sx={{
        marginBottom: {
          xs: 10,
          sm: 10,
          lg: 10,
          xl: 6,
        },
      }}
    >
      <Typography variant="h4" component="h4">
        Exemplo de pesquisas...
      </Typography>
      <Box className="search-one" marginBottom={1}>
        <Box gap={1} display="flex" alignItems="center">
          <Icon
            size={16}
            name="search"
            color={themes.authentication.palette.grey[300]}
          />
          <Typography
            component="p"
            variant="body1"
            sx={{
              marginLeft: 1,
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Usuários aguardando aprovação
          </Typography>
        </Box>
        <List
          disablePadding
          className="one-search"
          sx={{
            flexDirection: 'column',
            listStyleType: 'disc',
            gap: '4px',
            mt: 1,
            pl: 6,
          }}
        >
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Etapa do cadastro: Novo usuário
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Status da etapa: Recebido
          </ListItem>
        </List>
      </Box>
      <Box className="search-two" marginBottom={1}>
        <Box gap={1} display="flex" alignItems="center">
          <Icon
            size={16}
            name="search"
            color={themes.authentication.palette.grey[300]}
          />
          <Typography
            component="p"
            variant="body1"
            sx={{
              marginLeft: 1,
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Usuários sem conta digital
          </Typography>
        </Box>
        <List
          disablePadding
          className="two-search"
          sx={{
            flexDirection: 'column',
            listStyleType: 'disc',
            gap: '4px',
            mt: 1,
            pl: 6,
          }}
        >
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Etapa do cadastro: Cadastro básico
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Status da etapa: Aprovado
          </ListItem>
        </List>
      </Box>
      <Box className="search-three" marginBottom={1}>
        <Box gap={1} display="flex" alignItems="center">
          <Icon
            size={16}
            name="search"
            color={themes.authentication.palette.grey[300]}
          />
          <Typography
            component="p"
            variant="body1"
            sx={{
              marginLeft: 1,
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Usuários com conta digital
          </Typography>
        </Box>
        <List
          disablePadding
          className="three-search"
          sx={{
            flexDirection: 'column',
            listStyleType: 'disc',
            gap: '4px',
            mt: 1,
            pl: 6,
          }}
        >
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Etapa do cadastro: Conta digital
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Status da etapa: Aprovado
          </ListItem>
        </List>
      </Box>
      <Box className="search-four" marginBottom={1}>
        <Box gap={1} display="flex" alignItems="center">
          <Icon
            size={16}
            name="search"
            color={themes.authentication.palette.grey[300]}
          />
          <Typography
            component="p"
            variant="body1"
            sx={{
              marginLeft: 1,
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Usuários que precisam fornecer o código SMS para
            abertura da conta digital:
          </Typography>
        </Box>
        <List
          disablePadding
          className="four-search"
          sx={{
            flexDirection: 'column',
            listStyleType: 'disc',
            gap: '4px',
            mt: 1,
            pl: 6,
          }}
        >
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Etapa do cadastro: Conta digital
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            Status da etapa: Pendente
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default TextFilters;
