import { useRouter } from 'next/router';
import { useState, useContext } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { userLogin } from '@2ndmarket/services';
import { HttpStatus } from '@2ndmarket/helpers';
import { useLocalStorage } from '@2ndmarket/hooks';

import {
  Copyright,
  MuiLoader,
  ModalDialog,
} from '@2ndmarket/components';

import { Context } from '../../helpers';
import { Routine } from '../../components';
import { schema, defaultValues } from './formInfo';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const Login: React.FC = () => {
  const router = useRouter();

  const context = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [token, setToken] = useLocalStorage(
    '@2ndMarket-token',
    '',
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    userLogin(data.username, data.password, 'staff')
      .then((data: { access_token: string }) => {
        setToken(data.access_token);
        router.replace('/');
      })
      .catch((error: { status: number }) => {
        setLoading(false);
        if (!error.status) {
          context?.setError(true);
        } else if (
          error.status === HttpStatus.ClientErrorUnauthorized
        ) {
          setShowErrorModal(true);
          setErrorModalMessage('Usuário e/ou senha inválidos.');
        }
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        height="100%"
        paddingTop={8}
        display="flex"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ backgroundColor: 'background.neutral' }}
      >
        {loading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <MuiLoader
              loaderState={loading}
              text="Carregando..."
            />
          </Box>
        ) : (
          <Box paddingX={4}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              color="text.primary"
            >
              Login
            </Typography>
            <Typography
              paddingY={4}
              component="p"
              variant="body1"
              color="text.secondary"
            >
              Para acessar o painel e gerenciar os usuários do
              ecossistema, informe o seu e-mail e senha.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                label="E-mail *"
                variant="outlined"
                sx={{ borderWidth: 1 }}
                error={Boolean(errors.username)}
                {...register('username', { required: true })}
                helperText={
                  errors.username && errors.username.message
                }
                inputProps={{
                  style: { textTransform: 'lowercase' },
                  maxLength: 75,
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Senha *"
                type="password"
                variant="outlined"
                autoComplete="off"
                error={Boolean(errors.password)}
                {...register('password', { required: true })}
                helperText={
                  errors.password && errors.password.message
                }
              />
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                sx={{ marginTop: 2 }}
              >
                Realizar login
              </Button>
            </Box>
          </Box>
        )}

        <Copyright />

        {showErrorModal && (
          <ModalDialog
            error
            maxWidth="sm"
            open={showErrorModal}
            errorMessage={errorModalMessage}
            onClose={() => setShowErrorModal(false)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Login;
