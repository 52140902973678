import { useTable } from 'react-table';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { themes } from '@2ndmarket/components';

import {
  OnboardingProgress,
  ReviewStep,
  Targets,
  Status,
} from '@2ndmarket/helpers';

import {
  ColumnProps,
  TableProps,
  IUser,
} from '@2ndmarket/types';

import Bullet from '../../Bullet';

import Table from '@mui/material/Table';
import Badge from '@mui/material/Badge';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const TableUser: React.FC<TableProps> = props => {
  const router = useRouter();
  const { data } = props;

  const [columns, setColumns] = useState<ColumnProps[]>(
    props.columns != undefined ? props.columns : [],
  );

  const indexEdit = columns
    .map(event => event.accessor)
    .indexOf('edit');

  useEffect(() => {
    if (indexEdit > -1) {
      var columnsTemp = [...columns];
      columns.splice(indexEdit, 1);
      setColumns(columnsTemp);
    }
  }, [columns, indexEdit]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const convert = (Color: any) => {
    switch (Color) {
      case Status.PENDING: {
        return themes.authentication.palette.warning.main;
      }
      case Status.REJECTED:
      case Status.BLOCKED: {
        return themes.authentication.palette.error.main;
      }
      case Status.APPROVED: {
        return themes.authentication.palette.primary.main;
      }
      case Status.NONE: {
        return themes.authentication.palette.grey[300];
      }
    }
  };

  const ToolTip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ),
  )({
    ['&.tooltip-default .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.NONE),
    },
    ['&.tooltip-waring .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.PENDING),
    },
    ['&.tooltip-error .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.BLOCKED),
    },
    ['&.tooltip-primary .MuiTooltip-tooltip']: {
      marginBottom: '3px !important',
      backgroundColor: convert(Status.APPROVED),
    },
  });

  const handleBullet = (value: string, user: IUser) => {
    if (user.onboarding_progress == OnboardingProgress.NEWUSER) {
      if (user.onboarding_basic != null) {
        for (const [key, value] of Object.entries(
          user.onboarding_basic,
        )) {
          if (value == ReviewStep.REFUSED) {
            return (
              <ToolTip
                placement="top"
                title="Rejeitado"
                className="tooltip-default"
              >
                <Badge
                  variant="dot"
                  sx={{
                    backgroundColor: convert(Status.NONE),
                    borderRadius: '50%',
                    height: '8px',
                    width: '8px',
                  }}
                />
              </ToolTip>
            );
          }
          if (value == ReviewStep.NONE) {
            return (
              <ToolTip
                placement="top"
                title="Pendente"
                className="tooltip-default"
              >
                <Badge
                  variant="dot"
                  sx={{
                    backgroundColor: convert(Status.NONE),
                    borderRadius: '50%',
                    height: '8px',
                    width: '8px',
                  }}
                />
              </ToolTip>
            );
          }
          if (value == ReviewStep.RECEIVED) {
            return (
              <ToolTip
                placement="top"
                title="Aguardando aprovação"
                className="tooltip-waring"
              >
                <Badge
                  variant="dot"
                  sx={{
                    backgroundColor: convert(Status.PENDING),
                    borderRadius: '50%',
                    height: '8px',
                    width: '8px',
                  }}
                />
              </ToolTip>
            );
          }
        }
      }
    } else if (user.status == Status.BLOCKED) {
      return (
        <ToolTip
          placement="top"
          title="Bloqueado"
          className="tooltip-error"
        >
          <Badge
            variant="dot"
            sx={{
              backgroundColor: convert(Status.BLOCKED),
              borderRadius: '50%',
              height: '8px',
              width: '8px',
            }}
          />
        </ToolTip>
      );
    } else if (user.status == Status.REJECTED) {
      return (
        <ToolTip
          placement="top"
          title="Recusado"
          className="tooltip-error"
        >
          <Badge
            variant="dot"
            sx={{
              backgroundColor: convert(Status.REJECTED),
              borderRadius: '50%',
              height: '8px',
              width: '8px',
            }}
          />
        </ToolTip>
      );
    } else if (user.status == Status.PENDING) {
      return (
        <ToolTip
          placement="top"
          title="Pendente"
          className="tooltip-waring"
        >
          <Badge
            variant="dot"
            sx={{
              backgroundColor: convert(Status.PENDING),
              borderRadius: '50%',
              height: '8px',
              width: '8px',
            }}
          />
        </ToolTip>
      );
    } else {
      return (
        <ToolTip
          placement="top"
          title="Ativo"
          className="tooltip-primary"
        >
          <Badge
            variant="dot"
            sx={{
              backgroundColor: convert(Status.APPROVED),
              borderRadius: '50%',
              height: '8px',
              width: '8px',
            }}
          />
        </ToolTip>
      );
    }
  };

  const handleClick = (id: string, onboarding: string) => {
    if (onboarding == OnboardingProgress.NEWUSER) {
      router.push(`${Targets.BACKOFFICE_ONBOARDING}/${id}`);
    } else {
      router.push(`${Targets.BACKOFFICE_USERS_PROFILE}/${id}`);
    }
  };

  return (
    <Table
      {...getTableProps()}
      sx={{
        minWidth: 700,
        overflowX: 'scroll',
      }}
    >
      <TableHead>
        {headerGroups?.map((headerGroup, key) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            key={key}
          >
            {headerGroup.headers.map((column, key) => (
              <TableCell
                {...column.getHeaderProps()}
                key={key}
                sx={{
                  minWidth: column.minWidth,
                  borderBottom: 'none',
                }}
              >
                {column.render('title')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                key={key}
                onClick={() =>
                  handleClick(
                    row.original.id,
                    row.original.onboarding_progress,
                  )
                }
                sx={{
                  cursor: 'pointer',
                  '&:nth-of-type(odd)': {
                    border: 'none',
                    backgroundColor: 'rgb(245,245,248,0.6)',
                  },
                }}
              >
                {row.cells.map((cell, key) => {
                  if (cell.column.id === 'status') {
                    return (
                      <TableCell
                        key={key}
                        sx={{ borderBottom: 'none' }}
                      >
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{
                            textAlign: 'center',
                            position: 'relative',
                            zIndex: 99,
                          }}
                        >
                          {handleBullet(
                            cell.value,
                            row.original,
                          )}
                        </Typography>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        key={key}
                        scope="row"
                        component="th"
                        sx={{ borderBottom: 'none' }}
                      >
                        <Typography
                          sx={{
                            maxWidth: {
                              xs: '15ch',
                              sm: '20ch',
                              md: '25ch',
                              lg: '30ch',
                            },
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {cell.render('Cell')}
                        </Typography>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              align="center"
              colSpan={headerGroups[0].headers.length}
            >
              Nenhum resultado encontrado
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableUser;
