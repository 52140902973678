import { useTable } from 'react-table';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Targets } from '@2ndmarket/helpers';
import { ColumnProps, TableProps } from '@2ndmarket/types';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

const TableConf: React.FC<TableProps> = props => {
  const router = useRouter();
  const { data } = props;

  const [columns, setColumns] = useState<ColumnProps[]>(
    props.columns != undefined ? props.columns : [],
  );

  const indexEdit = columns
    .map(event => event.accessor)
    .indexOf('edit');

  useEffect(() => {
    if (indexEdit > -1) {
      var columnsTemp = [...columns];
      columns.splice(indexEdit, 1);
      setColumns(columnsTemp);
    }
  }, [columns, indexEdit]);

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
  } = useTable({ columns, data });

  const handleClick = (id: string) => {
    switch (router.pathname) {
      case Targets.BACKOFFICE_CONF_BANK_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_BANK_EDIT}/${id}`,
        );
        break;
      case Targets.BACKOFFICE_CONF_CITY_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_CITY_EDIT}/${id}`,
        );
        break;
      case Targets.BACKOFFICE_CONF_STATE_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_STATE_EDIT}/${id}`,
        );
        break;
      case Targets.BACKOFFICE_CONF_COUNTRY_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_COUNTRY_EDIT}/${id}`,
        );
        break;
      case Targets.BACKOFFICE_TEAM_LIST:
        router.push(`${Targets.BACKOFFICE_TEAM_EDIT}/${id}`);
        break;
      case Targets.BACKOFFICE_CONF_PERMISSION_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_PERMISSION_EDIT}/${id}`,
        );
        break;
      case Targets.BACKOFFICE_CONF_FUNCTION_LIST:
        router.push(
          `${Targets.BACKOFFICE_CONF_FUNCTION_EDIT}/${id}`,
        );
        break;
    }
  };

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups?.map((headerGroup, key) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            key={key}
          >
            {headerGroup.headers.map((column, key) => (
              <TableCell
                {...column.getHeaderProps()}
                key={key}
                sx={{
                  minWidth: column.minWidth,
                  borderBottom: 'none',
                }}
              >
                {column.render('title')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                key={key}
                onClick={() => handleClick(row.original.id)}
                sx={{
                  cursor: 'pointer',
                  '&:nth-of-type(odd)': {
                    border: 'none',
                    backgroundColor: 'rgb(245,245,248,0.6)',
                  },
                }}
              >
                {row.cells.map((cell, key) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      key={key}
                      scope="row"
                      component="th"
                      sx={{ borderBottom: 'none' }}
                    >
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {cell.render('Cell')}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              align="center"
              sx={{ borderBottom: 'none' }}
              colSpan={headerGroups[0].headers.length}
            >
              Nenhum resultado encontrado
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableConf;
