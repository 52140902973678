import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';

import { useMe } from '@2ndmarket/services';
import { themes } from '@2ndmarket/components';
import { UserIdProps } from '@2ndmarket/types';
import { SubMenu as options, Crypto } from '@2ndmarket/helpers';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';

const SubMenuMobile: React.FC<UserIdProps> = ({ user_id }) => {
  const router = useRouter();
  const pathname = router.pathname.split('/');

  const { data: user } = useMe(user_id);
  const { setValue, control } = useForm({
    defaultValues: {
      subMenu: pathname[2].toString() || pathname[3].toString(),
    },
  });

  const handleLink = (id: number, path: string) => {
    setValue('subMenu', path);

    if (path == Crypto.BTC || path == Crypto.BKAI) {
      router.push(`/usuarios/carteira-cripto/${path}/${id}`);
      return;
    }

    router.push(`/usuarios/${path}/${id}`);
  };

  useEffect(() => {
    if (router.query.crypto) {
      setValue(
        'subMenu',
        router.query.crypto.toString() || pathname[3].toString(),
      );
    }
  }, [pathname, setValue, router]);

  return (
    <Controller
      name="subMenu"
      control={control}
      render={({ field }) => (
        <TextField
          select
          fullWidth
          {...field}
          margin="dense"
          variant="outlined"
          sx={{
            marginBottom: 2,
            backgroundColor: 'white',
            '.MuiOutlinedInput-input': {
              color:
                themes.authentication.palette.primary.main +
                ' !important',
            },
          }}
          onChange={event =>
            handleLink(user.id, event.target.value as string)
          }
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{
                color:
                  option.value == pathname[2].toString() ||
                  option.value == pathname[3].toString()
                    ? `${themes.authentication.palette.primary.main} !important`
                    : `${themes.authentication.palette.grey[600]} !important`,
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SubMenuMobile;
