import { useEffect, useRef } from 'react';

import { PaginationProps } from '@2ndmarket/types';
import { SelectPagination, themes } from '@2ndmarket/components';

import {
  Targets,
  paginationDisplaying,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

const Paginator: React.FC<PaginationProps> = ({
  data,
  onChange,
  showLimit,
}) => {
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (value > data.currentPage && value <= data.lastPage) {
      onChange('page', value);
    } else if (value < data.currentPage && value > 0) {
      onChange('page', value);
    }
  };

  const pageSizeRef = useRef('pageSize');

  useEffect(() => {
    if (Targets.BACKOFFICE_USERS_WALLET_FIAT) {
      pageSizeRef.current = 'page_size';
    }

    if (Targets.BACKOFFICE_CONF_PERMISSION_LIST) {
      pageSizeRef.current = 'page_size';
    }
  }, [data]);

  const pageSize = pageSizeRef.current;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <Box
        gap={1}
        paddingX={2}
        display="flex"
        marginBottom={2}
        alignItems="center"
      >
        <Typography
          component="p"
          variant="body2"
          color={themes.authentication.palette.grey[300]}
        >
          Exibir
        </Typography>
        <Box width={67} height={30}>
          <SelectPagination
            options={
              data.totalRecords > 0
                ? paginationDisplaying
                : [{ value: 0, label: '0' }]
            }
            value={
              data.totalRecords > 0
                ? data.pageSize.toString()
                : '0'
            }
            onChange={value => onChange(pageSize, value)}
          />
        </Box>
        <Typography
          component="p"
          variant="body2"
          color={themes.authentication.palette.grey[300]}
        >
          de &nbsp;
          <Typography
            variant="body2"
            component="span"
            color={themes.authentication.palette.grey[600]}
          >
            {data.totalRecords}
          </Typography>
        </Typography>
      </Box>

      {showLimit && (
        <Pagination
          shape="rounded"
          onChange={handleChange}
          count={data.lastPage > 0 ? data.lastPage : 1}
          page={data.currentPage > 0 ? data.currentPage : 1}
        />
      )}
    </Box>
  );
};

export default Paginator;
