import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useIntent, useBalance } from '@2ndmarket/services';
import { TransferProps } from '@2ndmarket/types';

import {
  IntentStatus,
  Targets,
  Crypto,
} from '@2ndmarket/helpers';

import {
  ModalDialog,
  themes,
  Icon,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const TransferAwait: React.FC<TransferProps> = ({
  user_id,
  crypto,
  data,
}) => {
  const router = useRouter();
  const { mutate: mutateBalance } = useBalance(user_id);
  const [refreshInterval, setRefreshInterval] = useState(1000);

  const { intent } = useIntent(
    user_id,
    data?.id,
    data?.type,
    data?.register,
    refreshInterval,
  );

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [errorPaymentModal, setErrorPaymentModal] =
    useState(false);

  const [successPaymentModal, setSuccessPaymentModal] =
    useState(false);

  const handleReceipt = () => {
    if (crypto == Crypto.BTC || crypto == Crypto.BKAI) {
      const rota = `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${crypto}/${user_id}/comprovante/${data?.type}/${intent?.transaction_uuid}`;
      router.push(rota);
    } else {
      const rota = `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user_id}/comprovante/${data?.type}/${intent?.transaction_uuid}`;
      router.push(rota);
    }

    return;
  };

  const handleClose = () => {
    if (crypto == Crypto.BTC || crypto == Crypto.BKAI) {
      router.push(
        `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${crypto}/${user_id}`,
      );
    } else {
      router.push(
        `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user_id}`,
      );
    }

    return;
  };

  useEffect(() => {
    if (!intent) return;

    if (intent.intent_status === IntentStatus.FAILURE) {
      setRefreshInterval(0);
      setErrorModal(true);
      setErrorMessage(intent.description);
    }

    if (intent.intent_status === IntentStatus.DONE) {
      setRefreshInterval(0);
      setSuccessPaymentModal(true);
      mutateBalance();
    }
  }, [intent, mutateBalance, data]);

  return (
    <>
      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          errorTitle="Boleto vencido"
          onClose={handleClose}
        />
      )}

      {errorPaymentModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorPaymentModal}
          onClose={() => setErrorModal(false)}
        >
          <Box
            gap={2}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Avatar sx={{ width: 100, height: 100 }}>
              <Icon
                size={38}
                name="receipt-error"
                color={themes.authentication.palette.error.main}
              />
            </Avatar>
            <Typography variant="body1" component="span">
              [{data?.type}]
            </Typography>
            <Typography variant="body1" component="p">
              Por favor, tente novamente mais tarde. Caso o
              problema persista, entre em contato conosco.
            </Typography>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Fechar
            </Button>
          </Box>
        </ModalDialog>
      )}

      {successPaymentModal && (
        <ModalDialog
          maxWidth="sm"
          open={successPaymentModal}
          onClose={() => setSuccessPaymentModal(false)}
        >
          <Box
            gap={2}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Avatar sx={{ width: 100, height: 100 }}>
              <Icon
                size={38}
                name="receipt-sucess"
                color={
                  themes.authentication.palette.success.main
                }
              />
            </Avatar>
            <Typography variant="body1" component="span">
              [{data?.type}]
            </Typography>
            <Typography variant="body1" component="p">
              Transferência realizada.
            </Typography>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => handleReceipt()}
            >
              Ver comprovante
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Fechar
            </Button>
          </Box>
        </ModalDialog>
      )}
    </>
  );
};

export default TransferAwait;
