import * as yup from 'yup';

interface Scope {
  id: number;
  name: string;
}

export const defaultValues = {
  name: '',
  type: '',
  description: '',
  role_permissions: [
    {
      permission_id: 0,
      role_id: 0,
      own_activate: false,
      own_list: false,
      own_create: false,
      own_read: false,
      own_update: false,
      own_delete: false,
      other_activate: false,
      other_list: false,
      other_create: false,
      other_read: false,
      other_update: false,
      other_delete: false,
    },
  ],
};

const message = 'Campo obrigatório';

export const schema = yup.object().shape({
  name: yup.string().required(message),
  type: yup.string().required(message),
  description: yup.string().required(message),
});
