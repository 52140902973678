import { useEffect, useState } from 'react';

import { LoaderProps } from '@2ndmarket/types';
import { themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const Loader: React.FC<LoaderProps> = ({ loaderState }) => {
  const [loaderShow, setLoaderShow] = useState(loaderState);

  useEffect(() => {
    setLoaderShow(loaderState);
  }, [loaderState]);

  const BoxLoader = styled(Box)({
    padding: 35,
    width: '135px',
    height: '135px',
    borderRadius: '50%',
    position: 'relative',
    '@keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 50px)',
      },
      '50%': {
        top: 'calc(50% - 50px)',
        left: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 50px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 50px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '15px',
        width: '15px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 50px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
      },
    },
    '@-webkit-keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 50px)',
      },
      '50%': {
        top: 'calc(50% - 50px)',
        left: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 50px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@-webkit-keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 50px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '15px',
        width: '15px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 50px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '15px',
        width: '15px',
      },
    },
    '.square': {
      margin: 50,
      borderRadius: '0%',
      position: 'absolute',
      boxShadow: '0 0 12px rgba(0,0,0,0.1)',
      '&.first': {
        width: '15px',
        height: '15px',
        animation: 'loader_one 2s linear infinite',
        borderWidth: '2px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_one 2s linear infinite',
      },
      '&.second': {
        width: '15px',
        height: '15px',
        top: 'calc(100% - 100px)',
        left: 'calc(100% - 100px)',
        animation: 'loader_two 2s linear infinite',
        borderWidth: '2px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_two 2s linear infinite',
      },
    },
  });

  return (
    <Grid
      item
      gap={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      {loaderShow && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BoxLoader sx={{ bgcolor: 'grey.400' }}>
            <Box
              component="div"
              className="square first"
              sx={{ borderColor: 'primary.main' }}
            />
            <Box
              component="div"
              className="square second"
              sx={{ borderColor: 'primary.light' }}
            />
          </BoxLoader>
        </Box>
      )}
    </Grid>
  );
};

export default Loader;
