import { useRouter } from 'next/router';
import { useForm, Controller } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';

import { updateOnboarding } from '@2ndmarket/services';

import {
  ModalDialog,
  MuiLoader,
  themes,
} from '@2ndmarket/components';

import {
  OnboardingBasicProps,
  OnboardingBasicStep,
} from '@2ndmarket/types';

import {
  Documents,
  ReviewName,
  ReviewStep,
  OnboardingStep,
  HttpError,
  Targets,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material';

import ProfilePF from './Profile/ProfilePF';
import ProfilePJ from './Profile/ProfilePJ';
import Document from './Documents';
import Password from './Password';
import Address from './Address';
import Phone from './Phone';

const OnboardingBasic: React.FC<OnboardingBasicProps> = ({
  user,
}) => {
  const router = useRouter();

  const [status, setStatus] = useState('');

  const [profile, setProfile] = useState(
    user.onboarding_basic.profile_step,
  );

  const [address, setAddress] = useState(
    user.onboarding_basic.address_step,
  );

  const [document, setDocument] = useState(
    user.onboarding_basic.document_step,
  );

  const [phone, setPhone] = useState(
    user.onboarding_basic.phone_step,
  );
  const [pin, setPin] = useState(user.onboarding_basic.pin_step);

  const [review] = useState({
    profile: user.onboarding_basic.profile_step,
    address: user.onboarding_basic.address_step,
    document: user.onboarding_basic.document_step,
    phone: user.onboarding_basic.phone_step,
    pin: user.onboarding_basic.pin_step,
  });

  const [anyRefused, setAnyRefused] = useState(true);
  const [allApproved, setAllApproved] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  let countApproved = 0;
  let countRefused = 0;

  const initialValues = {
    status_message: '',
  };

  const { reset, control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const returnStatus = (stepStatus: string, step: string) => {
    switch (step) {
      case OnboardingStep.PROFILE:
        review.profile = profile;
        break;
      case OnboardingStep.ADDRESS:
        review.address = address;
        break;
      case OnboardingStep.DOCUMENT:
        review.document = document;
        break;
      case OnboardingStep.PHONE:
        review.phone = phone;
        break;
      case OnboardingStep.PIN:
        review.pin = pin;
        break;
    }

    switch (stepStatus) {
      case ReviewStep.REFUSED:
        return (
          <TypographyStatus variant="body1" className="error">
            {ReviewName.REFUSED}
          </TypographyStatus>
        );
      case ReviewStep.APPROVED:
        return (
          <TypographyStatus variant="body1" className="success">
            {ReviewName.APPROVED}
          </TypographyStatus>
        );
      case ReviewStep.NONE:
      case ReviewStep.RECEIVED:
        return (
          <TypographyStatus variant="body1" className="warning">
            {ReviewName.PENDING}
          </TypographyStatus>
        );
    }
  };

  const allStatus = useCallback(() => {
    const statusSteps = Object.values(review);
    return statusSteps;
  }, [review]);

  const onSubmit = (data: OnboardingBasicStep) => {
    setIsLoading(true);
    const onboardingData = {
      profile_step: profile,
      address_step: address,
      document_step: document,
      phone_step: phone,
      pin_step: pin,
      status: ReviewStep.NONE,
      status_message: data.status_message,
    };

    allStatus().forEach(onboardingData => {
      if (onboardingData === ReviewStep.REFUSED) {
        setStatus(ReviewStep.REFUSED);
      }
    });

    if (status === ReviewStep.REFUSED) {
      onboardingData.status = status;
    } else {
      onboardingData.status = ReviewStep.APPROVED;
    }

    updateOnboarding(user.id, {
      onboarding_basic: onboardingData,
    })
      .then((data: OnboardingBasicStep) => {
        if (onboardingData.status === ReviewStep.REFUSED) {
          router.push(
            `${Targets.BACKOFFICE_USERS_LIST}/${user.id}`,
          );
        }

        if (onboardingData.status === ReviewStep.APPROVED) {
          router.push(
            `${Targets.BACKOFFICE_USERS_PROFILE}/${user.id}`,
          );
        }
      })
      .catch((error: HttpError) => {
        setErrorMessage(error.error);
        setShowModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    switch (ReviewStep.REFUSED) {
      case profile:
        setAnyRefused(false);
        break;
      case address:
        setAnyRefused(false);
        break;
      case document:
        setAnyRefused(false);
        break;
      case phone:
        setAnyRefused(false);
        break;
      case pin:
        setAnyRefused(false);
        break;
      default:
        setAnyRefused(true);
    }
  }, [profile, address, document, phone, pin]);

  useEffect(() => {
    allStatus().forEach(step => {
      if (step === ReviewStep.APPROVED) {
        countApproved++;
      }

      if (step === ReviewStep.REFUSED) {
        countRefused++;
      }
    });

    if (countApproved == 5) {
      setAllApproved(true);
      setAnyRefused(false);
    }

    if (countRefused >= 1 && countRefused <= 5) {
      setAllApproved(false);
      setAnyRefused(true);
    }

    reset({
      status_message: user.onboarding_basic.status_message ?? '',
    });
  }, [allStatus, countApproved, countRefused, user, reset]);

  const TypographyStatus = styled(Typography)(({ theme }) => ({
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '&.warning': {
      color: theme.palette.warning.main,
    },
  }));

  const ListItens = styled(ListItem)(({ theme }) => ({
    padding: 0,
    paddingRight: 2,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  }));

  return (
    <Box gap={2} display="flex" flexDirection="column">
      {user.person_type === Documents.CPF ? (
        <ProfilePF
          status={profile}
          user_id={user.id}
          setStatus={setProfile}
          step={OnboardingStep.PROFILE}
        />
      ) : (
        <ProfilePJ
          status={profile}
          user_id={user.id}
          setStatus={setProfile}
          step={OnboardingStep.PROFILE}
        />
      )}
      <Address
        status={address}
        user_id={user.id}
        setStatus={setAddress}
        step={OnboardingStep.ADDRESS}
      />
      <Document
        user_id={user.id}
        status={document}
        setStatus={setDocument}
        step={OnboardingStep.DOCUMENT}
      />
      <Phone
        status={phone}
        user_id={user.id}
        setStatus={setPhone}
        step={OnboardingStep.PHONE}
      />
      <Password
        status={pin}
        user_id={user.id}
        setStatus={setPin}
        step={OnboardingStep.PIN}
      />
      <Card
        variant="outlined"
        sx={{
          padding: 2,
          width: '100%',
          border: 'none',
          boxShadow: 'none',
          borderRadius: '6px',
        }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{ marginBottom: 2 }}
        >
          Revisar cadastro
        </Typography>
        <Divider />
        <Box
          gap={2}
          display="flex"
          flexDirection="column"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box>
            <List
              sx={{
                gap: 2,
                paddingTop: 2,
                width: '100%',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                  lg: 'row',
                },
              }}
            >
              <ListItens>
                <Typography component="span" variant="body1">
                  Meus dados
                </Typography>
                {returnStatus(profile, OnboardingStep.PROFILE)}
              </ListItens>
              <ListItens>
                <Typography component="span" variant="body1">
                  Documento
                </Typography>
                {returnStatus(document, OnboardingStep.DOCUMENT)}
              </ListItens>
            </List>
            <List
              sx={{
                gap: 2,
                width: '100%',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                  lg: 'row',
                },
              }}
            >
              <ListItens>
                <Typography component="span" variant="body1">
                  Endereço
                </Typography>
                {returnStatus(address, OnboardingStep.ADDRESS)}
              </ListItens>
              <ListItens>
                <Typography component="span" variant="body1">
                  Telefone
                </Typography>
                {returnStatus(phone, OnboardingStep.PHONE)}
              </ListItens>
            </List>
            <List sx={{ gap: 2, width: '100%' }}>
              <ListItens>
                <Typography component="span" variant="body1">
                  Senha
                </Typography>
                {returnStatus(pin, OnboardingStep.PIN)}
              </ListItens>
            </List>
          </Box>
          <Typography variant="body1" component="span">
            O usuário possui alguma informação rejeitada?
          </Typography>
          <Typography component="p">
            Descreva abaixo o motivo da rejeição do cadastro
            deste usuário. Seja claro, objetivo e lembre-se que o
            usuário vai ler integralmente o texto que você
            digitar abaixo, sem nenhum tipo de edição automática:
          </Typography>
          <Controller
            name="status_message"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                rows={1}
                fullWidth
                multiline
                margin="dense"
                variant="outlined"
                label="Digite aqui"
              />
            )}
          />
          <Box
            gap={2}
            marginTop={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              type="submit"
              variant="contained"
              disabled={anyRefused}
              sx={{ width: '172px' }}
            >
              Rejeitar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={allApproved}
              sx={{ width: '172px' }}
            >
              Aprovar
            </Button>
          </Box>
        </Box>

        {isLoading && (
          <Backdrop
            open={isLoading}
            sx={{
              right: 0,
              left: 'auto',
              width: '100%',
              zIndex: theme => theme.zIndex.drawer + 1,
              color: themes.authentication.palette.common.white,
            }}
          >
            <MuiLoader loaderState={isLoading} />
          </Backdrop>
        )}

        {showModal && (
          <ModalDialog
            error
            maxWidth="sm"
            open={showModal}
            errorMessage={errorMessage}
            onClose={() => setShowModal(false)}
          />
        )}
      </Card>
    </Box>
  );
};

export default OnboardingBasic;
