import { useEffect, useRef, useState } from 'react';

import { UserIdProps } from '@2ndmarket/types';
import { ModalDialog } from '@2ndmarket/components';

import {
  Icon,
  themes,
  MaskedInput,
} from '@2ndmarket/components';

import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import {
  useMe,
  usePhone,
  fiatVerify,
  fiatRequest,
} from '@2ndmarket/services';

import {
  Masks,
  HttpError,
  HttpStatus,
  ReviewStep,
  OnboardingProgress,
} from '@2ndmarket/helpers';

import BalanceWalletFiat from './BalanceWalletFiat';
import AccountWalletFiat from './AccountWalletFiat';
import ReceiptWalletFiat from './ReceiptWalletFiat';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

const WalletFiat: React.FC<UserIdProps> = ({ user_id }) => {
  const { data: user, mutate } = useMe(user_id);
  const { data: phone } = usePhone(user.id);

  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [codeSent, setCodeSent] = useState(false);
  const [countDown, setCountDown] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const timerRef = useRef<HTMLButtonElement>(null);

  const toggleValue = (value: string) => {
    const newCode = parseInt(value.replace(/\D/g, ''));
    if (isNaN(newCode)) {
      setCode('');
    } else {
      setCode(value);
    }
  };

  const resquestFiat = () => {
    setCountDown(true);
    setCodeSent(true);
    setErrorCode('');

    fiatRequest(user.id)
      .then(() => {
        mutate();
        localStorage.setItem(
          '@2ndMarket-success-message',
          'true',
        );
      })
      .catch((error: HttpError) => {
        setErrorMessage(error.error);
        setErrorModal(true);
      });
  };

  const verifyCode = () => {
    if (code == '') {
      setErrorCode('Código é obrigatório');
      return;
    }

    fiatVerify(code, user.id)
      .then(() => {
        mutate();
      })
      .catch((error: HttpError) => {
        if (
          error.status ===
          HttpStatus.ClientErrorUnprocessableEntity
        ) {
          for (let fieldError of error.errors) {
            setErrorCode(fieldError.message);
          }
        } else {
          setErrorMessage(error.error);
          setErrorModal(true);
        }
      })
      .finally(() => {});
  };

  const handleCountDown = () => {
    let timer = 120;
    const intervalId = window.setInterval(() => {
      if (timerRef.current) {
        if (timer <= 0) {
          clearInterval(intervalId);
          timerRef.current.textContent = '';
          setCodeSent(false);
          setCountDown(false);
          return;
        }

        let initialMinutes = String(timer / 60);
        let initialSeconds = String(timer % 60);
        let minutes = parseInt(initialMinutes, 10);
        let seconds = parseInt(initialSeconds, 10);

        timerRef.current.textContent =
          ('0' + minutes).slice(-2) +
          ':' +
          ('0' + seconds).slice(-2);
        timer--;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  };

  const phases = [
    {
      phase: '01',
      title: 'Solicitar conta',
    },
    {
      phase: '02',
      title: 'Informar o código',
    },
    {
      phase: '03',
      title: 'Conta digital aberta',
    },
  ];

  useEffect(() => {
    if (!countDown) return;
    handleCountDown();
  }, [countDown]);

  return (
    <Box width="100%">
      {user.onboarding_progress == OnboardingProgress.BASIC ? (
        <Box width="100%">
          {codeSent ||
          user.onboarding_digital_account.status ==
            ReviewStep.PENDING ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                gap={2}
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
              >
                <Avatar
                  sx={{
                    width: 88,
                    height: 88,
                    marginLeft: '0 !important',
                    backgroundColor:
                      themes.authentication.palette.grey[200],
                  }}
                >
                  <Icon
                    size={42}
                    name="code-phone"
                    viewBox="0 0 42 49"
                    format={IconFormat.SVG}
                  />
                </Avatar>
                <Box
                  gap={1}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography
                    component="p"
                    variant="body1"
                    fontWeight={500}
                  >
                    Informe o código...
                  </Typography>
                  <Typography component="p" variant="body1">
                    O parceiro bancário enviou um SMS para o
                    celular do cliente contendo um código de
                    ativação da conta digital. Para finalizar a
                    abertura da conta digital, insira o código de
                    ativação no campo abaixo:
                  </Typography>
                  <Typography component="p" variant="body1">
                    O código de ativação foi enviado para este
                    número
                  </Typography>
                  <TextField
                    disabled
                    margin="dense"
                    variant="outlined"
                    value={phone.ddd + phone.number}
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        inputMode: 'numeric',
                        mask: Masks.PHONE,
                      },
                    }}
                    sx={{
                      width: 160,
                      height: 36,
                      marginTop: 0,
                      marginBottom: 2,
                      borderRadius: '2px',
                      justifyContent: 'center',
                      backgroundColor:
                        themes.authentication.palette.grey[200],
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'center',
                        '-webkit-text-fill-color':
                          themes.authentication.palette.primary
                            .main + ' !important',
                      },
                    }}
                  />
                  <Box
                    gap={1}
                    width="100%"
                    display="flex"
                    sx={{
                      flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                      },
                      '& .MuiInputBase-root': {
                        backgroundColor:
                          themes.authentication.palette
                            .grey[900],
                      },
                    }}
                  >
                    <TextField
                      name="code"
                      size="medium"
                      margin="dense"
                      label="Código de ativação"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.CODE,
                        },
                      }}
                      error={errorCode != ''}
                      helperText={errorCode}
                      onChange={event =>
                        toggleValue(event.target.value)
                      }
                      sx={{
                        marginTop: 0,
                        width: '100%',
                      }}
                    />
                    {!countDown ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 136,
                            lg: 136,
                          },
                          height: 56,
                        }}
                        onClick={resquestFiat}
                      >
                        Reenviar
                      </Button>
                    ) : (
                      <Button
                        disabled
                        ref={timerRef}
                        variant="contained"
                        sx={{
                          color: 'black',
                          height: 56,
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 136,
                            lg: 136,
                          },
                        }}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={verifyCode}
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 136,
                          lg: 136,
                        },
                        height: 56,
                      }}
                    >
                      Validar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                gap={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                  md: 'column',
                  lg: 'row',
                }}
              >
                <Box display="flex" gap={2}>
                  <Avatar
                    sx={{
                      width: 88,
                      height: 88,
                      marginLeft: '0 !important',
                      backgroundColor:
                        themes.authentication.palette.grey[200],
                    }}
                  >
                    <Icon
                      size={42}
                      name="wallet"
                      viewBox="0 0 42 42"
                      format={IconFormat.SVG}
                    />
                  </Avatar>
                  <Box>
                    <Typography
                      component="p"
                      variant="body1"
                      fontWeight="500"
                      color={
                        themes.authentication.palette.text
                          .secondary
                      }
                    >
                      Conta digital
                    </Typography>
                    <Typography variant="body1" component="p">
                      O cliente ainda não possui uma{' '}
                      <span
                        style={{
                          color:
                            themes.authentication.palette.primary
                              .main,
                        }}
                      >
                        conta digital.
                      </span>
                    </Typography>
                    <Typography variant="body1" component="p">
                      Deseja abrir uma conta?
                    </Typography>
                  </Box>
                </Box>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={resquestFiat}
                  sx={{ height: 50, width: 190 }}
                >
                  Abrir conta digital
                </Button>
              </Box>
              <Box marginTop={5} width="100%">
                <Typography
                  variant="body1"
                  fontWeight="500"
                  color={
                    themes.authentication.palette.text.secondary
                  }
                >
                  Conta digital em três etapas:
                </Typography>
                <Box
                  gap={2}
                  marginTop={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'column',
                    lg: 'row',
                  }}
                >
                  {phases.map((phase, index) => (
                    <Card
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '50%',
                          lg: '32%',
                        },
                        height: 80,
                        boxShadow: 'none',
                      }}
                      key={index}
                    >
                      <CardHeader
                        variant="body1"
                        avatar={
                          <Avatar
                            sx={{
                              fontSize: '14px',
                              fontWeight: '400',
                              color:
                                themes.authentication.palette
                                  .primary.main,
                            }}
                          >
                            {phase.phase}
                          </Avatar>
                        }
                        title={phase.title}
                      />
                    </Card>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <>
          <BalanceWalletFiat user_id={user.id} />
          <AccountWalletFiat user_id={user.id} />
          <ReceiptWalletFiat user_id={user.id} />
        </>
      )}

      {errorModal && (
        <ModalDialog
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
          errorTitle="Problema ao enviar código"
        />
      )}
    </Box>
  );
};

export default WalletFiat;
