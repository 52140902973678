import { useState } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { ReceiptIdProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';

import {
  Targets,
  TypesReceipt,
  WalletTransaction,
} from '@2ndmarket/helpers';

import TED from './TED';
import P2P from './P2P';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const TransferWallet: React.FC<ReceiptIdProps> = ({
  user_id,
}) => {
  const router = useRouter();

  const { data: user } = useMe(user_id);
  const [stepStatus, setStepStatus] = useState(true);
  const [transferTedData, setTransferTedData] = useState({});

  const transfers = [
    {
      icon: 'bank',
      title: 'TED',
      description: 'Informe agência e conta',
      path: `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user.id}/${WalletTransaction.TRANSFER}/${TypesReceipt.TED}`,
    },
    {
      icon: '2nd',
      title: 'P2P',
      description: 'Entre contas Bank.ai',
      path: `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user.id}/${WalletTransaction.TRANSFER}/${TypesReceipt.P2P}`,
    },
  ];

  const handleLink = (path: string) => {
    router.push(`${path}`);
  };

  const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '68px',
    border: 'none',
    borderRadius: '3px',
    padding: '15px 20px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  }));

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  const Breadcrumb = styled(Button)(() => ({
    height: '28px',
    boxShadow: 'none',
    padding: '5px 10px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor:
          themes.authentication.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: themes.authentication.palette.grey[200],
      '&:hover': {
        backgroundColor: themes.authentication.palette.grey[200],
      },
    },
  }));

  return (
    <>
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Breadcrumb
          disabled
          size="small"
          color="secondary"
          variant="contained"
        >
          <Typography
            variant="body2"
            color={themes.authentication.palette.text.secondary}
          >
            Início
          </Typography>
        </Breadcrumb>
        <Breadcrumb
          size="small"
          variant="contained"
          color={
            router.pathname ==
            Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER
              ? 'primary'
              : 'secondary'
          }
        >
          <Typography
            variant="body2"
            color={
              router.pathname ==
              Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER
                ? themes.authentication.palette.common.white
                : themes.authentication.palette.text.secondary
            }
          >
            Transferência
          </Typography>
        </Breadcrumb>

        {router.pathname ==
          Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED && (
          <>
            <Breadcrumb
              size="small"
              variant="contained"
              color={
                stepStatus == false ? 'secondary' : 'primary'
              }
            >
              <Typography
                variant="body2"
                color={
                  stepStatus == false
                    ? themes.authentication.palette.text
                        .secondary
                    : themes.authentication.palette.common.white
                }
              >
                TED
              </Typography>
            </Breadcrumb>
            {Object.keys(transferTedData).length != 0 &&
              stepStatus == false && (
                <Breadcrumb
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  PIN
                </Breadcrumb>
              )}
          </>
        )}

        {router.pathname ==
          Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P && (
          <>
            <Breadcrumb
              size="small"
              variant="contained"
              color={
                stepStatus == false ? 'secondary' : 'primary'
              }
            >
              <Typography
                variant="body2"
                color={
                  stepStatus == false
                    ? themes.authentication.palette.text
                        .secondary
                    : themes.authentication.palette.common.white
                }
              >
                P2P
              </Typography>
            </Breadcrumb>
            {Object.keys(transferTedData).length != 0 &&
              stepStatus == false && (
                <Breadcrumb
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  PIN
                </Breadcrumb>
              )}
          </>
        )}
      </Box>

      {router.pathname ==
        Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER && (
        <>
          <Box marginTop={2}>
            <List
              sx={{
                gap: '10px',
                paddingY: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {transfers.map((item, index) => (
                <ListItem
                  key={index}
                  component="li"
                  sx={{ padding: 0 }}
                >
                  <Buttons
                    variant="outlined"
                    onClick={() => handleLink(item.path)}
                    endIcon={
                      <Icon
                        size={12}
                        name="arrow"
                        rotate="-90deg"
                        color={
                          themes.authentication.palette.grey[300]
                        }
                      />
                    }
                  >
                    <Box
                      gap={2}
                      display="flex"
                      alignItems="center"
                    >
                      <Icon name={item.icon} size={20} />
                      <Box textAlign="left">
                        <Typography
                          variant="body1"
                          component="span"
                          color={
                            themes.authentication.palette.text
                              .primary
                          }
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette.text
                              .secondary
                          }
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Buttons>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <ButtonReturn
              component="a"
              onClick={() => router.back()}
            >
              Voltar
            </ButtonReturn>
          </Box>
        </>
      )}

      {router.query.hasOwnProperty(WalletTransaction.TRANSFER) &&
        router.pathname ==
          Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED && (
          <TED
            user_id={user.id}
            operation={TypesReceipt.TED}
            transferData={transferTedData}
            setTransferData={setTransferTedData}
            setStepStatus={setStepStatus}
          />
        )}

      {router.query.hasOwnProperty(WalletTransaction.TRANSFER) &&
        router.pathname ==
          Targets.BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P && (
          <P2P
            user_id={user.id}
            operation={TypesReceipt.P2P}
            transferData={transferTedData}
            setTransferData={setTransferTedData}
            setStepStatus={setStepStatus}
          />
        )}
    </>
  );
};

export default TransferWallet;
