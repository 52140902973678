import { useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import dayjs from 'dayjs';

import {
  Icon,
  MaskedInput,
  ModalDialog,
  themes,
} from '@2ndmarket/components';

import {
  getStaffMePin,
  sendBillPay,
  useStaffMe,
} from '@2ndmarket/services';

import {
  FiatOperation,
  HttpError,
  HttpStatus,
  Masks,
  Status,
  Targets,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';

import { schema, defaultValues } from './formPin';
import { TransferAwait, Loader } from '../../../../components';

interface PinProps {
  setStepRevieTicket: (value: boolean) => void;
  setStepPinTicket: (value: boolean) => void;
  user_id: number;
  data: any;
}

const StepPin: React.FC<PinProps> = ({
  user_id,
  data,
  setStepPinTicket,
  setStepRevieTicket,
}) => {
  const router = useRouter();
  const { data: staff } = useStaffMe();

  const [showPin, setShowPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalValidPin, setModalValidPin] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [transferPinPromise, setTransferPinPromise] = useState({
    id: 0,
    type: '',
    register: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleShowPin = () => {
    setShowPin(show => !show);
  };

  const handleMouseDownPin = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleReturnPin = () => {
    setStepRevieTicket(true);
    setStepPinTicket(false);
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setErrorModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const handleClose = () => {
    router.push(
      `${Targets.BACKOFFICE_USERS_WALLET_FIAT}/${user_id}`,
    );
  };

  const onSubmit = async (values: { pin: string }) => {
    const dateSchedule = data.schedule_date
      .replace('/', '-')
      .replace('/', '-');

    const dateNow = dayjs(dateSchedule, 'DD-MM-YYYY').format(
      'YYYY-MM-DD',
    );

    if (values.pin != '') {
      setIsLoading(true);
      await getStaffMePin(staff.id)
        .then(response => {
          if (response.status == Status.APPROVED) {
            setIsLoading(true);

            setTimeout(async () => {
              sendBillPay(
                data.id,
                data.amount,
                dateNow.toString(),
                values.pin,
                user_id,
              )
                .then(responseTransaction => {
                  const intent = responseTransaction
                    .split('?')[1]
                    .split('&')
                    .reduce(
                      (accumulator: any, item: any) => {
                        const [key, value] = item.split('=');
                        accumulator[key] = value;
                        return accumulator;
                      },
                      {
                        amount: data.amount,
                      },
                    );

                  setTransferPinPromise({
                    type: intent.type,
                    id: parseInt(intent.intent_id),
                    register: intent.is_register,
                  });
                })
                .catch((error: HttpError) => {
                  handleErrors(error);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }, 3000);
          } else {
            setIsLoading(false);
            setModalValidPin(true);
            setErrorMessage('A senha está incorreta.');
          }
        })
        .catch((error: HttpError) => {
          handleErrors(error);
        });
    }
  };

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Box display="flex">
        <TextField
          fullWidth
          label="Senha"
          margin="dense"
          autoComplete="off"
          type={showPin ? 'text' : 'password'}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              inputMode: 'numeric',
              mask: Masks.CODE,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="start"
                  onClick={handleShowPin}
                  onMouseDown={handleMouseDownPin}
                  aria-label="toggle password visibility"
                >
                  {showPin ? (
                    <Icon
                      size={20}
                      name="view"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="hide"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('pin', { required: true })}
          error={Boolean(errors.pin)}
          helperText={errors.pin && errors.pin.message}
        />
      </Box>
      <Box
        marginTop={3}
        display="flex"
        justifyContent="space-between"
      >
        <ButtonReturn component="a" onClick={handleReturnPin}>
          Voltar
        </ButtonReturn>
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Continuar
        </Button>
      </Box>

      {transferPinPromise.type == FiatOperation.BILL && (
        <TransferAwait
          user_id={user_id}
          data={transferPinPromise}
        />
      )}

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <Box
            gap={2}
            paddingX={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            sx={{
              padding: 4,
              width: { xs: 390, sm: 520 },
              backgroundColor:
                themes.authentication.palette.grey[900],
            }}
          >
            <Box width="100px">
              <Loader loaderState={isLoading} />
            </Box>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: 600 }}
            >
              Efetuando pagamento
            </Typography>
            <Typography variant="body1" component="p">
              Estamos realizando o pagamento do seu boleto.
            </Typography>
            <Button
              disabled
              fullWidth
              variant="contained"
              sx={{ marginBottom: 2 }}
            >
              Ver comprovante
            </Button>
          </Box>
        </Backdrop>
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorTitle="Erro ao efetuar pagamento"
          errorMessage={errorMessage}
          onClose={handleClose}
        />
      )}

      {modalValidPin && (
        <ModalDialog
          error
          maxWidth="sm"
          open={modalValidPin}
          errorTitle="Senha inválida"
          errorMessage={errorMessage}
          onClose={() => setModalValidPin(false)}
        />
      )}
    </Box>
  );
};

export default StepPin;
