import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@2ndmarket/services';
import { Crypto } from '@2ndmarket/helpers';
import { useResponsive } from '@2ndmarket/hooks';
import { ReceiptIdProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';

import {
  Bullet,
  Header,
  SubMenu,
  Navigation,
  SubMenuMobile,
} from '../../components';

import WalletCripto from './WalletCripto';
import ReceiptCripto from './WalletCripto/Receipt/ReceiptCripto';
import DepositWallet from './WalletCripto/Deposit/DepositWallet';
import TransferWallet from './WalletCripto/Transfer/TransferWallet';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const UserWalletCripto: React.FC<ReceiptIdProps> = ({
  user_id,
}) => {
  const router = useRouter();
  const pathname = router.pathname.split('/');

  const { data: user, loading } = useMe(user_id);

  const mediumDow = useResponsive('down', 'md', 'xs');

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      {!loading && (
        <Box
          width="100%"
          height="90%"
          display="flex"
          className="container-users"
        >
          <Navigation />
          <Box
            paddingY={3}
            paddingX={4}
            sx={{
              padding: {
                xs: '24px 16px',
                lg: '24px 32px',
              },
              overflowY: 'scroll',
              width: {
                xs: '100%',
                sm: '100%',
                md: '80%',
                lg: '80%',
              },
            }}
          >
            <Box
              gap={4}
              display="flex"
              marginBottom={1}
              flexDirection="row"
              alignItems="center"
              alignContent="center"
            >
              <IconButton
                onClick={() => router.back()}
                sx={{ ml: -1 }}
              >
                <Icon
                  rotate="180deg"
                  name="arrow-alternative"
                  color={themes.authentication.palette.grey[300]}
                />
              </IconButton>
              <Typography variant="h2" component="h2">
                {user.name}
              </Typography>
            </Box>
            <Bullet user_id={user.id} />
            <Box
              gap={{ xs: 1, sm: 1, md: 2, lg: 3 }}
              width="100%"
              display="flex"
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                },
              }}
            >
              {mediumDow ? (
                <Box width="100%">
                  <SubMenuMobile user_id={user.id} />
                </Box>
              ) : (
                <Box
                  gap={1}
                  display="flex"
                  flexDirection="column"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '25%',
                      lg: '20%',
                    },
                  }}
                >
                  <SubMenu user_id={user.id} />
                </Box>
              )}
              <Box
                gap={2}
                display="flex"
                flexDirection="column"
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '75%',
                    lg: '80%',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  component="h4"
                  color={
                    themes.authentication.palette.text.secondary
                  }
                >
                  {router.query.crypto === Crypto.BTC
                    ? 'Carteira Bitcoin'
                    : 'Carteira Bkai'}
                </Typography>

                {router.locale == pathname[3].toString() && (
                  <WalletCripto
                    user_id={user.id}
                    coin={pathname[3].toString()}
                  />
                )}

                {router.query.hasOwnProperty('operation') && (
                  <ReceiptCripto
                    user_id={user.id}
                    crypto={router.query.crypto as string}
                    operation={router.query.operation as string}
                    receipt_id={
                      router.query.receipt_id as string
                    }
                  />
                )}

                {router.query.hasOwnProperty('transfer') && (
                  <TransferWallet
                    user_id={user.id}
                    crypto={router.query.crypto as string}
                    operation={router.query.transfer as string}
                  />
                )}

                {router.query.hasOwnProperty('deposit') && (
                  <DepositWallet
                    user_id={user.id}
                    crypto={router.query.crypto as string}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default UserWalletCripto;
