import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';

import {
  IProfile,
  StepOnboardingBacicProps,
} from '@2ndmarket/types';

import {
  updateProfile,
  useProfile,
  useMe,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
  Icon,
} from '@2ndmarket/components';

import {
  RegisterDocuments,
  SocialNameExist,
  OnboardingStep,
  IProfilePF,
  ReviewStep,
  HttpStatus,
  HttpError,
  Documents,
  Targets,
  Masks,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { schemaPF, defaultValuesPF } from './formProfile';
import { styled } from '@mui/material/styles';

const ProfilePF: React.FC<StepOnboardingBacicProps> = ({
  user_id,
  step,
  status,
  setStatus,
}) => {
  const router = useRouter();
  const { data: user } = useMe(user_id);
  const {
    error,
    data: profile,
    mutate: mutateUserProfile,
  } = useProfile(user.id);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [socialNameExist, setSocialNameExist] = useState('');

  const [checkStatus, setCheckStatus] = useState(status);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [accordion, setAccordion] = useState<string | false>(
    'profile',
  );

  const handleAccordion =
    (panel: string) =>
    (event: React.SyntheticEvent, prevState: boolean) => {
      setAccordion(prevState ? panel : false);
    };

  let defaultValues = defaultValuesPF;
  const {
    reset,
    control,
    register,
    setError,
    getValues,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaPF),
  });

  const socialNameSelect = useWatch({
    control,
    name: 'social_name_exist',
  });

  const socialNameOptions = [
    { value: SocialNameExist.NO, label: 'Não' },
    { value: SocialNameExist.YES, label: 'Sim' },
  ];

  const handleReset = () => {
    reset({
      name: user.name,
      email: user.email,
      person_type: user.person_type,
      cpf_cnpj: user.cpf_cnpj,
      social_name: profile.social_name ?? '',
      birthdate: profile.birthdate
        ? profile.birthdate.split('-').reverse().join('/')
        : '',
    });

    setSocialNameExist('');
  };

  const handleError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorMessage(error.error);
      setShowModal(true);
    }
  };

  const onSubmit = (data: IProfilePF) => {
    setIsLoading(true);
    updateProfile(
      {
        ...data,
        birthdate: data.birthdate.split('/').reverse().join('-'),
      },
      user_id,
    )
      .then((data: IProfile) => {
        mutateUserProfile(data);
      })
      .catch((error: HttpError) => {
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeStatus = (reviewStep: ReviewStep) => {
    status = reviewStep;
    setStatus(status);
    setBtnDisabled(!formState.isDirty);
  };

  useEffect(() => {
    if (error || !profile) {
      if (error.status == HttpStatus.ServerErrorInternal) {
        router.push(Targets.SERVER_ERROR_INTERNAL);
      }
      return;
    }

    if (step == OnboardingStep.PROFILE) {
      setCheckStatus(status);
    }

    reset({
      name: user.name,
      email: user.email,
      person_type: user.person_type,
      cpf_cnpj: user.cpf_cnpj,
      social_name:
        (getValues('social_name') || profile.social_name) ?? '',
      birthdate: profile.birthdate
        ? profile.birthdate.split('-').reverse().join('/')
        : '',
    });
  }, [
    user,
    error,
    step,
    reset,
    router,
    status,
    profile,
    getValues,
  ]);

  const ButtonCheckbox = styled(Button)(({ theme }) => ({
    width: '140px',
    height: '48px',
    padding: '15px',
    border: 'none',
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      border: 'none',
    },
    '.MuiButton-startIcon': {
      width: 20,
      height: 20,
      display: 'flex',
      borderRadius: 3,
      border: '1px solid',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'white',
      borderColor: theme.palette.grey[200],
    },
  }));

  const BoxChecked = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    backgroundColor: themes.authentication.palette.primary.main,
  }));

  const ButtonSave = styled(Button)(() => ({
    height: '48px',
    border: 'none',
    maxWidth: '172px',
    boxShadow: 'none',
    padding: '16px 32px',
    '&:hover': {
      border: 'none',
    },
  }));

  return (
    <>
      <Accordion
        expanded={accordion == 'profile'}
        onChange={handleAccordion('profile')}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: themes.authentication.palette.grey[300],
              }}
            />
          }
        >
          <Typography
            variant="h4"
            component="h4"
            color={themes.authentication.palette.grey[600]}
          >
            Meus dados
          </Typography>
        </AccordionSummary>
        <Divider
          sx={{
            marginX: 2,
            borderColor: `${themes.authentication.palette.grey[400]} !important`,
          }}
        />
        <AccordionDetails>
          <Box
            paddingTop={2}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              <Box width="100%" gap={1} display="grid">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      disabled
                      {...field}
                      fullWidth
                      label="Nome"
                      margin="dense"
                      value={user.name}
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 200 }}
                    />
                  )}
                />
                <TextField
                  select
                  disabled
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Tipo de cadastro"
                  defaultValue={Documents.CPF}
                >
                  {RegisterDocuments.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Controller
                  name="birthdate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      margin="dense"
                      variant="outlined"
                      label="Data de nascimento"
                      InputProps={{
                        inputComponent: MaskedInput,
                        value: getValues('birthdate'),
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.DATE,
                        },
                      }}
                      error={Boolean(formState.errors.birthdate)}
                      helperText={
                        formState.errors.birthdate &&
                        formState.errors.birthdate.message
                      }
                      onChange={event => {
                        field.onChange(event);
                        setBtnDisabled(!formState.isDirty);
                      }}
                    />
                  )}
                />
              </Box>
              <Box width="100%" gap={1} display="grid">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      fullWidth
                      margin="dense"
                      label="E-mail"
                      value={user.email}
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{
                        style: { textTransform: 'lowercase' },
                        maxLength: 75,
                      }}
                    />
                  )}
                />
                <Controller
                  name="cpf_cnpj"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      fullWidth
                      margin="dense"
                      label="N.º CPF"
                      variant="outlined"
                      value={user.cpf_cnpj}
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.CPF,
                          unmask: true,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="social_name_exist"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Nome social"
                      {...register('social_name_exist')}
                      defaultValue={
                        SocialNameExist.NO || socialNameExist
                      }
                      InputProps={{
                        value: getValues('social_name_exist'),
                      }}
                      onChange={event => {
                        field.onChange(event);
                        setSocialNameExist(
                          event.target.value as string,
                        );
                        setBtnDisabled(!formState.isDirty);
                      }}
                    >
                      {socialNameOptions.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            {socialNameSelect == SocialNameExist.YES && (
              <Box width="100%" marginTop={1}>
                <Controller
                  name="social_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label="Nome completo"
                      {...register(
                        'social_name',
                        socialNameSelect == SocialNameExist.YES
                          ? { required: true }
                          : {},
                      )}
                      InputProps={{
                        value: getValues('social_name'),
                        inputProps: { maxLength: 90 },
                      }}
                      error={Boolean(
                        formState.errors.social_name,
                      )}
                      helperText={
                        formState.errors.social_name &&
                        formState.errors.social_name.message
                      }
                      onChange={event => {
                        field.onChange(event);
                        setBtnDisabled(!formState.isDirty);
                      }}
                    />
                  )}
                />
              </Box>
            )}
            <Box
              gap={2}
              width="100%"
              paddingTop={2}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                gap={2}
                display="flex"
                alignItems="center"
                className="stepStatus"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-start',
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
              >
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.APPROVED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.APPROVED)
                  }
                >
                  Aprovado
                </ButtonCheckbox>
                <ButtonCheckbox
                  variant="outlined"
                  startIcon={
                    status == ReviewStep.REFUSED ? (
                      <BoxChecked height="20px">
                        <Icon
                          size={12}
                          name="check"
                          color={
                            themes.authentication.palette.common
                              .white
                          }
                        />
                      </BoxChecked>
                    ) : (
                      <Box></Box>
                    )
                  }
                  onClick={() =>
                    changeStatus(ReviewStep.REFUSED)
                  }
                >
                  Rejeitado
                </ButtonCheckbox>
              </Box>
              <Box
                gap={{
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 8,
                }}
                width={{
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                }}
                display="flex"
                alignItems="center"
                className="stepSend"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
              >
                <Typography
                  height={56}
                  component="p"
                  display="flex"
                  variant="body1"
                  alignItems="center"
                >
                  <Button
                    component="a"
                    variant="text"
                    disabled={
                      !btnDisabled == !formState.isDirty
                        ? true
                        : false
                    }
                    onClick={() => handleReset()}
                    sx={{
                      border: 'none !important',
                      textDecoration: 'underline !important',
                      backgroundColor: 'transparent !important',
                    }}
                  >
                    Cancelar
                  </Button>
                </Typography>
                <ButtonSave
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={
                    !btnDisabled == !formState.isDirty
                      ? true
                      : false
                  }
                >
                  Salvar
                </ButtonSave>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}

      {showModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showModal}
          errorMessage={errorMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ProfilePF;
