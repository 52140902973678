import { useRouter } from 'next/router';
import { useState, MouseEvent, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Icon,
  themes,
  MaskedInput,
  ModalDialog,
} from '@2ndmarket/components';

import {
  getStaffMePin,
  useStaffMe,
  useCryptoWallet,
  sendBtcP2P,
} from '@2ndmarket/services';

import {
  HttpStatus,
  HttpError,
  Status,
  Masks,
  yupPt,
  Targets,
  satoshiFormat,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';
import { TransferAwait, Loader } from '../../../../components';

interface PinProps {
  crypto: string;
  user_id: number;
  onResponsePin: any;
  setStepPin: (value: boolean) => void;
  setStepReview: (value: boolean) => void;
}

const StepPin: React.FC<PinProps> = ({
  crypto,
  user_id,
  onResponsePin,
}) => {
  const router = useRouter();
  const { data: staff } = useStaffMe();
  const { data: wallet } = useCryptoWallet(crypto, user_id);
  const wallet_id = wallet[0].id;

  const [showPin, setShowPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalValidPin, setModalValidPin] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [transferPinPromise, setTransferPinPromise] = useState({
    id: 0,
    type: '',
    register: false,
  });

  const schema = yupPt.object().shape({
    pin: yupPt
      .number()
      .typeError('Valor inválido')
      .required('Campo obrigatório'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { pin: '' },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!staff || !onResponsePin) {
      return;
    }
  }, [staff, onResponsePin]);

  const handleShowPin = () => {
    setShowPin(show => !show);
  };

  const handleDownPin = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorNotFound:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
      case HttpStatus.ClientErrorUnprocessableEntity:
        setErrorModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const onSubmit = async (data: { pin: string }) => {
    const sendData = {
      destination: onResponsePin.cpf_cnpj,
      amount: satoshiFormat(onResponsePin.amount_cripto),
      description: '',
    };

    const { pin } = data;
    if (pin !== '') {
      setIsLoading(true);
      await getStaffMePin(staff.id)
        .then(response => {
          if (response.status == Status.APPROVED) {
            setIsLoading(true);
            sendBtcP2P(sendData, pin, crypto, wallet_id, user_id)
              .then(responseTransaction => {
                const intent = responseTransaction
                  .split('?')[1]
                  .split('&')
                  .reduce(
                    (accumulator: any, item: any) => {
                      const [key, value] = item.split('=');
                      accumulator[key] = value;
                      return accumulator;
                    },
                    {
                      amount: onResponsePin.amount_cripto,
                    },
                  );

                setTransferPinPromise({
                  type: intent.type,
                  id: parseInt(intent.intent_id),
                  register: intent.is_register,
                });
              })
              .catch((error: HttpError) => {
                handleErrors(error);
              });
          }
        })
        .catch((error: HttpError) => {
          handleErrors(error);
        });
    }
  };

  const handleClose = () => {
    router.push(
      `${Targets.BACKOFFICE_USERS_WALLET_CRIPTO}/${crypto}/${user_id}`,
    );
  };

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Box
      gap={2}
      display="flex"
      component="form"
      flexDirection="column"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Box display="flex">
        <TextField
          fullWidth
          label="Senha"
          margin="dense"
          autoComplete="off"
          type={showPin ? 'text' : 'password'}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              inputMode: 'numeric',
              mask: Masks.CODE,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="start"
                  onClick={handleShowPin}
                  onMouseDown={handleDownPin}
                  aria-label="toggle password visibility"
                >
                  {showPin ? (
                    <Icon
                      size={20}
                      name="view"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="hide"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('pin', { required: true })}
          error={Boolean(errors.pin)}
          helperText={errors.pin && errors.pin.message}
        />
      </Box>
      <Box
        marginTop={2}
        display="flex"
        justifyContent="flex-end"
        flexDirection={{ xs: 'column', lg: 'row' }}
      >
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          sx={{
            paddingX: 8,
            width: { xs: '100%', sm: 172, md: 172, lg: 172 },
          }}
        >
          Continuar
        </Button>
      </Box>

      {transferPinPromise.id > 0 && (
        <TransferAwait
          user_id={user_id}
          crypto={crypto as string}
          data={transferPinPromise}
        />
      )}

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.text.secondary,
          }}
        >
          <Box
            gap={2}
            paddingX={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            sx={{
              padding: 4,
              width: { xs: 390, sm: 520 },
              backgroundColor:
                themes.authentication.palette.grey[900],
            }}
          >
            <Box width="100px">
              <Loader loaderState={isLoading} />
            </Box>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: 600 }}
            >
              [{crypto.toUpperCase()}P2P]
            </Typography>
            <Typography variant="body1" component="p">
              Estamos realizando sua transferência...
            </Typography>
            <Button
              disabled
              fullWidth
              variant="contained"
              sx={{ marginBottom: 2 }}
            >
              Ver comprovante
            </Button>
          </Box>
        </Backdrop>
      )}

      {modalValidPin && (
        <ModalDialog
          error
          maxWidth="sm"
          open={modalValidPin}
          errorTitle="Senha inválida"
          errorMessage={errorMessage}
          onClose={() => setModalValidPin(false)}
        />
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorTitle="Erro"
          errorMessage={errorMessage}
          onClose={handleClose}
        />
      )}
    </Box>
  );
};

export default StepPin;
