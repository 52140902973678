import { useRef } from 'react';

import { Icon, themes } from '@2ndmarket/components';
import { StepOnboardingBacicProps } from '@2ndmarket/types';
import { ReviewStep, OnboardingStep } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const BoxBtnsReviewStep: React.FC<StepOnboardingBacicProps> = ({
  step,
  status,
  setStatus,
}) => {
  const statusRef = useRef(status);

  const changeStatus = (reviewStep: ReviewStep, step: any) => {
    if (step == OnboardingStep.PROFILE) {
      statusRef.current = reviewStep;
      setStatus(statusRef.current);
    } else if (step == OnboardingStep.ADDRESS) {
      statusRef.current = reviewStep;
      setStatus(statusRef.current);
    } else if (step == OnboardingStep.DOCUMENT) {
      statusRef.current = reviewStep;
      setStatus(statusRef.current);
    } else if (step == OnboardingStep.PHONE) {
      statusRef.current = reviewStep;
      setStatus(statusRef.current);
    } else if (step == OnboardingStep.PIN) {
      statusRef.current = reviewStep;
      setStatus(statusRef.current);
    }
  };

  const ButtonCheckbox = styled(Button)(({ theme }) => ({
    width: '140px',
    height: '48px',
    padding: '15px',
    border: 'none',
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      border: 'none',
    },
    '.MuiButton-startIcon': {
      width: 20,
      height: 20,
      display: 'flex',
      borderRadius: 3,
      border: '1px solid',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'white',
      borderColor: theme.palette.grey[200],
    },
  }));

  const BoxChecked = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    backgroundColor: themes.authentication.palette.primary.main,
  }));

  return (
    <Box
      gap={2}
      display="flex"
      alignItems="center"
      className="stepStatus"
      justifyContent={{
        xs: 'space-between',
        sm: 'flex-start',
      }}
      width={{
        xs: '100%',
        sm: '50%',
        md: '50%',
        lg: '50%',
      }}
    >
      <ButtonCheckbox
        variant="outlined"
        startIcon={
          statusRef.current == ReviewStep.APPROVED ? (
            <BoxChecked height="20px">
              <Icon
                size={12}
                name="check"
                color={
                  themes.authentication.palette.common.white
                }
              />
            </BoxChecked>
          ) : (
            <Box></Box>
          )
        }
        onClick={() => changeStatus(ReviewStep.APPROVED, step)}
      >
        Aprovado
      </ButtonCheckbox>
      <ButtonCheckbox
        variant="outlined"
        startIcon={
          statusRef.current == ReviewStep.REFUSED ? (
            <BoxChecked height="20px">
              <Icon
                size={12}
                name="check"
                color={
                  themes.authentication.palette.common.white
                }
              />
            </BoxChecked>
          ) : (
            <Box></Box>
          )
        }
        onClick={() => changeStatus(ReviewStep.REFUSED, step)}
      >
        Rejeitado
      </ButtonCheckbox>
    </Box>
  );
};

export default BoxBtnsReviewStep;
