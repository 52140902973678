import React from 'react';

interface ContextProps {
  error: boolean;
  setError: (error: boolean) => void;
  permissions: object;
  setPermissions: (permissions: object) => void;
}

const Context = React.createContext<ContextProps | null>({
  error: false,
  setError: () => {},
  permissions: {},
  setPermissions: () => {},
});

export default Context;
