import { useState } from 'react';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { createPermission } from '@2ndmarket/services';

import {
  Icon,
  themes,
  MuiLoader,
  ModalDialog,
} from '@2ndmarket/components';

import {
  HttpStatus,
  HttpError,
  Targets,
} from '@2ndmarket/helpers';

import { Header, Navigation } from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { schema, defaultValues } from './formScope';

const ScopesCreate: React.FC = () => {
  const router = useRouter();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorUnprocessableEntity:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
        setShowModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const onSubmit = (data: {
    name: string;
    description: string;
  }) => {
    setIsLoading(true);

    createPermission(data.name, data.description)
      .then(() => {
        router.push(Targets.BACKOFFICE_CONF_PERMISSION_LIST);
      })
      .catch((error: HttpError) => {
        handleErrors(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-users"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            padding: {
              xs: '24px 16px',
              lg: '24px 32px',
            },
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Box
            gap={4}
            display="flex"
            marginBottom={1}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton
              onClick={() => router.back()}
              sx={{ ml: -1 }}
            >
              <Icon
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography variant="h2" component="h2">
              Adicionar
            </Typography>
          </Box>
          <Box
            gap={2}
            display="flex"
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          />
          <Box
            gap={3}
            width="100%"
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '20%',
                  lg: '20%',
                },
              }}
            >
              <Button
                variant="outlined"
                endIcon={
                  <Icon
                    size={12}
                    name="arrow"
                    rotate="-90deg"
                    color={
                      themes.authentication.palette.grey[300]
                    }
                  />
                }
                sx={{
                  width: '100%',
                  height: '54px',
                  border: 'none',
                  borderRadius: '6px',
                  justifyContent: 'space-between',
                  backgroundColor:
                    themes.authentication.palette.grey[100],
                  '&:hover': {
                    border: 'none',
                    cursor: 'default',
                    backgroundColor:
                      themes.authentication.palette.grey[100],
                  },
                }}
              >
                Cadastro
              </Button>
            </Box>
            <Box
              gap={3}
              display="flex"
              flexDirection="column"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '80%',
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                color={themes.authentication.palette.grey[600]}
              >
                Cadastro
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:
                      themes.authentication.palette.grey[900],
                  },
                }}
              >
                <Box
                  gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                  display="flex"
                  flexDirection={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nome"
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.name)}
                          inputProps={{ maxLength: 200 }}
                          helperText={
                            errors.name && errors.name.message
                          }
                        />
                      )}
                    />
                  </Box>
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="dense"
                          label="Descrição"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.description)}
                          inputProps={{ maxLength: 200 }}
                          helperText={
                            errors.description &&
                            errors.description.message
                          }
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  marginY={2}
                  display="flex"
                  alignItems="center"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  }}
                  justifyContent="space-between"
                >
                  <ButtonReturn
                    component="a"
                    onClick={() => router.back()}
                  >
                    Voltar
                  </ButtonReturn>
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      paddingX: 8,
                      width: {
                        xs: '100%',
                        sm: 172,
                        md: 172,
                        lg: 172,
                      },
                    }}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>

              {isLoading && (
                <Backdrop
                  open={isLoading}
                  sx={{
                    right: 0,
                    left: 'auto',
                    width: '100%',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    color:
                      themes.authentication.palette.common.white,
                  }}
                >
                  <MuiLoader loaderState={isLoading} />
                </Backdrop>
              )}

              {showModal && (
                <ModalDialog
                  error
                  maxWidth="sm"
                  open={showModal}
                  errorMessage={errorMessage}
                  onClose={() => setShowModal(false)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ScopesCreate;
