import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  createRole,
  updateRole,
  useStaffMe,
  useScopes,
} from '@2ndmarket/services';

import {
  Icon,
  themes,
  MuiLoader,
  ModalDialog,
} from '@2ndmarket/components';

import {
  HttpStatus,
  HttpError,
  staffRoles,
  Targets,
} from '@2ndmarket/helpers';

import { Header, Navigation } from '../../components/';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { styled } from '@mui/material/styles';
import { schema, defaultValues } from './formRole';

const RolesCreate: React.FC = () => {
  const router = useRouter();

  const { data: userStaff } = useStaffMe();
  const { data: permissions } = useScopes();

  const [myType, setMyType] = useState('');
  const [elements, setElements] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const checkOptionsOwn = [
    { id: 1, name: 'own_activate', label: 'Ativar' },
    { id: 2, name: 'own_update', label: 'Atualizar' },
    { id: 3, name: 'own_create', label: 'Criar' },
    { id: 4, name: 'own_delete', label: 'Deletar' },
    { id: 5, name: 'own_read', label: 'Ler' },
    { id: 6, name: 'own_list', label: 'Listar' },
  ];

  const checkOptionsOthers = [
    { id: 1, name: 'other_activate', label: 'Ativar' },
    { id: 2, name: 'other_update', label: 'Atualizar' },
    { id: 3, name: 'other_create', label: 'Criar' },
    { id: 4, name: 'other_delete', label: 'Deletar' },
    { id: 5, name: 'other_read', label: 'Ler' },
    { id: 6, name: 'other_list', label: 'Listar' },
  ];

  const addElement = () => {
    setElements([...elements, elements.length]);
    setValue(
      `role_permissions.${elements.length}.permission_id`,
      0,
    );
  };

  const removeElement = () => {
    const updateElements = elements.slice(0, -1);
    setElements(updateElements);
  };

  const onChangeType = (event: string) => {
    setMyType(event);
    setValue('type', event);
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorUnprocessableEntity:
      case HttpStatus.ClientErrorConflict:
      case HttpStatus.ClientErrorBadRequest:
        setShowModal(true);
        setErrorMessage(error.error);
        break;
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    await createRole(data.name, data.description, data.type)
      .then(response => {
        setIsLoading(true);
        const role_id = response.id;

        const role_permissions = {
          role_id: role_id,
          ...data.role_permissions,
        };

        updateRole(role_id, role_permissions)
          .then(() => {
            router.push(
              `${Targets.BACKOFFICE_CONF_FUNCTION_LIST}`,
            );
          })
          .catch((error: HttpError) => {
            handleErrors(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error: HttpError) => {
        handleErrors(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!userStaff) {
      return;
    }
  }, [userStaff]);

  const ButtonReturn = styled(Button)<{ component: 'a' }>(
    () => ({
      textDecoration: 'underline',
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'underline',
      },
    }),
  );

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100svh',
        overflow: 'hidden',
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
    >
      <Header />
      <Box
        width="100%"
        height="90%"
        display="flex"
        className="container-users"
      >
        <Navigation />
        <Box
          paddingY={3}
          paddingX={4}
          sx={{
            padding: {
              xs: '24px 16px',
              lg: '24px 32px',
            },
            overflowY: 'scroll',
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            },
          }}
        >
          <Box
            gap={4}
            display="flex"
            marginBottom={1}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton
              onClick={() => router.back()}
              sx={{ ml: -1 }}
            >
              <Icon
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography variant="h2" component="h2">
              Adicionar
            </Typography>
          </Box>
          <Box
            gap={2}
            display="flex"
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          />
          <Box
            gap={3}
            width="100%"
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '20%',
                  lg: '20%',
                },
              }}
            >
              <Button
                variant="outlined"
                endIcon={
                  <Icon
                    size={12}
                    name="arrow"
                    rotate="-90deg"
                    color={
                      themes.authentication.palette.grey[300]
                    }
                  />
                }
                sx={{
                  width: '100%',
                  height: '54px',
                  border: 'none',
                  borderRadius: '6px',
                  justifyContent: 'space-between',
                  backgroundColor:
                    themes.authentication.palette.grey[100],
                  '&:hover': {
                    border: 'none',
                    cursor: 'default',
                    backgroundColor:
                      themes.authentication.palette.grey[100],
                  },
                }}
              >
                Cadastro
              </Button>
            </Box>
            <Box
              gap={3}
              display="flex"
              flexDirection="column"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '80%',
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                color={themes.authentication.palette.grey[600]}
              >
                Cadastro
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:
                      themes.authentication.palette.grey[900],
                  },
                }}
              >
                <Box
                  gap={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                  display="flex"
                  flexDirection={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nome"
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.name)}
                          inputProps={{ maxLength: 200 }}
                          helperText={
                            errors.name && errors.name.message
                          }
                        />
                      )}
                    />
                  </Box>
                  <Box width="100%" gap={1} display="grid">
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          margin="dense"
                          label="Tipo"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(errors.type)}
                          helperText={
                            errors.type && errors.type.message
                          }
                          onChange={event => {
                            onChangeType(event.target.value);
                          }}
                        >
                          <MenuItem value="0">
                            <Typography
                              color={
                                themes.authentication.palette
                                  .grey[300]
                              }
                            >
                              Selecione
                            </Typography>
                          </MenuItem>
                          {staffRoles.map(
                            (option, key: number) => (
                              <MenuItem
                                key={key}
                                value={option.type}
                              >
                                {option.type[0].toUpperCase() +
                                  option.type.slice(1)}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                    />
                  </Box>
                </Box>
                <Box width="100%" gap={1} display="grid">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        margin="dense"
                        label="Descrição"
                        variant="outlined"
                        autoComplete="off"
                        error={Boolean(errors.description)}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          errors.description &&
                          errors.description.message
                        }
                      />
                    )}
                  />
                </Box>
                {elements.map((element, key) => (
                  <Box
                    key={key}
                    padding={3}
                    marginTop={1}
                    sx={{
                      borderRadius: '6px',
                      backgroundColor:
                        themes.authentication.palette.grey[100],
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      color={
                        themes.authentication.palette.grey[600]
                      }
                    >
                      Permissão
                    </Typography>
                    <Controller
                      name={`role_permissions.${element}.permission_id`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          fullWidth
                          margin="dense"
                          label={
                            field.value > 0 ||
                            field.value == undefined
                              ? 'Selecione'
                              : ''
                          }
                          variant="outlined"
                          autoComplete="off"
                          sx={{ marginTop: 2 }}
                        >
                          <MenuItem
                            value={
                              field.value == undefined
                                ? 0
                                : field.value
                            }
                          >
                            <Typography
                              color={
                                themes.authentication.palette
                                  .grey[300]
                              }
                            >
                              Selecione
                            </Typography>
                          </MenuItem>
                          {permissions.results.map(
                            (option, key: number) => (
                              <MenuItem
                                key={key}
                                value={option.id as number}
                              >
                                {option.name[0].toUpperCase() +
                                  option.name.slice(1)}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                    />

                    <Box
                      marginTop={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Typography>
                          Ações no próprio usuário:
                        </Typography>
                        <FormControl
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {checkOptionsOwn.map(
                            (option, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    name={`${option.name}`}
                                    onChange={event => {
                                      let ownChecked = `role_permissions.${element}.${option.name}`;
                                      setValue(
                                        ownChecked as any,
                                        event.target.checked,
                                      );
                                    }}
                                    sx={{
                                      color:
                                        themes.authentication
                                          .palette.grey[200],
                                    }}
                                  />
                                }
                                name="own"
                                label={
                                  <Typography
                                    component="p"
                                    variant="body1"
                                    color="text.secondary"
                                  >
                                    {option.label}
                                  </Typography>
                                }
                              />
                            ),
                          )}
                        </FormControl>
                        <Typography>
                          Ações em outros usuários:
                        </Typography>
                        <FormControl
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {checkOptionsOthers.map(
                            (option, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    name={`${option.name}`}
                                    onChange={event => {
                                      let otherChecked = `role_permissions.${element}.${option.name}`;
                                      setValue(
                                        otherChecked as any,
                                        event.target.checked,
                                      );
                                    }}
                                    sx={{
                                      color:
                                        themes.authentication
                                          .palette.grey[200],
                                    }}
                                  />
                                }
                                name="other"
                                label={
                                  <Typography
                                    component="p"
                                    variant="body1"
                                    color="text.secondary"
                                  >
                                    {option.label}
                                  </Typography>
                                }
                              />
                            ),
                          )}
                        </FormControl>
                      </Box>
                      <ButtonReturn
                        component="a"
                        onClick={removeElement}
                      >
                        Excluir
                      </ButtonReturn>
                    </Box>
                  </Box>
                ))}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginTop={2}
                >
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={addElement}
                    sx={{
                      paddingX: 8,
                      width: 172,
                    }}
                  >
                    Adicionar
                  </Button>
                </Box>

                <Box
                  marginY={2}
                  display="flex"
                  alignItems="center"
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  }}
                  justifyContent="space-between"
                >
                  <ButtonReturn
                    component="a"
                    onClick={() => router.back()}
                  >
                    Voltar
                  </ButtonReturn>
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      paddingX: 8,
                      width: 172,
                    }}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>

              {isLoading && (
                <Backdrop
                  open={isLoading}
                  sx={{
                    right: 0,
                    left: 'auto',
                    width: '100%',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    color:
                      themes.authentication.palette.common.white,
                  }}
                >
                  <MuiLoader loaderState={isLoading} />
                </Backdrop>
              )}

              {showModal && (
                <ModalDialog
                  error
                  maxWidth="sm"
                  open={showModal}
                  errorMessage={errorMessage}
                  onClose={() => setShowModal(false)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default RolesCreate;
