import { SEO } from '@2ndmarket/components';

type Props = {
  title: string;
  description: string;
};

const BackofficeSeo: React.FC<Props> = ({
  title,
  description,
}) => {
  const path = 'https://backoffice.2ndmarket.com.br';
  const params = {
    appName: 'Backoffice 2ND Market',
    title: title,
    description: description,
    image: `${path}/banner.jpg`,
    imageWidth: '1080',
    imageHeight: '1080',
    url: path,
    product: 'backoffice',
  };

  return <SEO {...params} />;
};

export default BackofficeSeo;
