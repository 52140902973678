import React from 'react';
import Image from 'next/image';

import { Page500Props } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Page500 = ({
  imageWidth,
  imageHeight,
  image,
  title,
  mailto,
  description,
}: Page500Props) => {
  return (
    <Grid
      component="main"
      sx={{
        width: '100%',
        height: '100svh',
        backgroundColor: themes.authentication.palette.grey[900],
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Grid
          display="flex"
          flexDirection="row"
          alignContent="center"
          justifyContent="center"
        >
          <Icon
            size={28}
            name="2nd"
            color={themes.authentication.palette.primary.main}
          />
          <Box>
            <Image
              alt=""
              src={image}
              width={imageWidth}
              height={imageHeight}
            />
          </Box>
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <Typography
              variant="h2"
              marginTop={3}
              component="h2"
              textAlign="center"
              color="text.primary"
            >
              {title}
            </Typography>
            <Typography
              paddingX={2}
              marginTop={3}
              component="p"
              variant="body1"
              textAlign="center"
              color="text.secondary"
            >
              {description}
            </Typography>
          </Box>
        </Grid>
        <Grid
          display="flex"
          marginBottom={3}
          justifyContent="center"
        >
          <Button
            href="/"
            component="a"
            color="primary"
            variant="contained"
            sx={{ marginTop: 3, paddingX: 4 }}
          >
            Voltar para o início
          </Button>
        </Grid>
        <Grid display="flex" justifyContent="center">
          <Link href={mailto}>Preciso de ajuda</Link>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Page500;
